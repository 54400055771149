import {ApolloError, FetchResult} from '@apollo/client';
import type {ProductSDK} from '../../../ProductSDK';
import {SessionTokens} from '../../../models/SessionTokens';
import {requireDefined} from '../../../utils/nullability-utils';
import {InvalidRefreshTokenError} from './exceptions/InvalidRefreshTokenError';
import {refreshTokenMutation} from '../../../graphql/mutation/refreshToken';
import {Operation} from '../../../models/Operation';
import {requireSuccessfulOperation} from '../../../utils/operations-utils';
import {RefreshTokenInput} from './models/RefreshTokenInput';

type Results = FetchResult<{refreshToken: Operation<{tokens: SessionTokens}>}>;

/**
 * @throws InvalidRefreshTokenError
 * @throws OperationFailError
 */
export async function refreshToken(this: ProductSDK, refreshToken: string): Promise<SessionTokens> {
  const variables: {input: RefreshTokenInput} = {
    input: {refreshToken},
  };

  try {
    const response: Results = await this.client.mutate({
      mutation: refreshTokenMutation,
      variables: variables,
    });

    const data = requireDefined(response.data?.refreshToken);
    requireSuccessfulOperation(data);

    return data.tokens;
  } catch (e: unknown) {
    if (e instanceof ApolloError) {
      if (e.graphQLErrors.length > 0) {
        switch (e.graphQLErrors[0].message) {
          case InvalidRefreshTokenError.ERR_MESSAGE:
            throw new InvalidRefreshTokenError(e.message);
        }
      }
    }

    throw e;
  }
}
