import {Link, MainMenuContainer} from './MainMenu.styled';

const MainMenu = ({links, ...rest}) => (
  <MainMenuContainer {...rest}>
    {links &&
      links.map((link) => (
        <Link key={link.id} url={link.path} type={link.type} isButton={link.isButton} isLinkActive={link.isActive}>
          {link.text}
        </Link>
      ))}
  </MainMenuContainer>
);

export default MainMenu;
