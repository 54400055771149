import styled, {css, keyframes} from 'styled-components';

const slide = keyframes`
  0% {left: -200%;}
	100% {left :100%;}
`;

interface IImageWrapper {
  isLoading: boolean;
}

export const ImageWrapper = styled.span<IImageWrapper>`
  position: relative;
  display: inline-block;
  overflow: hidden;
  height: 100%;
  width: 100%;
  ${(props) =>
    props.isLoading
      ? css`
          opacity: 0.15;
          &:after {
            content: '';
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 0.8) 50%,
              rgba(128, 186, 232, 0) 99%,
              rgba(125, 185, 232, 0) 100%
            );
            animation: ${slide} 1.5s infinite;
          }
        `
      : ''};
`;

export const ImageStyled = styled.img`
  display: block;
  height: 100%;
  width: 100%;
`;
