import {fromJS} from 'immutable';
import {prepareReducers} from '@/app/utils/redux';
import actions from './actions';

export const initialState = fromJS({
  isLoading: true,
});

const reducer = prepareReducers(
  {
    [actions.startupAction]: (state) => state.set('isLoading', true),
    [actions.startupSuccessAction]: (state) => state.set('isLoading', false),
  },
  initialState
);

export default reducer;
