import styled, {css, keyframes} from 'styled-components';

const getRoller = (nth: string, animationDelay: string, top: string, left: string) => css`
  &:nth-child(${nth}) {
    animation-delay: ${animationDelay};
    &:after {
      top: ${top};
      left: ${left};
    }
  }
`;

const LdsRollerKeyfrems = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

interface IStyleProps {
  color: string;
}

const style = (props: IStyleProps) => css`
  background: ${props.color};
`;

interface ILdsRollerProps {
  centered?: boolean;
}

export const LdsRoller = styled.div<ILdsRollerProps>`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  ${(props) =>
    props.centered
      ? `
    display: block;
    margin: 0 auto;
  `
      : ``}
`;

export const LdsRollerInner = styled.div`
  animation: ${LdsRollerKeyfrems} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
  ${style};
  &:after {
    content: ' ';
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: grey;
    margin: -3px 0 0 -3px;
  }
  ${getRoller('1', '-0.036s', '50px', '50px')};
  ${getRoller('2', '-0.072s', '54px', '45px')};
  ${getRoller('3', '-0.108s', '57px', '39px')};
  ${getRoller('4', '-0.144s', '58px', '32px')};
  ${getRoller('5', '-0.18s', '57px', '25px')};
  ${getRoller('6', '-0.216s', '54px', '19px')};
  ${getRoller('7', '-0.252s', '50px', '14px')};
  ${getRoller('8', '-0.288s', '45px', '10px')};
`;
