import {FetchResult} from '@apollo/client';
import type {ProductSDK} from '../../../ProductSDK';
import {requireDefined} from '../../../utils/nullability-utils';
import {Operation} from '../../../models/Operation';
import {UpdatePasswordWithTokenInput} from './models/UpdatePasswordWithTokenInput';
import {requireSuccessfulOperation} from '../../../utils/operations-utils';
import {ResetPasswordDataset} from './models/ResetPasswordDataset';
import {updatePasswordWithTokenMutation} from '../../../graphql/mutation/updatePasswordWithToken';
import {OperationFailError} from '../../../exceptions/OperationFailError';
import {InvalidResetPasswordTokenError} from './exceptions/InvalidResetPasswordTokenError';

type Results = FetchResult<{updatePasswordWithToken: Operation<unknown>}>;

/**
 * Uses a resetPasswordToken to update user password.
 * Second and last step of password reset flow.
 * @throws OperationFailError
 */
export async function updatePasswordWithToken(this: ProductSDK, dataset: ResetPasswordDataset): Promise<void> {
  try {
    const variables: {input: UpdatePasswordWithTokenInput} = {
      input: {password: dataset},
    };

    const response: Results = await this.client.mutate({
      mutation: updatePasswordWithTokenMutation,
      variables: variables,
    });

    const data = requireDefined(response.data?.updatePasswordWithToken);
    requireSuccessfulOperation(data);
  } catch (e: unknown) {
    if (e instanceof OperationFailError && e.getErrors().length > 0) {
      switch (e.getErrors()[0].code) {
        case InvalidResetPasswordTokenError.ERR_CODE:
          throw new InvalidResetPasswordTokenError();
      }
    }

    throw e;
  }
}
