import {fromJS, Map} from 'immutable';
import {prepareReducers} from '@/app/utils/redux';
import {getGuid} from '@/app/utils/guid';
import actions from './actions';

export const initialState = fromJS({
  data: [],
});

const reducer = prepareReducers(
  {
    [actions.addNotificationAction]: (state, {payload}) =>
      state.set('data', state.get('data').push(Map({id: getGuid(), ...payload}))),
    [actions.removeNotificationAction]: (state, {payload}) =>
      state.set(
        'data',
        state.get('data').filter((item) => item.get('id') !== payload)
      ),
  },
  initialState
);

export default reducer;
