import {prepareActions} from '@/app/utils/redux';

export const actions = prepareActions(
  {},
  ['login', true, true],
  ['logout', true],
  ['updatePassword', true, true],
  ['newPassword', true, true],
  ['newPasswordEmail', true, true],
  ['cancelAccount', true, true],
  ['updateCredentials', true, true],
  ['showUpdateCredentials', true, true],
  ['hideUpdateCredentials', true, true]
);

export default actions;
