import {Component, createRef} from 'react';
import {CloseIcon, SearchBtn, SearcherStyled, SearchIcon, SearchInput, SearchWrapper} from './Searcher.styled';
import ISearcherTheme from './ISearcherTheme';

interface ISearcherProps {
  placeholder?: string;
  searchPhrase?: string;
  onSearch: Function;
  location?: string;
  searchPath?: string;
  collapse?: Function;
  expandedReversed?: boolean;
  ariaLabel?: string;
  componentTheme: ISearcherTheme;
  children?: any;
}

interface ISearcherState {
  value: string;
  isExpanded: boolean;
}

class Searcher extends Component<ISearcherProps, ISearcherState> {
  inputRef = createRef<HTMLDivElement>();
  wrapperRef = createRef<HTMLDivElement>();
  state = {
    value: this.props.searchPhrase || '',
    isExpanded: !!this.props.searchPhrase,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handlClickOutside);
  }

  componentDidUpdate(prevProps: ISearcherProps) {
    this.handleLocationChange(prevProps);
    this.updateValue(prevProps);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handlClickOutside);
  }

  handlClickOutside = (event: any) => {
    const {isExpanded, value} = this.state;
    const shouldClose = isExpanded && value.trim().length === 0;
    if (this.wrapperRef && !this.wrapperRef.current!.contains(event.target) && shouldClose) {
      this.handleCollapseSearch();
    }
  };

  handleLocationChange = (prevProps: ISearcherProps) => {
    const {location, searchPath} = this.props;
    if (location !== prevProps.location && location !== searchPath) {
      this.handleCollapseSearch();
    }
  };

  updateValue = (prevProps: ISearcherProps) => {
    const {searchPhrase} = this.props;
    if (prevProps.searchPhrase !== this.props.searchPhrase) {
      this.setState(() => ({
        isExpanded: !!searchPhrase,
        value: searchPhrase || '',
      }));
    }
  };

  handleFocusInput = () => {
    this.inputRef.current!.focus();
  };

  handleChange = (event: any) => {
    this.setState({value: event.target.value});
  };

  handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      this.handleSearch();
    }
  };

  handleSearch = () => {
    const {onSearch} = this.props;
    onSearch && onSearch(this.state.value);
  };

  handleExpandSearch = () => {
    if (!this.state.isExpanded) {
      this.setState((prev) => ({...prev, isExpanded: true}), this.handleFocusInput);
    }
  };

  handleCollapseSearch = () => {
    if (this.state.isExpanded) {
      this.setState((prev) => ({...prev, isExpanded: false}), this.props.collapse && this.props.collapse());
    }
  };

  render() {
    const {value, isExpanded} = this.state;
    const {placeholder, onSearch, children, componentTheme, expandedReversed = false, ariaLabel, ...rest} = this.props;
    const hasValue = value.trim().length > 0;

    const btnAction = !isExpanded
      ? this.handleExpandSearch
      : isExpanded && hasValue
      ? this.handleSearch
      : this.handleCollapseSearch;

    return (
      <SearcherStyled {...rest}>
        <SearchWrapper isExpanded={isExpanded} expandedReversed={expandedReversed} ref={this.wrapperRef}>
          <SearchInput
            type='text'
            name='searcher'
            placeholder={placeholder}
            value={value}
            ref={this.inputRef}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            componentTheme={componentTheme}
            isExpanded={isExpanded}
            aria-label={ariaLabel}
          />
          <SearchBtn isExpanded={isExpanded} onClick={btnAction} componentTheme={componentTheme}>
            {!isExpanded && <SearchIcon componenttheme={componentTheme} />}
            {isExpanded && hasValue && <SearchIcon componenttheme={componentTheme} />}
            {isExpanded && !hasValue && <CloseIcon componenttheme={componentTheme} />}
          </SearchBtn>
        </SearchWrapper>
        {children}
      </SearcherStyled>
    );
  }
}

export default Searcher;
