import {add2Pot} from '@/app/utils/i18n/translate';

export const ACCESS_TYPE = {
  UN_AUTH: 'unAuth',
  AUTH: 'auth',
};

/*
  {
    view: name of view
    title: title of view
    path: relative url
    access?: ACCESS_TYPE (if param not exist, public route)
    exact?: boolean
  }
*/

export const ROUTES = {
  MAINPAGE: {
    view: 'MainPage',
    title: 'MainPage',
    path: '/',
    exact: true,
    access: ACCESS_TYPE.UN_AUTH,
  },
  ACCOUNT: {
    view: 'Account',
    title: add2Pot('Account'),
    path: '/account',
    access: ACCESS_TYPE.AUTH,
  },
  BROWSE: {
    view: 'Browse',
    title: add2Pot('Browse'),
    path: '/browse',
    access: ACCESS_TYPE.AUTH,
  },
  LOGIN: {
    view: 'Login',
    title: add2Pot('Login'),
    path: '/login',
    access: ACCESS_TYPE.UN_AUTH,
  },
  PLAYER: {
    view: 'Player',
    title: add2Pot('Player'),
    path: '/player/:id',
    access: ACCESS_TYPE.AUTH,
  },
  PRIVACY_POLICY: {
    view: 'PrivacyPolicy',
    title: add2Pot('Privacy Policy'),
    path: '/privacy-policy',
  },
  SEARCH: {
    view: 'Search',
    title: add2Pot('Search'),
    path: '/search',
    access: ACCESS_TYPE.AUTH,
  },
  SUPPORT: {
    view: 'Support',
    title: add2Pot('Support'),
    path: '/support',
  },
  SUBSCRIPTION: {
    view: 'Subscription',
    title: add2Pot('Cancel your account'),
    path: '/subscription',
    access: ACCESS_TYPE.UN_AUTH,
    fallback: '/account',
  },
  UNSUBSCRIBE: {
    view: 'Subscription',
    title: add2Pot('Cancel your account'),
    path: '/unsubscribe',
    access: ACCESS_TYPE.UN_AUTH,
    fallback: '/account',
  },
  TERMS: {
    view: 'Terms',
    title: add2Pot('Terms of use'),
    path: '/terms',
  },
  IMPRINT: {
    view: 'Imprint',
    title: add2Pot('Imprint'),
    path: '/imprint',
  },
  MY_LIST: {
    view: 'MyList',
    title: add2Pot('My list'),
    path: '/my-list',
    access: ACCESS_TYPE.AUTH,
  },
  FORGOT_PASSWORD: {
    view: 'ForgotPassword',
    title: add2Pot('Forgot password'),
    path: '/forgot-password',
  },
  DISCOVER: {
    view: 'Discover',
    title: add2Pot('Discover'),
    path: '/discover',
    access: ACCESS_TYPE.AUTH,
    exact: true,
  },
  REGISTER: {
    view: 'Register',
    title: add2Pot('Register'),
    path: '/register',
    access: ACCESS_TYPE.UN_AUTH,
  },
  PROMO: {
    view: 'PromoRegister',
    title: add2Pot('Promo'),
    path: '/promo',
    access: ACCESS_TYPE.UN_AUTH,
  },
  ERROR: {
    view: 'Error',
    title: add2Pot('No signal…'),
  },
};

export const AUTH_ROUTES = Object.values(ROUTES).filter((route) => route.path && route.access !== ACCESS_TYPE.UN_AUTH);
export const UN_AUTH_ROUTES = Object.values(ROUTES).filter((route) => route.access !== ACCESS_TYPE.AUTH);
export const ROUTES_GDPR = {
  COOKIES: {
    view: 'Cookies',
    title: 'Our use of cookies and similar technologies',
    path: '/cookies-policy',
  },
  ...ROUTES,
};
