import {ComponentType} from 'react';
import {ButtonStyled, ErrorWrapper, ImageErrorStyled, ParagraphStyled, TitleStyled} from './Error.styled';
import IErrorTheme from '@dev7/altar-alt-ui/Error/IErrorTheme';

interface ErrorProps {
  icon: string;
  iconWebp?: string;
  title: string;
  description: string;
  buttonText: string;
  onBtnClick: Function;
  children?: ComponentType | HTMLElement;
  componentTheme: IErrorTheme;
}

function Error(props: ErrorProps) {
  const {icon, title, description, buttonText, onBtnClick, children, componentTheme, iconWebp, ...rest} = props;
  return (
    <ErrorWrapper {...rest}>
      <ImageErrorStyled src={icon} webp={iconWebp} />
      <TitleStyled forwardedAs='h4' componentTheme={componentTheme}>
        {title}
      </TitleStyled>
      <ParagraphStyled componentTheme={componentTheme} pType='p2'>
        {description}
      </ParagraphStyled>
      <ButtonStyled onClick={onBtnClick} label={buttonText} componentTheme={componentTheme} />
      {children}
    </ErrorWrapper>
  );
}

export default Error;
