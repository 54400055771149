import {connect} from 'react-redux';
import {compose} from 'redux';
import {withTranslators} from '@/app/lib/lioness';
import {createStructuredSelector} from 'reselect';
import {selectors} from '@/app/reducers/info';
import {withInfo} from '@/app/ui/HOC/withInfo';
import {toJS} from '@/app/utils/immutable/toJS';
import Cookies from './Cookies';

const mapStateToProps = createStructuredSelector({
  info: selectors.getInfo(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, withTranslators, withInfo)(toJS(Cookies));
