import {StoreObject} from '@apollo/client';
import {Content} from '../../../../models/Content';
import {getContentQuery} from '../../../../graphql/query/getContent';
import type {ProductSDK} from '../../../../ProductSDK';

export function updateCache(this: ProductSDK, contentId: string) {
  // getContent
  const getContentResults: {content: Content & StoreObject} | null = this.client.readQuery({
    query: getContentQuery,
    variables: {id: contentId},
  });

  if (getContentResults) {
    this.client.cache.modify({
      id: this.client.cache.identify(getContentResults.content),
      fields: {
        isUserContent() {
          return true;
        },
      },
    });
  }
}
