import {ComponentType, ForwardedRef, forwardRef, PropsWithChildren} from 'react';
import {useTheme} from 'styled-components';
import {getThemeObject} from '../../utils';
import getDisplayName from 'react-display-name';
import hoistNonReactStatics from 'hoist-non-react-statics';


export interface WithThemeProps {
  theme: any;
  componentTheme: any;
  styles?: any;
}

function withTheme(componentName: string) {
  return function withTheme<P extends WithThemeProps>(WrappedComponent: ComponentType<P>) {
    let newComponent;

    // eslint-disable-next-line react/display-name
    newComponent = forwardRef<ComponentType<P>, P>((props: PropsWithChildren<P>, ref: ForwardedRef<ComponentType<P>>) => {
      const {componentTheme} = props;
      const theme = useTheme();
      const themeProp = getThemeObject(componentTheme ? componentTheme : theme, componentName);

      return (<WrappedComponent ref={ref} {...(props as P)} theme={themeProp} componentTheme={themeProp} />);
    });

    hoistNonReactStatics(newComponent, WrappedComponent);
    newComponent = Object.assign(newComponent, {displayName: `withTheme(${getDisplayName(WrappedComponent)})`});

    return newComponent;
  }
}

export default withTheme;
