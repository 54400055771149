import {fromJS} from 'immutable';
import {prepareReducers} from '@/app/utils/redux';
import actions from './actions';

export const initialState = fromJS({
  data: null, //TODO: Inspect usage of this field, now it's `DomainInfo`
});

const reducer = prepareReducers(
  {
    [actions.getInfoSuccessAction]: (state, {payload}) => state.set('data', payload),
  },
  initialState
);

export default reducer;
