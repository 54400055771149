import styled from 'styled-components';
import Wrapper from '@dev7/altar-alt-ui/Wrapper';
import IBackdropMenuTheme from '@dev7/altar-alt-ui/BackdropMenu/IBackdropMenuTheme';
import {ReactNode} from 'react';

export interface BackdropMenuProps {
  componentTheme: IBackdropMenuTheme;
  children: ReactNode | string;
}

export const BackdropMenuContainer = styled(Wrapper)<BackdropMenuProps>`
  display: block;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: ${({componentTheme}) => componentTheme && componentTheme.background};
  z-index: 26;
  overflow: auto;
`;
