import styled from 'styled-components';
import ICloseTheme from './ICloseTheme';

interface ICloseWrapper {
  componentTheme: ICloseTheme;
}

export const CloseWrapper = styled.div<ICloseWrapper>`
  position: absolute;
  width: ${({componentTheme}) => componentTheme && `${componentTheme.height}px`};
  height: ${({componentTheme}) => componentTheme && `${componentTheme.height}px`};
  outline: 0;
  :hover {
    cursor: pointer;
  }

  :before,
  :after {
    position: absolute;
    left: ${({componentTheme}) => componentTheme && `${componentTheme.height / 2}px`};
    content: ' ';
    height: ${({componentTheme}) => componentTheme && `${componentTheme.height + 1}px`};
    width: ${({componentTheme}) => componentTheme && `${componentTheme.width}px`};
    background-color: ${({componentTheme}) => componentTheme && componentTheme.color};
  }

  :before {
    transform: rotate(45deg);
  }
  :after {
    transform: rotate(-45deg);
  }
`;
