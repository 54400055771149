import styled, {withTheme} from 'styled-components';
import media from '@/app/styles/media.styled';
import LoadingSource from '@dev7/altar-alt-ui/Loading';

const LoadingStyled = styled.div`
  min-height: calc(100vh - 361px);
  padding: 85px 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.md`
      min-height: calc(100vh - 146px);
      padding: 120px 0 40px;
  `} ${media.lg`
      min-height: calc(100vh - 165px);
      padding: 120px 0 40px;
  `};
`;

const Loading = (props) => (
  <LoadingStyled {...props}>
    <LoadingSource color={props.theme.colors.brand} />
  </LoadingStyled>
);

export default withTheme(Loading);
