import {LdsRing, LdsRingInner} from './Default.styled';

interface DefaultProps {
  color: string;
  centered?: boolean;
}

function Default(props: DefaultProps) {
  const {color, ...rest} = props;
  return (
    <LdsRing {...rest}>
      <LdsRingInner color={color} style={{animationDelay: '-0.45s'}} />
      <LdsRingInner color={color} style={{animationDelay: '-0.3s'}} />
      <LdsRingInner color={color} style={{animationDelay: '-0.15s'}} />
      <LdsRingInner color={color} />
    </LdsRing>
  );

}

export default Default;
