import {ReactNode} from 'react';
import {RowWrapper} from './Row.styled';

export interface RowProps {
  'justify-content'?: 'center' | 'flex-start' | 'flex-end' | 'space-around';
  children: ReactNode | string;
}

function Row(props: RowProps) {
  const {children, ...rest} = props;
  return <RowWrapper {...rest}>{children}</RowWrapper>

}

export default Row;
