import {AdditionalDataItem, DomainInfo, Entity, PhoneSupport, SubscriptionPlan} from '@dev7/altar-product-sdk';
import {OldDomainInfo} from './OldDomainInfo';

export function convertDomainInfo(domainInfo: DomainInfo): OldDomainInfo {
  return {
    domain: domainInfo.domain,
    service_name: domainInfo.serviceName,
    support_email: domainInfo.supportEmail,
    support_phones: domainInfo.supportPhones.map((phoneSupport: PhoneSupport) => ({
      support_phone: phoneSupport.number,
      country: phoneSupport.country.isoCode,
      working_hours: phoneSupport.workingHours,
    })),
    support_skype: null,
    subscription_plans: domainInfo.subscriptionPlans.map((subscriptionPlan: SubscriptionPlan) => ({
      name: subscriptionPlan.name,
      price: subscriptionPlan.price.amount.toString(),
      active: subscriptionPlan.active,
      trial_days: subscriptionPlan.trialDays,
      duration: subscriptionPlan.duration,
      duration_unit: subscriptionPlan.durationUnit,
      currency: subscriptionPlan.price.currency.isoCode,
      currency_symbol: subscriptionPlan.price.currency.symbol,
    })),
    entities: domainInfo.entities.map((entity: Entity) => ({
      name: entity.name,
      address: entity.address,
      city: entity.city,
      country: entity.country,
      additional_data: ['reg_address', 'reg_nr', 'headquarters_email', 'state', 'cep', 'cnpj'].reduce(
        (additionalData: Record<string, unknown>, key: string) => ({
          ...additionalData,
          [key]: entity.additionalData.find((dataItem: AdditionalDataItem) => dataItem.key === key)?.value,
        }),
        {} as Record<string, unknown>
      ) as (OldDomainInfo['entities'][number]['additional_data']),
    })),
    impressum: domainInfo.impressum,
    country: domainInfo.country.isoCode,
    europe: domainInfo.country.continent.isoCode === 'EU',
    additional_data: ['reg_nr', 'headquarters_email', 'state', 'cep', 'cnpj', 'desc_name', "desc_phone"].reduce(
      (additionalData: Record<string, unknown>, key: string) => ({
        ...additionalData,
        [key]: domainInfo.additionalData.find((dataItem: AdditionalDataItem) => dataItem.key === key)?.value,
      }),
      {} as Record<string, unknown>
    ) as (OldDomainInfo['additional_data'])
  };
}
