import {ApolloError, FetchResult} from '@apollo/client';
import type {ProductSDK} from '../../../ProductSDK';
import {requireDefined} from '../../../utils/nullability-utils';
import {Operation} from '../../../models/Operation';
import {validateTokenMutation} from '../../../graphql/mutation/validateToken';
import {ValidateTokenInput} from './models/ValidateTokenInput';
import {requireSuccessfulOperation} from '../../../utils/operations-utils';
import {InvalidAccessTokenError} from './exceptions/InvalidAccessTokenError';

type Results = FetchResult<{validateToken: Operation<unknown>}>;

export async function validateToken(this: ProductSDK, accessToken: string): Promise<void> {
  try {
    const variables: {input: ValidateTokenInput} = {
      input: {accessToken},
    };

    const response: Results = await this.client.mutate({
      mutation: validateTokenMutation,
      variables: variables,
    });

    const data = requireDefined(response.data?.validateToken);
    requireSuccessfulOperation(data);
  } catch (e: unknown) {
    if (e instanceof ApolloError) {
      if (e.graphQLErrors.length > 0) {
        switch (e.graphQLErrors[0].message) {
          case InvalidAccessTokenError.ERR_MESSAGE:
            throw new InvalidAccessTokenError(e.message);
        }
      }
    }

    throw e;
  }
}
