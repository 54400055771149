import { createContext } from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {selectors as infoSelectors} from '@/app/reducers/info';

export const GDPRContext = createContext();

const GdprProvider = ({info, children}) => <GDPRContext.Provider value={info}>{children}</GDPRContext.Provider>;

const mapStateToProps = createStructuredSelector({
  info: infoSelectors.getInfo(),
});

export default connect(mapStateToProps)(GdprProvider);
