import {connect} from 'react-redux';
import PrivateRoute from './PrivateRoute';
import {selectors as userSelectors} from '@/app/reducers/user';
import {createStructuredSelector} from 'reselect';
import {toJS} from '@/app/utils/immutable/toJS';

const mapStateToProps = createStructuredSelector({
  user: userSelectors.getUser().toJS ? userSelectors.getUser().toJS() : userSelectors.getUser(),
});

const withConnect = connect(mapStateToProps, null);

export default withConnect(toJS(PrivateRoute));
