import {ComponentType} from 'react';
import {FakeLinkStyled, LinkStyled, LinkStyledRedirect, NavLinkStyled} from './Link.styled';
import ILinkTheme from './ILinkTheme';

enum types {
  link = 'link',
  navlink = 'navlink',
  mail = 'mail',
  tel = 'tel',
  anchor = 'anchor',
  fake = 'fake',
}

export interface LinkProps {
  url?: string;
  type?: types;
  isButton?: boolean;
  description?: string;
  isLinkActive?: boolean;
  children?: ComponentType | HTMLElement;
  componentTheme: ILinkTheme;
  ariaLabel?: string;
}

interface PropsType {
  to?: string;
  href?: string;
  isLinkActive?: boolean;
}

interface LinkType {
  type: types;
  Component: any;
  props: PropsType;
}

function Link(props: LinkProps) {
  const {
    type = types.link,
    description,
    children,
    url,
    isButton,
    componentTheme,
    isLinkActive,
    ariaLabel,
    ...rest
  } = props;
  const linkTypes: Array<LinkType> = [
    {
      type: types.link,
      Component: LinkStyledRedirect,
      props: {
        to: url,
      },
    },
    {
      type: types.navlink,
      Component: NavLinkStyled,
      props: {
        to: url,
      },
    },
    {
      type: types.mail,
      Component: LinkStyled,
      props: {
        href: `mailto:${url}`,
      },
    },
    {
      type: types.tel,
      Component: LinkStyled,
      props: {
        href: `tel:${url}`,
      },
    },
    {
      type: types.anchor,
      Component: LinkStyled,
      props: {
        href: url,
      },
    },
    {
      type: types.fake,
      Component: FakeLinkStyled,
      props: {},
    },
  ];

  const renderLink = () => {
    const {props, Component} = linkTypes.find((item) => item.type === type) || linkTypes[0];
    return (
      <Component
        {...props}
        {...rest}
        isButton={isButton}
        componenttheme={componentTheme}
        isLinkActive={isLinkActive}
        aria-label={ariaLabel}
      >
        {description || children}
      </Component>
    );
  };

  return renderLink();
}

export default Link;
