import {gql} from '@apollo/client';

export const deleteUserContentMutation = gql`
  mutation deleteUserContent($input: DeleteUserContentInput!) {
    deleteUserContent(input: $input) {
      success
      errors {
        code
        msg
      }
    }
  }
`;
