import { Component } from 'react';
import {WrapperStyled} from './Wrapper.styled';
import {ROUTES} from '@/app/Routes';
import {isWebpFormatSupported} from '@/app/utils/images';

const webpSupported = isWebpFormatSupported();

class Wrapper extends Component {
  state = {
    module: null,
    repeatImg: true,
  };

  componentDidMount() {
    if (this.shouldloadBackground()) this.loadBackground(webpSupported);
  }

  componentDidUpdate(prevProps, prevState) {
    const {location} = this.props;
    const shouldloadBackground = this.shouldloadBackground();

    if (!shouldloadBackground && prevState.module !== null) {
      this.setState(() => ({module: null}));
    } else if (shouldloadBackground && prevProps.location.pathname !== location.pathname) {
      this.loadBackground(webpSupported);
    }
  }

  shouldloadBackground() {
    const {location, theme} = this.props;
    const {others} = theme;
    const noSubscriptionRoute = location.pathname !== ROUTES.SUBSCRIPTION.path;
    const subscriptionRoute = location.pathname === ROUTES.SUBSCRIPTION.path;
    const mainPageRoute = location.pathname === ROUTES.MAINPAGE.path;

    if (Object.hasOwnProperty.call(others, 'mainPageOnlyBackground') && others.mainPageOnlyBackground) {
      return noSubscriptionRoute && mainPageRoute;
    }

    return (
      (noSubscriptionRoute || (subscriptionRoute && theme.others.withBackgroundImgSubscription)) &&
      theme.others.withBackgroundImg
    );
  }

  loadBackground = (useWebp = false) => {
    const {theme, location} = this.props;
    const bgImageFileName = useWebp ? 'bg_image_mainpage.webp' : 'bg_image_mainpage.jpg';
    const bgFileName = useWebp ? 'bg_image.webp' : 'bg_image.jpg';

    try {
      if (location.pathname === ROUTES.MAINPAGE.path && theme.others.otherMainPageBackgroundImg) {
        import(/* webpackMode: "lazy-once" */ `@/product/assets/jpg/${bgImageFileName}`).then((module) => {
          this.setState({module: module.default});
        });
      } else {
        import(/* webpackMode: "lazy-once" */ `@/product/assets/jpg/${bgFileName}`).then((module) => {
          this.setState({module: module.default});
          !theme.others.repeatBgImg && this.setState({repeatImg: theme.others.repeatBgImg});
        });
      }
    } catch (ex) {
      /* eslint-disable no-console */
      console.error(ex);
    }
  };

  render() {
    return (
      <WrapperStyled {...this.props} bgImg={this.state.module} bgImgRepeat={this.state.repeatImg}>
        {this.props.children}
      </WrapperStyled>
    );
  }
}

export default Wrapper;
