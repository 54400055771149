import {fromJS} from 'immutable';
import {prepareReducers} from '@/app/utils/redux';
import actions from './actions';

export const initialState = fromJS({
  isVisible: false,
});

const reducer = prepareReducers(
  {
    [actions.openCookiesAction]: (state) => state.set('isVisible', true),
    [actions.closeCookiesAction]: (state) => state.set('isVisible', false),
  },
  initialState
);

export default reducer;
