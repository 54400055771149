import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {actions as moviesActions, selectors as moviesSelectors} from '@/app/reducers/movies';
import {actions as notificationActions} from '@/app/reducers/notification';
import Logger from '@/app/modules/logger';
import {prepareError} from '@/app/utils/sagas';
import {getCategoriesList, getContentsList} from '@dev7/altar-product-sdk';
import {productSDK} from '../modules/productSDK';

const logger = Logger.getInstance('MoviesSaga');
const STAFF_PICKS_CATEGORY_NAME = 'Staff picks';

function prepareSortingOptions(sortType) {
  switch (sortType) {
    case 'alphabeticalAZ':
      return {by: 'title', direction: 'ASC'};
    case 'alphabeticalZA':
      return {by: 'title', direction: 'DESC'};
    case 'oldest':
      return {by: 'created_at', direction: 'ASC'};
    case 'newest':
    default:
      return {by: 'created_at', direction: 'DESC'};
  }
}

export function* fetchBrowseMoviesSaga({payload}) {
  try {
    const options = {
      first: payload.limit,
      sort: prepareSortingOptions(payload.order),
      categories: payload.genres,
    };

    const connection = yield call(productSDK.call, getContentsList, options);
    yield put(moviesActions.fetchBrowseMoviesSuccessAction(connection));
  } catch (error) {
    yield put(moviesActions.fetchBrowseMoviesFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export function* fetchMoreBrowseMoviesSaga({payload}) {
  try {
    const pagination = yield select(moviesSelectors.getBrowsePagination());

    const options = {
      after: pagination?.endCursor,
      first: payload.limit,
      sort: prepareSortingOptions(payload.order),
      categories: payload.genres,
    };

    const connection = yield call(productSDK.call, getContentsList, options);
    yield put(moviesActions.fetchMoreBrowseMoviesSuccessAction(connection));
  } catch (error) {
    yield put(moviesActions.fetchMoreBrowseMoviesFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export function* getSearchMoviesSaga({payload}) {
  try {
    const options = {
      first: payload.limit,
      sort: {by: 'created_at', direction: 'DESC'},
      search: payload.query,
    };

    const connection = yield call(productSDK.call, getContentsList, options);
    yield put(moviesActions.getSearchMoviesSuccessAction(connection));
  } catch (error) {
    yield put(moviesActions.getSearchMoviesFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export function* getRecentMoviesSaga() {
  try {
    const result = yield call(productSDK.call, getContentsList, {
      first: 10,
      sort: {by: 'shuffle', direction: 'DESC'},
    });
    yield put(
      moviesActions.getRecentMoviesSuccessAction({
        movies: result.nodes,
        total: result.totalCount,
      })
    );
  } catch (error) {
    yield put(moviesActions.getRecentMoviesFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export function* getStaffPickMoviesSaga() {
  try {
    const categoriesResults = yield call(productSDK.call, getCategoriesList);
    const promoEbooksCategory = categoriesResults.nodes.find((category) => category.name === STAFF_PICKS_CATEGORY_NAME);

    if (!promoEbooksCategory) throw new Error(`Category named \`${STAFF_PICKS_CATEGORY_NAME}\` was not found.`);

    const result = yield call(productSDK.call, getContentsList, {first: 8, categories: [promoEbooksCategory.id]});
    yield put(
      moviesActions.getStaffPickMoviesSuccessAction({
        movies: result.nodes,
        total: result.totalCount,
      })
    );
  } catch (error) {
    yield put(moviesActions.getStaffPickMoviesFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export default function* getMovies() {
  yield all([
    takeLatest(moviesActions.fetchMoreBrowseMoviesAction, fetchMoreBrowseMoviesSaga),
    takeLatest(moviesActions.getSearchMoviesAction, getSearchMoviesSaga),
    takeLatest(moviesActions.getRecentMoviesAction, getRecentMoviesSaga),
    takeLatest(moviesActions.getStaffPickMoviesAction, getStaffPickMoviesSaga),
    takeLatest(moviesActions.fetchBrowseMoviesAction, fetchBrowseMoviesSaga),
  ]);
}
