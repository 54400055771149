import {FetchResult} from '@apollo/client';
import type {ProductSDK} from '../../../ProductSDK';
import {requireDefined} from '../../../utils/nullability-utils';
import {UserContentsListConnection} from './models/UserContentsListConnection';
import {getUserContentsListQuery} from '../../../graphql/query/getUserContentsList';
import {UserContentsListOptions} from './models/UserContentsListOptions';

export async function getUserContentsList(
  this: ProductSDK,
  options: UserContentsListOptions = {}
): Promise<UserContentsListConnection> {
  const response: FetchResult<{userContents: UserContentsListConnection}> = await this.client.query({
    query: getUserContentsListQuery,
    variables: options,
    fetchPolicy: 'no-cache',
  });

  return requireDefined(response.data?.userContents);
}
