import {all, call, put, takeLatest} from 'redux-saga/effects';
import {actions as movieActions} from '@/app/reducers/movie';
import {actions as notificationActions} from '@/app/reducers/notification';
import Logger from '@/app/modules/logger';
import {prepareError} from '@/app/utils/sagas';
import {getContent} from '@dev7/altar-product-sdk';
import {productSDK} from '../modules/productSDK';

const logger = Logger.getInstance('MovieSaga');

export function* getMovieSaga({payload: contentId}) {
  try {
    const results = yield call(productSDK.call, getContent, contentId);
    yield put(movieActions.getMovieSuccessAction(results));
  } catch (error) {
    yield put(movieActions.getMovieFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export function* bookmarkMovieSaga() {
  try {
    yield put(movieActions.bookmarkMovieAction());
  } catch (error) {
    yield put(movieActions.getMovieFailedAction());
  }
}

export default function* watchForPromoMovies() {
  yield all([takeLatest(movieActions.getMovieAction, getMovieSaga)]);
}
