import styled from 'styled-components';
import IInputTheme from './IInputTheme';

interface InputStyledProps {
  error?: boolean;
  id: string;
  disabled?: boolean;
  readOnly?: boolean;
  componentTheme: IInputTheme;
  touched?: boolean;
}

export const InputStyled = styled.input<InputStyledProps>`
  height: ${(componentTheme) => componentTheme.height || '48px'};
  font-family: inherit;
  color: ${({error, componentTheme}) => (error ? componentTheme.colorError : componentTheme.color)};
  background: ${({error, componentTheme}) => (error ? componentTheme.backgroundError : componentTheme.background)};
  border: 1px solid ${({error, componentTheme}) => (error ? componentTheme.borderError : componentTheme.border)};
  border-radius: 4px;
  ${({componentTheme}) =>
    componentTheme &&
    `
    font-size: ${componentTheme.fontSize ? componentTheme.fontSize : '14px'};
    line-height: ${componentTheme.lineHeight ? componentTheme.lineHeight : '20px'}; 
  `}
  padding: 14px 16px;
  outline: none;
  ${({componentTheme}) =>
    componentTheme && componentTheme.fontWeight ? `font-weight: ${componentTheme.fontWeight}` : ''};
  ${({readOnly}) => readOnly && `cursor: auto`};

  &::placeholder {
    color: ${({error, componentTheme}) =>
      error
        ? componentTheme.colorError
        : componentTheme && componentTheme.placeholderColor
        ? `${componentTheme.placeholderColor}`
        : ``};
    font-weight: ${({componentTheme}) => componentTheme.placeholderFontWeight || ''};
  }

  &:active,
  &:focus {
    background: ${({componentTheme, disabled, readOnly}) =>
      !disabled && !readOnly && componentTheme && componentTheme.backgroundActive};
    ${({componentTheme, disabled, readOnly}) =>
      !disabled && !readOnly && componentTheme.borderActive && `border: 1px solid ${componentTheme.borderActive};`}
    ${({componentTheme, disabled, readOnly, error}) =>
      !disabled && !readOnly && !error && componentTheme.colorActive && `color: ${componentTheme.colorActive};`}
      
    ${({componentTheme, disabled, readOnly, error}) =>
      !disabled &&
      !readOnly &&
      !error &&
      componentTheme.placeholderColorActive &&
      `&::placeholder {color: ${componentTheme.placeholderColorActive};}`}
  }
`;
