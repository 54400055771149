import {forwardRef, ReactNode, Ref} from 'react';
import styled from 'styled-components';

export interface IWrapperStyled {
  ref?: Ref<HTMLDivElement>;
}

const WrapperStyled = styled('div')<IWrapperStyled>``;

export type RefDiv = HTMLDivElement;

export interface Props {
  children: ReactNode | string;
  theme?: any;
}

function Wrapper(props: Props, ref: Ref<HTMLDivElement>) {
  const {children, ...rest} = props;
  return (
    <WrapperStyled ref={ref} {...rest}>
      {children}
    </WrapperStyled>
  );
}

export default forwardRef(Wrapper);
