import {FetchResult} from '@apollo/client';
import type {ProductSDK} from '../../../ProductSDK';
import {requireDefined} from '../../../utils/nullability-utils';
import {getUserQuery} from '../../../graphql/query/getUser';
import {User} from '../../../models/User';

export async function getUser(this: ProductSDK): Promise<User> {
  const response: FetchResult<{me: User}> = await this.client.query({
    query: getUserQuery,
  });

  return requireDefined(response.data?.me);
}
