import { Component } from 'react';
import {GDPRContext} from "../../providers/gdpr";

const withGdprContext = (WrappedComponent, other) => {
  return class extends Component {
    render() {
      return (
        <GDPRContext.Consumer>
          {(value) => <WrappedComponent {...other} {...this.props} {...value} />}
        </GDPRContext.Consumer>
      );
    }
  };
};

export default withGdprContext;
