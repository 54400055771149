import {createSelector} from 'reselect';

const selectors = {
  selectState: (state) => state.get('moviesToWatch'),
  isLoading: () => createSelector(selectors.selectState, (movies) => movies.get('isLoading')),
  getMoviesToWatch: () => createSelector(selectors.selectState, (movies) => movies.get('movies')),
  getMoviesToWatchTotal: () => createSelector(selectors.selectState, (movies) => movies.get('total')),
};

export default selectors;
