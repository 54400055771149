import {call, put, takeLatest} from 'redux-saga/effects';
import {actions} from '@/app/reducers/startup';
import {actions as userActions} from '@/app/reducers/user';
import {infoSaga} from '@/app/sagas/info';
import {replace} from 'connected-react-router';
import {getQueryParam, getQueryParamsString} from '@/app/utils/query';
import {AUTO_LOGIN_TOKEN} from '@/app/constants/queryParams';
import {autoLoginSaga} from '@/app/sagas/user';
import {purgeSessionTokens, readSessionTokens, saveSessionTokens} from '../modules/localStorage';
import Logger from '@/app/modules/logger';
import {getUser, InvalidAccessTokenError, OperationFailError, validateToken} from '@dev7/altar-product-sdk';
import {doesSessionTokensExpired} from '../modules/sessionTokens';
import {productSDK} from '../modules/productSDK';

const logger = Logger.getInstance('StartupSaga');

export function* startupSaga() {
  try {
    yield call(infoSaga);
    const user = yield call(authorize);
    if (user) yield put(userActions.loginSuccessAction(user));
  } catch (error) {
    logger.error(error);
  }
  yield put(actions.startupSuccessAction());
}

export function* authorize() {
  const token = yield call(getQueryParam, AUTO_LOGIN_TOKEN);
  if (token) {
    yield put(replace(getQueryParamsString({[AUTO_LOGIN_TOKEN]: undefined})));
    return yield call(autoLoginSaga, token);
  } else {
    return yield call(restoreSession);
  }
}

export function* restoreSession() {
  const sessionTokens = yield call(readSessionTokens);

  if (sessionTokens) {
    try {
      if (!doesSessionTokensExpired(sessionTokens)) throw new Error('Access token expired.');

      yield call(productSDK.call, validateToken, sessionTokens.accessToken);

      productSDK.setupAuthentication(sessionTokens, saveSessionTokens);
      return yield call(productSDK.call, getUser);
    } catch (e) {
      if (!(e instanceof InvalidAccessTokenError || e instanceof OperationFailError))
        console.error('Restore session error:', e); // Print only unknown errors

      purgeSessionTokens();
    }
  }
  return null;
}

export default function* watchForStartup() {
  yield takeLatest(actions.startupAction, startupSaga);
}
