import styled, {css} from 'styled-components';
import Button from '../Button';
import IDropDownTheme from './IDropDownTheme';
import {ReactComponent as CarretDown} from './assets/caret-down.svg';
import {ReactComponent as CarretUp} from './assets/caret-up.svg';

export const DropDownStyled = styled.div`
  position: relative;
  display: inline-block;
  font-family: 'Open Sans', 'sans-serif';
`;

export const ButtonText = styled.span<IDropDownStyled>`
  ${({
    componentTheme: {buttonTextFontSize, buttonTextColorOpen, buttonTextColor, buttonTextLineHeight, fontWeight},
    isOpen,
  }) => css`
    margin: 0;
    color: ${isOpen ? buttonTextColorOpen : buttonTextColor};
    ${buttonTextFontSize && `font-size: ${buttonTextFontSize};`}
    line-height: ${buttonTextLineHeight || '16px'};
    ${fontWeight && `font-weight: ${fontWeight};`}
    transition: color 0.3s ease-in-out;
  `}
`;

interface ISVGStyled {
  componenttheme: IDropDownTheme;
}

const CaretMix = () => css<ISVGStyled>`
  flex-shrink: 0;
  ${({componenttheme: {carretWidth, carretHeight}}) => css`
    width: ${carretWidth || '16px'};
    height: ${carretHeight || '16px'};
  `}
  margin-left: 4px;
  transition: fill 0.3s ease-in-out;
`;

export const CarretDownStyled = styled(CarretDown)<ISVGStyled>`
  ${CaretMix}
  fill: ${({componenttheme}) => componenttheme.carretColor};
`;

export const CarretUpStyled = styled(CarretUp)<ISVGStyled>`
  ${CaretMix}
  fill: ${({componenttheme}) => componenttheme.carretColorOpen || componenttheme.carretColor};
`;

interface IDropDownStyled {
  componentTheme: IDropDownTheme;
  isOpen: boolean;
}

export const ButtonStyled = styled(Button)<IDropDownStyled>`
  ${({
    componentTheme: {
      buttonBorderColor,
      buttonBackgroundOpen,
      buttonBorderRadius,
      buttonBorderOpen,
      buttonBackground,
      buttonTextColor,
      buttonTextColorHover,
      buttonTextColorOpen,
      carretColorHover,
    },
    isOpen,
  }) => css`
    height: 40px;
    padding: 12px 16px;
    border: solid 1px ${buttonBorderColor};
    ${isOpen
      ? `
        background: ${buttonBackgroundOpen};
        border-radius: ${buttonBorderRadius || `4px 4px 0 0`};
        ${buttonBorderOpen && `border-color: ${buttonBorderOpen}`};
    `
      : `background: ${buttonBackground};`};
    color: ${buttonTextColor};
    font-weight: 400;
    transition: background 0.3s ease-in-out, color 0.3s ease-in-out;

    &:hover ${ButtonText} {
      color: ${buttonTextColorHover || buttonTextColorOpen};
    }

    ${carretColorHover &&
    `
      &:hover ${CarretDownStyled},
      &:hover ${CarretUpStyled} {
        fill: ${carretColorHover};
    `}
  `}
`;
