import {gql} from '@apollo/client';

export const validateTokenMutation = gql`
  mutation validateToken($input: ValidateTokenInput!) {
    validateToken(input: $input) {
      success
      errors {
        code
        msg
      }
    }
  }
`;
