import {ApolloError, FetchResult} from '@apollo/client';
import type {ProductSDK} from '../../../ProductSDK';
import {loginMutation} from '../../../graphql/mutation/login';
import {SessionTokens} from '../../../models/SessionTokens';
import {requireDefined} from '../../../utils/nullability-utils';
import {Operation} from '../../../models/Operation';
import {requireSuccessfulOperation} from '../../../utils/operations-utils';
import {AutologinInput} from './models/AutologinInput';
import {InvalidAutologinToken} from './exceptions/InvalidAutologinToken';

type Results = FetchResult<{login: Operation<{tokens: SessionTokens}>}>;

/**
 * @throws InvalidAutologinToken
 * @throws OperationFailError
 */
export async function autologin(this: ProductSDK, autologinToken: string): Promise<SessionTokens> {
  const variables: {input: AutologinInput} = {
    input: {
      session: {autologinToken},
    },
  };

  try {
    const response: Results = await this.client.mutate({
      mutation: loginMutation,
      variables: variables,
    });

    const data = requireDefined(response.data?.login);
    requireSuccessfulOperation(data);

    return data.tokens;
  } catch (e: unknown) {
    if (e instanceof ApolloError) {
      if (e.graphQLErrors.length > 0) {
        switch (e.graphQLErrors[0].message) {
          case InvalidAutologinToken.ERR_MESSAGE:
            throw new InvalidAutologinToken(e.message);
        }
      }
    }

    throw e;
  }
}
