import {ReactComponent as ar} from './flags/ar.svg';
import {ReactComponent as br} from './flags/br.svg';
import {ReactComponent as cz} from './flags/cz.svg';
import {ReactComponent as de} from './flags/de.svg';
import {ReactComponent as en} from './flags/en.svg';
import {ReactComponent as es} from './flags/es.svg';
import {ReactComponent as fr} from './flags/fr.svg';
import {ReactComponent as gb} from './flags/gb.svg';
import {ReactComponent as it} from './flags/it.svg';
import {ReactComponent as pt} from './flags/pt.svg';
import {ReactComponent as ru} from './flags/ru.svg';
import {ReactComponent as tr} from './flags/tr.svg';
import {ReactComponent as uk} from './flags/uk.svg';
import {ReactComponent as us} from './flags/us.svg';

const flags: any = {
  ar,
  br,
  cz,
  de,
  en,
  es,
  fr,
  gb,
  it,
  pt,
  ru,
  tr,
  uk,
  us,
};

export const getFlag = (language = 'en') => flags[language.toLowerCase()];
