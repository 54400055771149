import {fromJS} from 'immutable';
import {prepareReducers} from '@/app/utils/redux';
import actions from './actions';

export const initialState = fromJS({
  isLoading: false,
  isError: false,
  movies: [],
  total: null,
});

const reducer = prepareReducers(
  {
    [actions.getMoviesToWatchAction]: (state) => state.set('isLoading', true).set('isError', false),
    [actions.getMoviesToWatchSuccessAction]: (state, {payload}) =>
      state.set('isLoading', false).set('isError', false).set('movies', payload.movies).set('total', payload.total),
    [actions.getMoviesToWatchFailedAction]: (state) => state.set('isLoading', false).set('isError', true),
    [actions.unwatchAction]: (state) => state.set('isLoading', true).set('isError', false),
    [actions.unwatchSuccessAction]: (state, {payload}) =>
      state
        .set('isLoading', false)
        .set('isError', false)
        .update('movies', (movies) => movies.filter(movie => movie.id !== payload.id))
        .update('total', (total) => total - 1),
    [actions.unwatchFailedAction]: (state) => state.set('isLoading', false).set('isError', true),
    [actions.watchLaterAction]: (state) => state.set('isLoading', true).set('isError', false),
    [actions.watchLaterSuccessAction]: (state) => state.set('isLoading', false).set('isError', false),
    [actions.watchLaterFailedAction]: (state) => state.set('isLoading', false).set('isError', true),
  },
  initialState
);

export default reducer;
