import NotificationItem from './NotificationItem';
import {NotificationWrapper} from './Notifications.styled';
import INotificationsTheme from './INotificationsTheme';

interface NotificationsProps {
  removeNotification: Function;
  notifications: Array<{id: string; type: 'danger' | 'info' | 'warning' | 'success'; message: string}>;
  componentTheme: INotificationsTheme;
}

function Notifications(props: NotificationsProps) {
  const {notifications, removeNotification, componentTheme, ...rest} = props;
  return (<NotificationWrapper {...rest}>
    {notifications &&
      notifications.map((item) => (
        <NotificationItem
          message={item.message}
          type={item.type}
          key={item.id}
          id={item.id}
          removeNotification={removeNotification}
          componentTheme={componentTheme}
        />
      ))}
  </NotificationWrapper>)

}

export default Notifications;
