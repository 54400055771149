import {gql} from '@apollo/client';

export const getCategoriesListQuery = gql`
  query getCategoriesList(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $sort: SortInput
    $categoryType: CategoryTypeEnum
  ) {
    categories(before: $before, after: $after, first: $first, last: $last, sort: $sort, categoryType: $categoryType) {
      nodes {
        categoryType
        id
        name
        subcategories {
          categoryType
          id
          name
        }
      }
    }
  }
`;
