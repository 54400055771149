import {all, call, put, takeLatest} from 'redux-saga/effects';
import {actions as genresActions} from '@/app/reducers/genres';
import {actions as notificationActions} from '@/app/reducers/notification';
import Logger from '@/app/modules/logger';
import {prepareError} from '@/app/utils/sagas';
import {CategoryType, getCategoriesList} from '@dev7/altar-product-sdk';
import {productSDK} from '../modules/productSDK';

const logger = Logger.getInstance('GenresSaga');

export function* getGenresSaga() {
  try {
    const connection = yield call(productSDK.call, getCategoriesList, {categoryType: CategoryType.GENRE});
    yield put(genresActions.getGenresSuccessAction(connection.nodes));
  } catch (error) {
    yield put(genresActions.getGenresFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export default function* watchForGenres() {
  yield all([takeLatest(genresActions.getGenresAction, getGenresSaga)]);
}
