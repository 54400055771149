import {gql} from '@apollo/client';

export const refreshTokenMutation = gql`
  mutation refreshToken($input: RefreshTokenInput!) {
    refreshToken(input: $input) {
      tokens {
        accessToken
        refreshToken
        expiry
      }
      success
      errors {
        code
        msg
      }
    }
  }
`;
