import {createSelector} from 'reselect';

const selectors = {
  selectState: (state) => state.get('info'),
  getInfo: () => createSelector(selectors.selectState, (auth) => auth.get('data')),
  getEntityByCountry: (country) =>
    createSelector(selectors.getInfo, (info) => info.get('entities').find((entity) => entity.country === country)),
};

export default selectors;
