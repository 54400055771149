import styled from 'styled-components';
import {ReactComponent as EyeShowIcon} from '@/app/assets/svg/icon_eye.svg';
import {ReactComponent as EyeHideIcon} from '@/app/assets/svg/icon_eye_hide.svg';

const iconStyles = `
  position: absolute;
  width: 24px;
  height: 24px;
  top: 40px;
  right: 16px;
  &:hover {
    cursor: pointer;
  }
`;

export const EyeShow = styled(EyeShowIcon)`
  ${iconStyles};
  fill: ${(props) => props.theme.colors.medium};
`;
export const EyeHide = styled(EyeHideIcon)`
  ${iconStyles};
  fill: ${(props) => props.theme.colors.medium};
`;
