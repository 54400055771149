import {fromJS} from 'immutable';
import {prepareReducers} from '@/app/utils/redux';
import actions from './actions';

export const initialState = fromJS({
  isLoading: false,
  moviesStatistics: [],
});

const reducer = prepareReducers(
  {
    [actions.getMoviesStatisticsAction]: (state) => state.set('isLoading', true),
    [actions.getMoviesStatisticsSuccessAction]: (state, {payload}) => state.set('moviesStatistics', payload),
    [actions.getMoviesStatisticsFailedAction]: (state) => state.set('isLoading', true),
  },
  initialState
);

export default reducer;
