import styled from 'styled-components';

export const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 5%;
  right: 0;
  z-index: 20;
`;
