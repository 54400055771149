import {Operation, SuccessOperation} from '../models/Operation';
import {OperationFailError} from '../exceptions/OperationFailError';
import {AnyObject} from './typescript-utils';

/**
 * @throws OperationFailError
 */
export function requireSuccessfulOperation<T>(data: Operation<T>): asserts data is SuccessOperation<T> {
  if (!data.success || data.errors.length > 0) throw new OperationFailError(data.errors as AnyObject[]);
}
