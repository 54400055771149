import {all, call, put, takeLatest} from 'redux-saga/effects';
import {actions as sessionInfoActions} from '@/app/reducers/sessionInfo';
import {actions as notificationActions} from '@/app/reducers/notification';
import Logger from '@/app/modules/logger';
import {prepareError} from '@/app/utils/sagas';
import * as registerApi from '@/app/api/register';

const logger = Logger.getInstance('sessionInfoSaga');

export function* getSessionInfoSaga() {
  try {
    const result = yield call(registerApi.getSession);
    yield put(sessionInfoActions.getSessionInfoSuccessAction(result.context));
  } catch (error) {
    yield put(sessionInfoActions.getSessionInfoFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export default function* watchForSessionInfo() {
  yield all([takeLatest(sessionInfoActions.getSessionInfoAction, getSessionInfoSaga)]);
}
