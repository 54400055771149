import styled from 'styled-components';
import IIconTheme from './IIconTheme';

const IconStyled = styled.span<{componentTheme: IIconTheme}>`
  width: 14px;
  height: 14px;
  display: inline-block;
  > svg {
    fill: ${({componentTheme}) => componentTheme.fill};
  }
`;

/**
 * Column properties.
 */
export interface IconProps {
  /** prop1 description */
  icon: any;
  componentTheme: IIconTheme;
}

/**
 * Form column.
 */
function Icon(props: IconProps) {
  const {icon: IconInside, componentTheme, ...rest} = props;
  return (
    <IconStyled componentTheme={componentTheme} {...rest}>
      <IconInside />
    </IconStyled>
  );
}

export default Icon;
