import {ReactNode} from 'react';
import Throbber from '../Throbber';
import {ButtonInnerStyled, ButtonStyled, LabelStyled} from './Button.styled';
import Icon from '../Icon';
import IButtonTheme from './IButtonTheme';

export enum ButtonColors {
  active = 'active',
  disabled = 'disabled',
  light = 'light',
  brand = 'brand',
  danger = 'danger',
  info = 'info',
  success = 'success',
}

export enum ButtonSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

interface ButtonProps {
  children: ReactNode | string,
  color?: ButtonColors;
  size?: ButtonSizes;
  label?: any;
  isLoading?: boolean;
  disabled?: boolean;
  icon?: any;
  componentTheme: IButtonTheme;
  onClick?: Function;
}

function Button(props: ButtonProps) {
  const {
    children,
    label,
    isLoading = false,
    disabled = false,
    icon,
    color = ButtonColors.brand,
    componentTheme,
    ...rest
  
  } = props;
  const onCallback = () => {
    rest.onClick && rest.onClick();
  };
  return (
    <ButtonStyled
      isLoading={isLoading}
      {...{...rest, color}}
      disabled={disabled}
      componentTheme={componentTheme}
      onClick={() => onCallback()}
    >
      {icon && <Icon icon={icon} {...rest} />}
      {icon && (children || label) ? (
        <LabelStyled>{children || label}</LabelStyled>
      ) : (
        <ButtonInnerStyled>{children || label}</ButtonInnerStyled>
      )}
      {isLoading && <Throbber />}
    </ButtonStyled>
  );
}

export default Button;
