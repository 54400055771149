import { getDomain } from '@/app/utils/domain';
import { __STAGING__ } from '@/app/config';
import { deleteCookie } from '@/app/modules/cookies';

export function removeAllLiveAgentCookies() {
  const liveAgentCookies = [
    'LaSID',
    'LaVisitorId_',
    'LaVisitorNew',
    'LaReset',
    'LaRunningChat_',
    'LaUserDetails',
    'closedInvitations',
    'V_la_sid',
  ];
  let liveAgentCookiesExist = false;
  document.cookie.split(';').forEach(item => {
    const cookie = item.split('=');
    liveAgentCookies.forEach(agentCookie => {
      if (cookie['0'].includes(agentCookie)) {
        liveAgentCookiesExist = true;
        deleteCookie(cookie['0']);
        if (agentCookie === 'LaRunningChat_' || agentCookie === 'LaVisitorId_') {
          deleteCookie(cookie['0'], __STAGING__ ? '.sheep.guru' : `.${getDomain()}`);
        } else {
          deleteCookie(cookie['0']);
        }
      }
    });
  });
  return liveAgentCookiesExist;
}
