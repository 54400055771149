import {SessionTokens} from '@dev7/altar-product-sdk';
import {LocalStorage} from '../mechanisms/LocalStorage';

const SESSION_TOKENS_KEY = 'sessionTokens';
const LOCALES_LANG_KEY = 'localesLang';

export function saveSessionTokens(sessionTokens: SessionTokens): void {
  LocalStorage.setJsonItem(SESSION_TOKENS_KEY, sessionTokens);
}

export function readSessionTokens(): SessionTokens | null {
  return LocalStorage.getJsonItem(SESSION_TOKENS_KEY);
}

export function purgeSessionTokens(): void {
  LocalStorage.unsetItem(SESSION_TOKENS_KEY);
}

export function saveLocalesLang(lang: string) {
  LocalStorage.setItem(LOCALES_LANG_KEY, lang)
}

export function readLocalesLang(): string | null {
  return LocalStorage.getItem(LOCALES_LANG_KEY);
}
