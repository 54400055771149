import { Fragment, Component } from 'react';
import { checkIfCookieExists, deleteCookie, setCookie } from '@/app/modules/cookies';
import { ROUTES_GDPR } from '@/app/Routes';
import TitleSource from '@dev7/altar-alt-ui/Title';
import {
  CheckboxField,
  Checkmark,
  CookieBackdrop,
  CookiesSmallStyled,
  CookiesStyled,
  Link,
  NecessaryWrapper,
  Paragraph,
  SelectAllWrapper,
  Title,
  Wrapper,
} from './AdvanceCookies.styled';
import FakeChat from '../FakeChat';
import { getConfig } from '@/app/utils/general';
import { Checkboxes } from './Checkboxes';
import { removeAllLiveAgentCookies } from './removeAllLiveAgentCookies';

const date = new Date(Date.now() + 3600000 * 24 * 14);

export const cookieAgreement = { name: 'cookies_agreement', value: true, expires: date };
const cookieSessionBanner = { name: 'cookie_session_banner', value: true };
export const cookieLiveAgent2Week = { name: 'la_cookie-accept', value: true, expires: date };
const cookieLang = { name: 'lang', expires: date };
const cookiePerformance = { name: 'cookie_performance', value: true };
const cookieTargeting = { name: 'cookie_targeting', value: true };
const cookiePerformanceWithExp = { name: 'cookie_performance', value: true, expires: date };
const cookieTargetingWithExp = { name: 'cookie_targeting', value: true, expires: date };
const cookieLiveAgentSession = { name: '__liveagent', value: true };
const { hasFakeChat } = getConfig();
const checkIfFunctionalityCookies =
  checkIfCookieExists(cookieAgreement.name) ||
  checkIfCookieExists(cookieLiveAgent2Week.name) ||
  checkIfCookieExists(cookieLang.name);

class AdvanceCookies extends Component {
  state = {
    isVisible:
      !checkIfCookieExists(cookieAgreement.name) && !checkIfCookieExists(cookieSessionBanner.name) && this.props.europe,
    isChatOpen: checkIfCookieExists(cookieLiveAgent2Week.name) || checkIfCookieExists(cookieLiveAgentSession.name),
    isLiveAgentOpen: false,
    isFakeChatOpen: false,
    chatStarted: !hasFakeChat,
    allChecked: false,
    checkbox1: checkIfCookieExists(cookiePerformance.name),
    checkbox2: checkIfFunctionalityCookies,
    checkbox3: checkIfCookieExists(cookieTargeting.name),
    checkbox4: checkIfCookieExists(cookieLiveAgent2Week.name) || checkIfCookieExists(cookieLiveAgentSession.name),
  };

  componentDidMount() {
    if (this.state.checkbox4 === false) {
      removeAllLiveAgentCookies();
    }
  }

  toggleExpanded = () => {
    this.props.openCookie();
    this.setState((prev) => ({ isVisible: !prev.isVisible }));
  };

  handleCheckboxChange = (item) => {
    const checkbox = item.state;
    this.setState({ [item.id]: !checkbox });
  };

  handleCheckAll = () => {
    this.setState((prev) => ({
      allChecked: !prev.allChecked,
      checkbox1: !prev.allChecked,
      checkbox2: !prev.allChecked,
      checkbox3: !prev.allChecked,
      checkbox4: !prev.allChecked,
    }));
  };

  handleAgreement = () => {
    const { checkbox1, checkbox2, checkbox3, checkbox4 } = this.state;

    if (checkbox1 === true) {
      if (checkbox2 === true) {
        setCookie(cookiePerformanceWithExp);
      } else {
        setCookie(cookiePerformance);
      }
    } else {
      deleteCookie(cookiePerformance.name);
    }
    if (checkbox2 === true) {
      setCookie(cookieAgreement);
      if (checkbox4 === true) setCookie(cookieLiveAgent2Week);
      setCookie(cookieLang);
      deleteCookie(cookieSessionBanner.name);
    } else {
      setCookie(cookieSessionBanner);
      deleteCookie(cookieAgreement.name);
      deleteCookie(cookieLiveAgent2Week.name);
      deleteCookie(cookieLang.name);
    }
    if (checkbox3 === true) {
      if (checkbox2 === true) {
        setCookie(cookieTargetingWithExp);
      } else {
        setCookie(cookieTargeting);
      }
    } else {
      deleteCookie(cookieTargeting.name);
    }
    if (checkbox4 === true) {
      this.setState({ isChatOpen: true });
      setCookie(cookieLiveAgentSession);
      if (checkbox2 === true) setCookie(cookieLiveAgent2Week);
    } else {
      this.setState({ isChatOpen: false });
      deleteCookie(cookieLiveAgentSession.name);
      deleteCookie(cookieLiveAgent2Week.name);
      document.querySelectorAll('.i-embedded-chat').forEach(item => {
        item.remove();
      });
      if (removeAllLiveAgentCookies()) {
        window.location.reload(true);
      }
    }
    if (checkbox4 === true && checkbox2 === false) {
      deleteCookie(cookieLiveAgent2Week.name);
    }

    this.handleClose();
    this.handleSmallClose();
  };

  handleClose = () => {
    if (this.props.isCookiesVisible) {
      this.props.closeCookie();
    }
  };

  handleSmallClose = () => {
    if (this.state.isVisible) {
      this.setState({ isVisible: false });
    }
  };

  onToggleFakeChat = isOpen => {
    this.setState({ isFakeChatOpen: isOpen });
  };
  onToggleChat = isOpen => {
    this.setState({ isLiveAgentOpen: isOpen });
  };
  onStartChat = started => {
    this.setState({
      chatStarted: started,
      isLiveAgentOpen: true,
      checkbox4: checkIfCookieExists(cookieLiveAgent2Week.name) || checkIfCookieExists(cookieLiveAgentSession.name),
    });
  };

  render() {
    const {
      isVisible,
      isChatOpen,
      isLiveAgentOpen,
      isFakeChatOpen,
      chatStarted,
      allChecked,
      checkbox1,
      checkbox2,
      checkbox3,
      checkbox4,
    } = this.state;
    const { t, europe, isCookiesVisible, isUS } = this.props;

    return [
      <CookiesSmallStyled
        buttonText={t('Choose your settings')}
        onClick={this.toggleExpanded}
        onClose={this.handleSmallClose}
        cookieCloseTooltip={t('Reject all')}
        ariaLabelClose={t('Close')}
        hasFakeChat={hasFakeChat}
        isLiveAgent={isChatOpen}
        isFakeChatOpen={chatStarted || isChatOpen ? isLiveAgentOpen : isFakeChatOpen}
        key="cookies"
        isVisible={isVisible}
        isExpanded={isCookiesVisible}
      >
        <Title forwardedAs='h3'>{t('This website uses cookies.')}</Title>
        <Paragraph pType='p3'>
          {t(
            'We use various types of cookies for different purposes on our website. Please check the ones that you agree to receive below. These cookies (with the exception of Strictly necessary cookies) are not necessary for the service to function properly and the acceptance is fully voluntary. You can read the full policy and change your preferences at any time by clicking '
          )}
          <Link url={ROUTES_GDPR.COOKIES.path} type='anchor' target='_blank'>
            {t('here.')}
          </Link>
        </Paragraph>
      </CookiesSmallStyled>,

      <CookieBackdrop key='backdrop' isExpanded={isCookiesVisible}>
        <CookiesStyled
          buttonText={t('Save preferences')}
          onClick={this.handleAgreement}
          onClose={this.handleClose}
          role='navigation'
          ariaLabelClose={t('Close')}
          key='cookies'
        >
          <Title forwardedAs='h3'>{t('This website uses cookies.')}</Title>
          <Paragraph pType='p3'>
            {t(
              'We use various types of cookies for different purposes on our website. Please check the ones that you agree to receive below. These cookies (with the exception of Strictly necessary cookies) are not necessary for the service to function properly and the acceptance is fully voluntary. You can read the full policy and change your preferences at any time by clicking '
            )}
            <Link url={ROUTES_GDPR.COOKIES.path} type='anchor' target='_blank'>
              {t('here.')}
            </Link>
          </Paragraph>
          <Wrapper>
            <SelectAllWrapper onClick={this.handleCheckAll}>
              <Checkmark square visited={allChecked} />
              <Paragraph pType='p2'>{t('Select All')}</Paragraph>
            </SelectAllWrapper>
            <NecessaryWrapper>
              <TitleSource forwardedAs='h4'>{t('Strictly necessary cookies')}</TitleSource>
              <Paragraph pType='p3'>
                {t(
                  'These cookies enable the core services of the website, including establishing a secure connection, viewing and billing modules. The website won’t work properly without them, and they can be only turned off in your browser settings.'
                )}
              </Paragraph>
            </NecessaryWrapper>
            {Checkboxes(t, isUS, checkbox1, checkbox2, checkbox3, checkbox4).map((item) => (
              <CheckboxField onClick={() => this.handleCheckboxChange(item)} key={item.id}>
                <Checkmark square visited={item.state} />
                <div>
                  <TitleSource forwardedAs='h4'>{item.title}</TitleSource>
                  <Paragraph pType='p3'>
                    {item.description}
                    {item.link && item.link}
                  </Paragraph>
                </div>
              </CheckboxField>
            ))}
          </Wrapper>
        </CookiesStyled>
      </CookieBackdrop>,
      <Fragment key='fake-chat'>
          <FakeChat
            hasFakeChat={isChatOpen || !europe ? false : hasFakeChat}
            cookiesAccepted={checkIfCookieExists(cookieLiveAgent2Week.name) || checkIfCookieExists(cookieLiveAgentSession.name)}
            onToggleFakeChat={this.onToggleFakeChat}
            onToggleChat={this.onToggleChat}
            onStartChat={this.onStartChat}
            key='chat'
            test={this.props.test}
          />
      </Fragment>,
    ];
  }
}

export default AdvanceCookies;
