import {HamburgerInnerStyled, HamburgerLineStyled, HamburgerStyled} from './Hamburger.styled';
import IHamburgerTheme from './IHamburgerTheme';

interface HamburgerProps {
  isActive?: boolean;
  onClick?: Function;
  componentTheme: IHamburgerTheme;
}

function Hamburger(props: HamburgerProps) {
  const {isActive, onClick = () => {}, componentTheme, ...rest} = props;
  return (
    <HamburgerStyled onClick={() => onClick()} componentTheme={componentTheme} {...rest}>
      <HamburgerInnerStyled componentTheme={componentTheme}>
        <HamburgerLineStyled isActive={isActive} componentTheme={componentTheme} />
        <HamburgerLineStyled isActive={isActive} componentTheme={componentTheme} />
        <HamburgerLineStyled isActive={isActive} componentTheme={componentTheme} />
      </HamburgerInnerStyled>
    </HamburgerStyled>
  );
}

export default Hamburger;
