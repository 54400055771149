import {getContext, wrapDisplayName} from 'recompose';
import {forceUpdatedComponent} from './forceUpdatedComponent';

// eslint-disable-next-line
const noopFunction = () => {};

/**
 * Provides the given component with translator functions
 * as props.
 */
export default function withTranslators(WrappedComponent) {
  const forceUpdatedWrappedComponent = forceUpdatedComponent(WrappedComponent);
  const withTranslators = getContext({locale: noopFunction, t: noopFunction, tcnp: noopFunction, transformInput: noopFunction})(
    forceUpdatedWrappedComponent
  );
  withTranslators.displayName = wrapDisplayName(WrappedComponent, 'withTranslators');
  return withTranslators;
}
