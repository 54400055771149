import {
  Category,
  CategoryType,
  Content,
  ContentFile,
  ContentKnownProperty,
  ContentProperty,
} from '@dev7/altar-product-sdk';
import {formatDateToDigitalTime, formatDateToSimpleTime, formatDecimalMinutesToDate} from './time-utils';

export function getContentProperty(content: Content, propertyName: ContentKnownProperty | string): string | undefined {
  return content.properties.find((property: ContentProperty) => property.name === propertyName)?.value ?? undefined;
}

export function getContentAuthor(content: Content): string | undefined {
  return getContentProperty(content, ContentKnownProperty.AUTHOR);
}

export function getContentDuration(content: Content, format: 'simple' | 'digital'): string | undefined {
  const propertyValue = getContentProperty(content, ContentKnownProperty.DURATION_MINUTES);
  if (!propertyValue) return undefined;

  const parsedMinutes = parseFloat(propertyValue);
  if (isNaN(parsedMinutes)) return undefined;

  const date = formatDecimalMinutesToDate(parsedMinutes);
  if (isNaN(date.getTime())) return undefined;

  return formatTimeDuration(date, format);
}

export function getContentFileDuration(contentFile: ContentFile, format: 'simple' | 'digital'): string | undefined {
  if (contentFile.durationMinutes === null) return undefined;

  const date = formatDecimalMinutesToDate(contentFile.durationMinutes);
  if (isNaN(date.getTime())) return undefined;

  return formatTimeDuration(date, format);
}

export function formatTimeDuration(date: Date, format: 'simple' | 'digital'): string | undefined {
  if (isNaN(date.getTime())) return undefined;

  switch (format) {
    case 'simple':
      return formatDateToSimpleTime(date, 'hours');
    case 'digital':
    default:
      return formatDateToDigitalTime(date, 'minutes');
  }
}

export function getContentGenres(content: Content): Category[] {
  return content.categories.filter((category: Category) => category.categoryType === CategoryType.GENRE);
}
