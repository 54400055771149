import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {withRouter} from 'react-router-dom';
import {withTheme} from 'styled-components';
import {compose} from 'redux';
import {toJS} from '@/app/utils/immutable/toJS';
import {selectors as userSelectors} from '@/app/reducers/user';
import withI18Context from '@/app/ui/HOC/withI18Context';
import {withTranslators} from '@/app/lib/lioness';
import Header from './Header';

const mapStateToProps = createStructuredSelector({
  user: userSelectors.getUser(),
});

const withConnect = connect(mapStateToProps);

export default compose(withRouter, withTheme, withConnect, withI18Context, withTranslators)(toJS(Header));
