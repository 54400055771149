import {gql} from '@apollo/client';

export const addUserContentMutation = gql`
  mutation addUserContent($input: AddUserContentInput!) {
    addUserContent(input: $input) {
      success
      errors {
        code
        msg
      }
    }
  }
`;
