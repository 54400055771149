import Icon from './../Icon';
import {ScrollTopStyled} from './ScrollTop.styled';
import IScrollTopTheme from './IScrollTopTheme';

interface ScrollTopProps {
  icon?: any;
  description?: string;
  ariaLabel?: string;
  componentTheme: IScrollTopTheme;
}

function ScrollTop(props: ScrollTopProps) {
  const {icon, description, componentTheme, ariaLabel, ...rest} = props;
  const scrollTop = () => {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

    if (top > 0) {
      window.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
  };
  return (
    <ScrollTopStyled onClick={scrollTop} componentTheme={componentTheme} aria-label={ariaLabel} {...rest}>
      {icon && <Icon icon={icon} componentTheme={componentTheme} />}
      {description}
    </ScrollTopStyled>
  );

}

export default ScrollTop;
