import {FetchResult} from '@apollo/client';
import type {ProductSDK} from '../../../ProductSDK';
import {requireDefined} from '../../../utils/nullability-utils';
import {ContentsListConnection} from './models/ContentsListConnection';
import {getContentsListQuery} from '../../../graphql/query/getContentsList';
import {ContentsListOptions} from './models/ContentsListOptions';

export async function getContentsList(
  this: ProductSDK,
  options: ContentsListOptions = {}
): Promise<ContentsListConnection> {
  const response: FetchResult<{contents: ContentsListConnection}> = await this.client.query({
    query: getContentsListQuery,
    variables: options,
  });

  return requireDefined(response.data?.contents);
}
