import media from './media.styled';
import sections from '@/product/styles/sections';
import {ResponsiveSection} from './../styles.interface';

interface IAppyStyles {
  section: string;
  component: string;
}

const applyStyles = ({section, component}: IAppyStyles) => {
  const applySection: ResponsiveSection = sections[section];
  return (
    applySection &&
    applySection[component] &&
    Object.keys(applySection[component]).map((size) =>
      media[size](
        Object.keys(applySection[component][size]).reduce(
          (prev, item) => `${prev} ${item}: ${applySection[component][size][item]}; `,
          ''
        )
      )
    )
  );
};

export default applyStyles;
