export const getDomain = (defaultDomain = null) => {
  return defaultDomain || window.location.hostname || window.location.host;
};

export const DomainUrlTypes = {
  domain: 'DOMAIN',
  support: 'SUPPORT',
  api: 'API',
  session: 'SESSION',
};

export const buildUrlFromDomain = (
  urlType = DomainUrlTypes.domain
  // registerRouter: string = 'router',
) => {
  const domain = getDomain();
  switch (urlType) {
    case DomainUrlTypes.domain:
      return domain;
    case DomainUrlTypes.support:
      return `support@${domain}`;
    case DomainUrlTypes.api:
      return `https://api.${domain}`;
    // case DomainUrlTypes.session:
    // return genericApi ? `https://${registerRouter}.${domain}/api/` : `https://${registerRouter}.${domain}/api/`;
    default:
      return domain;
  }
};

export const buildSalesScriptUrl = (merchant_id, script_name) => `/s/${merchant_id}/${script_name}`;
