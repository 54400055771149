import {call, put} from 'redux-saga/effects';
import {actions} from '@/app/reducers/info';
import {actions as notificationActions} from '@/app/reducers/notification';
import Logger from '@/app/modules/logger';
import {prepareError} from '@/app/utils/sagas';
import {getDomainInfo} from '@dev7/altar-product-sdk';
import {convertDomainInfo} from '../utils/migration/convertDomainInfo';
import {productSDK} from '../modules/productSDK';

const logger = Logger.getInstance('InfoSaga');

export function* infoSaga() {
  try {
    logger.debug('start');
    const info = yield call(productSDK.call, getDomainInfo);
    const oldInfo = convertDomainInfo(info);
    yield put(actions.getInfoSuccessAction(oldInfo));
    return oldInfo;
  } catch (error) {
    // eslint-disable-next-line no-console
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export default infoSaga;
