import {compose} from 'redux';
import {connect} from 'react-redux';
import {withTranslators} from '@/app/lib/lioness';
import {createStructuredSelector} from 'reselect';
import {selectors as infoSelectors} from '@/app/reducers/info';
import {withInfo} from '@/app/ui/HOC/withInfo';
import Footer from './Footer';

const mapStateToProps = createStructuredSelector({
  info: infoSelectors.getInfo(),
});

const withConnect = connect(mapStateToProps, null);

export default compose(withConnect, withTranslators, withInfo)(Footer);
