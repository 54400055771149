import {all} from 'redux-saga/effects';

import genresSaga from './genres';
import movieSaga from './movie';
import moviesSaga from './movies';
import moviesToWatchSaga from './moviesToWatch';
import promoMoviesSaga from './promoMovies';
import recommendedMoviesSaga from './recommendedMovies';
import registerSaga from './register';
import startupSaga from './startup';
import supportSaga from './support';
import userSaga from './user';
import sessionInfoSaga from './sessionInfo';

export default function* rootSaga() {
  yield all([
    genresSaga(),
    movieSaga(),
    moviesSaga(),
    moviesToWatchSaga(),
    promoMoviesSaga(),
    recommendedMoviesSaga(),
    registerSaga(),
    startupSaga(),
    supportSaga(),
    userSaga(),
    sessionInfoSaga(),
  ]);
}
