import {fromJS} from 'immutable';
import {prepareReducers} from '@/app/utils/redux';
import actions from './actions';

export const initialState = fromJS({
  isLoading: false,
});

const reducer = prepareReducers(
  {
    [actions.sendSupportAction]: (state) => state.set('isLoading', true),
    [actions.sendSupportSuccessAction]: (state) => state.set('isLoading', false),
    [actions.sendSupportFailedAction]: (state) => state.set('isLoading', false),
  },
  initialState
);

export default reducer;
