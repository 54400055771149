import {fromJS} from 'immutable';
import {prepareReducers} from '@/app/utils/redux';
import actions from './actions';

export const initialState = fromJS({
  isLoading: false,
  // Browse
  browseMovies: [],
  browseTotal: null,
  browsePagination: null,
  // Search
  searchMovies: [],
  searchTotal: null,
  // Recent
  recentMovies: [],
  recentTotal: null,
  // Staff pick
  staffPickMovies: [],
  staffPickTotal: null,
});

const reducer = prepareReducers(
  {
    // recentMovies
    [actions.getRecentMoviesAction]: (state) => state.set('isLoading', true),
    [actions.getRecentMoviesSuccessAction]: (state, {payload}) =>
      state.set('isLoading', false).set('recentMovies', payload.movies).set('recentTotal', payload.total),
    [actions.getRecentMoviesFailedAction]: (state) => state.set('isLoading', false),
    // staffPick
    [actions.getStaffPickMoviesAction]: (state) => state.set('isLoading', true),
    [actions.getStaffPickMoviesSuccessAction]: (state, {payload}) =>
      state.set('isLoading', false).set('staffPickMovies', payload.movies).set('staffPickTotal', payload.total),
    [actions.getStaffPickMoviesFailedAction]: (state) => state.set('isLoading', false),
    // browseMovies
    [actions.fetchBrowseMoviesAction]: (state) => state.set('isLoading', true).set('browsePagination', null),
    [actions.fetchBrowseMoviesSuccessAction]: (state, {payload}) =>
      state
        .set('isLoading', false)
        .set('browseMovies', payload.nodes)
        .set('browseTotal', payload.totalCount)
        .set('browsePagination', payload.pageInfo),
    [actions.fetchBrowseMoviesFailedAction]: (state) => state.set('isLoading', false),
    [actions.fetchMoreBrowseMoviesAction]: (state) => state.set('isLoading', true),
    [actions.fetchMoreBrowseMoviesSuccessAction]: (state, {payload}) =>
      state
        .set('isLoading', false)
        .update('browseMovies', (movies) => movies.concat(payload.nodes))
        .set('browseTotal', payload.totalCount)
        .set('browsePagination', payload.pageInfo),
    [actions.fetchMoreBrowseMoviesFailedAction]: (state) => state.set('isLoading', false),
    // searchMovies
    [actions.getSearchMoviesAction]: (state) => state.set('isLoading', true),
    [actions.getSearchMoviesSuccessAction]: (state, {payload}) =>
      state.set('isLoading', false).set('searchMovies', payload.nodes).set('searchTotal', payload.totalCount),
    [actions.getSearchMoviesFailedAction]: (state) => state.set('isLoading', false),
  },
  initialState
);

export default reducer;
