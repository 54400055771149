export class InvalidResetPasswordTokenError extends Error {
  public static ERR_CODE = 203;
  public static ERR_MESSAGE = 'Reset password token is invalid';

  constructor() {
    super('Reset password token is invalid');
    this.name = InvalidResetPasswordTokenError.name;
    if (Object.hasOwnProperty.call(Error, 'captureStackTrace')) Error.captureStackTrace(this);
  }
}
