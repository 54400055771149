import styled, {css} from 'styled-components';
import IHamburgerTheme from './IHamburgerTheme';

function transformHamburgerLine(translateY: number, rotate: number) {
  return css`
    transform: translateY(${translateY}px) rotate(${rotate}deg);
  `;
}

interface IHamburgerLineStyledProps {
  isActive?: boolean;
  componentTheme: IHamburgerTheme;
}

export const HamburgerLineStyled = styled.span<IHamburgerLineStyledProps>`
  ${({componentTheme: {color, hoverLineBackground}, isActive}) => css`
    display: block;
    width: 100%;
    height: 1px;
    background-color: ${color};
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: ${hoverLineBackground};
    }

    &:nth-child(1) {
      ${isActive && transformHamburgerLine(5, 45)};
    }

    &:nth-child(2) {
      ${isActive && `opacity: 0;`}
    }

    &:nth-child(3) {
      ${isActive && transformHamburgerLine(-5, -45)};
    }
  `}
`;

export const HamburgerInnerStyled = styled.div<{componentTheme: IHamburgerTheme}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 12px;
  height: 11px;
`;

export const HamburgerStyled = styled.div<{componentTheme: IHamburgerTheme}>`
  ${({componentTheme: {border, borderRadius, background, backgroundHover, borderHover, hoverLineBackground}}) => css`
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: solid 1px ${border};
    border-radius: ${borderRadius || '3px'};
    background: ${background};
    cursor: pointer;
    transition: 0.3s;
    z-index: 1;
    &:hover {
      background: ${backgroundHover};
      border-color: ${borderHover};
      ${HamburgerLineStyled} {
        background-color: ${hoverLineBackground};
      }
    }
  `}
`;
