import {LdsRoller, LdsRollerInner} from './Dots.styled';

interface DotsProps {
  color: string;
  centered?: boolean;
}

function Dots(props: DotsProps) {
  const {color, ...rest} = props;
  return (
    <LdsRoller {...rest}>
      <LdsRollerInner color={color} />
      <LdsRollerInner color={color} />
      <LdsRollerInner color={color} />
      <LdsRollerInner color={color} />
      <LdsRollerInner color={color} />
      <LdsRollerInner color={color} />
      <LdsRollerInner color={color} />
      <LdsRollerInner color={color} />
    </LdsRoller>
  );
}

export default Dots;
