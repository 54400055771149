import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import {actions as userActions} from '@/app/reducers/user';
import {actions as notificationActions} from '@/app/reducers/notification';
import {actions as forgotFlowActions} from '@/app/reducers/forgotFlow';
import {push} from 'connected-react-router';
import {purgeSessionTokens, saveSessionTokens} from '../modules/localStorage';
import Logger from '@/app/modules/logger';
import CONFIG from '@/app/config';
import {redirectTo} from '@/app/utils/redirect/redirect';
import {successMessages} from '@/app/constants/successMessages';
import {forgotSteps} from '@/app/constants/forgotSteps';
import {prepareError, prepareSuccess} from '@/app/utils/sagas';
import {
  autologin,
  deactivateAccount,
  DeactivationStatus,
  getUser,
  login,
  logout,
  sendResetPassword,
  updateCredentials,
  updatePassword,
  updatePasswordWithToken,
} from '@dev7/altar-product-sdk';
import {productSDK} from '../modules/productSDK';

const logger = Logger.getInstance('UserSaga');

export function* updateCredentialsSaga({payload}) {
  const {values} = payload;
  try {
    yield call(productSDK.call, updateCredentials, values);
    yield put(userActions.updateCredentialsSuccessAction());
    yield put(userActions.hideUpdateCredentialsAction());
    yield put(notificationActions.addNotificationAction(prepareSuccess(successMessages.PASSWORD_CHANGED)));
  } catch(error) {
    logger.error(error);
    yield put(userActions.updateCredentialsFailedAction());
    yield put(userActions.showUpdateCredentialsAction());
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export function* autoLoginSaga(autologinToken) {
  try {
    const sessionTokens = yield call(productSDK.call, autologin, autologinToken);
    yield fork(saveSessionTokens, sessionTokens);
    productSDK.setupAuthentication(sessionTokens, saveSessionTokens);
    const user = yield call(productSDK.call, getUser);
    yield put(userActions.loginSuccessAction(user));
    yield put(push(CONFIG.routes.signedIn));

    if(user.updateCredentials === true)
      yield put(userActions.showUpdateCredentialsAction());

    //TODO: What about `user.updateEmail`?

    return user;
  } catch(error) {
    logger.error(error);
    yield put(userActions.loginFailedAction(error));
    yield put(notificationActions.addNotificationAction(prepareError(error)));
    return null;
  }
}

export function* loginSaga({payload}) {
  const {values, prevRoute} = payload;
  try {
    const sessionTokens = yield call(productSDK.call, login, values);
    yield fork(saveSessionTokens, sessionTokens);
    productSDK.setupAuthentication(sessionTokens, saveSessionTokens);
    const user = yield call(productSDK.call, getUser);
    yield put(userActions.loginSuccessAction(user));
    yield put(push(prevRoute || CONFIG.routes.signedIn));

    //TODO: What about `user.updateCredentials` & `user.updateEmail`?
    return user;
  } catch(error) {
    logger.error(error);
    yield put(userActions.loginFailedAction(error));
    yield put(notificationActions.addNotificationAction(prepareError(error)));
    return null;
  }
}

export function* logoutSaga() {
  try {
    yield call(productSDK.call, logout);
    yield put(userActions.logoutSuccessAction());
    yield put(push('/'));
  } catch(error) {
    logger.error(error);
  } finally {
    purgeSessionTokens();
    productSDK.dropAuthentication();
  }
}

export function* updatePasswordSaga({payload}) {
  const {values} = payload;
  try {
    yield call(productSDK.call, updatePassword, values);
    yield put(notificationActions.addNotificationAction(prepareSuccess(successMessages.PASSWORD_CHANGED)));
  } catch(error) {
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export function* newPasswordSaga({payload}) {
  const {values, onSuccess} = payload;
  try {
    const resetPasswordDataset = {
      password: values.password,
      passwordConfirmation: values.password_confirmation,
      resetPasswordToken: values.reset_password_token,
    };
    yield call(productSDK.call, updatePasswordWithToken, resetPasswordDataset);
    yield put(userActions.newPasswordSuccessAction());
    yield put(notificationActions.addNotificationAction(prepareSuccess(successMessages.PASSWORD_CHANGED)));
    yield call(onSuccess);
  } catch(error) {
    logger.error(error);
    yield put(userActions.newPasswordFailedAction());
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export function* newPasswordEmailSaga({payload}) {
  const {values} = payload;
  try {
    yield call(productSDK.call, sendResetPassword, values.email);
    yield put(notificationActions.addNotificationAction(prepareSuccess(successMessages.EMAIL_WAS_SENT)));
    yield put(userActions.newPasswordEmailSuccessAction());
    yield put(forgotFlowActions.changeStepAction(forgotSteps.check_email));
  } catch(error) {
    logger.error(error);
    yield put(userActions.newPasswordEmailFailedAction());
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export const STATUS = {
  SUCCESS: 'success',
  REDIRECT: 'redirect',
  SMS: 'sms',
  PROCESSING: 'processing',
  FAILURE: 'failure',
};

export function* cancelAccountSaga({payload}) {
  const {onSuccess} = payload;
  try {
    const deactivationResult = yield call(productSDK.call, deactivateAccount, {
      success: '/',
      failure: '/error',
    });

    switch(deactivationResult.status) {
      case DeactivationStatus.SUCCESS: {
        yield put(userActions.cancelAccountSuccessAction());
        yield call(onSuccess);
        break;
      }
      case DeactivationStatus.REDIRECT: {
        yield put(userActions.cancelAccountSuccessAction());
        yield call(redirectTo, deactivationResult.redirectUrl);
        break;
      }
      default:
        throw new Error(`Unsupported deactivation status \`${deactivationResult.status}\`.`);
    }
  } catch(error) {
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
    yield put(userActions.cancelAccountFailedAction());
  }
}

export default function* watchForUser() {
  yield all([
    takeLatest(userActions.loginAction, loginSaga),
    takeLatest(userActions.logoutAction, logoutSaga),
    takeLatest(userActions.newPasswordAction, newPasswordSaga),
    takeLatest(userActions.newPasswordEmailAction, newPasswordEmailSaga),
    takeLatest(userActions.updatePasswordAction, updatePasswordSaga),
    takeLatest(userActions.cancelAccountAction, cancelAccountSaga),
    takeLatest(userActions.updateCredentialsAction, updateCredentialsSaga),
  ]);
}
