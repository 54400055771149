import styled, {css} from 'styled-components';
import {Container} from '../Rwd';
import IHeaderTheme from './IHeaderTheme';

const isSafari = () => !!(window as any).ApplePaySession;

interface IHeaderStyledProps {
  hasBackground: boolean;
  sticky: boolean;
  componentTheme: IHeaderTheme;
}

export const HeaderStyled = styled.header<IHeaderStyledProps>`
  ${(props) => css`
    position: fixed;
    width: 100%;
    height: 80px;
    z-index: 26;
    ${props.hasBackground &&
    !props.sticky &&
    css`
      background: ${props.componentTheme.stickyHeaderBackground};
    `}
    ${props.sticky || props.hasBackground
      ? css`
          background: ${props.componentTheme.stickyHeaderBackground};
          border-bottom: solid 1px ${props.componentTheme.stickyHeaderBorder};
          transition: background 0.3s linear, border-bottom 0.3s ease-out;
        `
      : css`
          position: ${isSafari() ? 'absolute' : 'fixed'};
          background: ${props.componentTheme.headerBackground
            ? `${props.componentTheme.headerBackground}`
            : `transparent`};
          border-bottom: 1px solid transparent;
          transition: background 0.3s linear, border-bottom 0.3s ease-out;
        `};
  `}
`;

export const Wrapper = styled(Container)`
  position: relative;
  height: 100%;
`;
