import {createSelector} from 'reselect';

const selectors = {
  selectState: (state) => state.get('user'),
  isLoading: () => createSelector(selectors.selectState, (user) => user.get('isLoading')),
  getUser: () => createSelector(selectors.selectState, (user) => user.get('data')),
  showUpdateCredentials: () => createSelector(selectors.selectState, (user) => user.get('showUpdateCredentials')),
};

export default selectors;
