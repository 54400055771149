import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {toJS} from '@/app/utils/immutable/toJS';
import {createStructuredSelector} from 'reselect';
import {actions as userActions, selectors as userSelectors} from '@/app/reducers/user';
import {withTranslators} from '@/app/lib/lioness';

import UserMenu from './UserMenu';

const mapStateToProps = createStructuredSelector({
  user: userSelectors.getUser(),
});

const mapDispatchToProps = (dispatch) => ({
  onLogin: bindActionCreators(userActions.loginAction, dispatch),
  onLogout: bindActionCreators(userActions.logoutAction, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(compose(withConnect, withTranslators)(toJS(UserMenu)));
