import {ApolloError, FetchResult} from '@apollo/client';
import type {ProductSDK} from '../../../ProductSDK';
import {requireDefined} from '../../../utils/nullability-utils';
import {requireSuccessfulOperation} from '../../../utils/operations-utils';
import {Operation} from '../../../models/Operation';
import {ConfirmedCredentials} from '../../../models/Credentials';
import {updateCredentialsMutation} from '../../../graphql/mutation/updateCredentials';
import {UserSetCredentialsInput} from './models/UserSetCredentialsInput';
import {UnauthorizedError} from '../../../exceptions/UnauthorizedError';
import {OperationFailError} from '../../../exceptions/OperationFailError';
import {CredentialsAlreadySetError} from './exceptions/CredentialsAlreadySetError';

type Results = FetchResult<{userSetCredentials: Operation<unknown>}>;

/**
 * Allowed to use only when `user.updateCredentials` is true
 * @throws UnauthorizedError
 * @throws OperationFailError
 * @throws CredentialsAlreadySetError - when `user.updateCredentials` is false
 */
export async function updateCredentials(this: ProductSDK, credentials: ConfirmedCredentials): Promise<void> {
  try {
    const variables: {input: UserSetCredentialsInput} = {
      input: {credentials},
    };

    const response: Results = await this.client.mutate({
      mutation: updateCredentialsMutation,
      variables: variables,
    });

    const data = requireDefined(response.data?.userSetCredentials);
    requireSuccessfulOperation(data);
  } catch (e: unknown) {
    if (e instanceof ApolloError) {
      if (e.graphQLErrors.length > 0) {
        switch (e.graphQLErrors[0].message) {
          case UnauthorizedError.ERR_MESSAGE:
            throw new UnauthorizedError(e.message);
        }
      }
    }
    if (e instanceof OperationFailError && e.getErrors().length > 0) {
      switch (e.getErrors()[0].code) {
        case CredentialsAlreadySetError.ERR_CODE:
          throw new CredentialsAlreadySetError();
      }
    }

    throw e;
  }
}
