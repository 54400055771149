import styled from 'styled-components';
import Checkmark from '../../Checkmark';
import Input from './../Input';
import Label from './../Label';
import ICheckboxFieldTheme from './ICheckboxFieldTheme';

export const CheckboxFieldStyled = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const LabelStyled = styled(Label)<{componentTheme: ICheckboxFieldTheme}>`
  display: block;
  position: relative;
  padding-left: 26px;
  font-size: ${({componentTheme}) => (componentTheme && componentTheme.fontSize) || '14px'};
  cursor: pointer;
  user-select: none;
  color: ${({componentTheme}) => componentTheme && componentTheme.color};
`;

export const CheckmarkSquare = styled(Checkmark)`
  // .checkmark
  position: absolute;
  top: calc(50% - 9px);
  left: 0;
  margin: 0;
`;

export const InputStyled = styled(Input)`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
`;
