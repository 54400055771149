import {FetchResult} from '@apollo/client';
import type {ProductSDK} from '../../../ProductSDK';
import {requireDefined} from '../../../utils/nullability-utils';
import {Operation} from '../../../models/Operation';
import {SendResetPasswordInput} from './models/SendResetPasswordInput';
import {requireSuccessfulOperation} from '../../../utils/operations-utils';
import {sendResetPasswordMutation} from '../../../graphql/mutation/sendResetPassword';
import {OperationFailError} from '../../../exceptions/OperationFailError';
import {UserNotFoundError} from './exceptions/UserNotFoundError';

type Results = FetchResult<{sendResetPassword: Operation<unknown>}>;

/**
 * Sends an email to the provided address with a link
 * to reset user password. First step of the password reset flow.
 * @throws OperationFailError
 */
export async function sendResetPassword(this: ProductSDK, email: string): Promise<void> {
  try {
    const variables: {input: SendResetPasswordInput} = {
      input: {email},
    };

    const response: Results = await this.client.mutate({
      mutation: sendResetPasswordMutation,
      variables: variables,
    });

    const data = requireDefined(response.data?.sendResetPassword);
    requireSuccessfulOperation(data);
  } catch (e: unknown) {
    if (e instanceof OperationFailError && e.getErrors().length > 0) {
      switch (e.getErrors()[0].code) {
        case UserNotFoundError.ERR_CODE:
          throw new UserNotFoundError();
      }
    }

    throw e;
  }
}
