import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {createStructuredSelector} from 'reselect';
import {selectors as userSelectors} from '@/app/reducers/user';
import {actions as notificationActions} from '@/app/reducers/notification';
import {toJS} from '@/app/utils/immutable/toJS';
import {withTranslators} from '@/app/lib/lioness';
import {withRouter} from 'react-router';
import UpdateCredentialsSection from './UpdateCredentialsSection';

const mapDispatchToProps = (dispatch) => ({
  addNotification: bindActionCreators(notificationActions.addNotificationAction, dispatch),
});

const mapStateToProps = createStructuredSelector({
  user: userSelectors.getUser(),
  showUpdateCredentials: userSelectors.showUpdateCredentials(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const ComposedUpdateCredentialsSection = compose(withConnect, withTranslators)(toJS(UpdateCredentialsSection));

export default withRouter(ComposedUpdateCredentialsSection);
