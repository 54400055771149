import {fromJS} from 'immutable';
import {prepareReducers} from '@/app/utils/redux';
import {forgotSteps} from '@/app/constants/forgotSteps';
import actions from './actions';

export const initialState = fromJS({
  stepName: forgotSteps.forgot,
});

const reducer = prepareReducers(
  {
    [actions.changeStepAction]: (state, {payload}) => state.set('stepName', payload),
  },
  initialState
);

export default reducer;
