import {ComponentType} from 'react';
import styled from 'styled-components';
import ILabelTheme from './ILabelTheme';

const LabelStyled = styled.label<{componentTheme: ILabelTheme}>`
  margin-bottom: 8px;
  ${({componentTheme}) =>
    componentTheme &&
    `
    font-size: ${componentTheme.fontSize ? componentTheme.fontSize : '14px'};
    line-height: ${componentTheme.lineHeight ? componentTheme.lineHeight : '20px'};
    color: ${componentTheme.color};
    font-weight: ${componentTheme.fontWeight || ''};
  `}
`;

interface LabelProps {
  children: ComponentType | HTMLElement;
  componentTheme: ILabelTheme;
}

function Label(props: LabelProps) {
  const {children, ...rest} = props;
  return <LabelStyled {...rest}>{children}</LabelStyled>

}

export default Label;
