import { createContext, PureComponent } from 'react';
import Logger from '@/app/modules/logger';
import {LionessProvider} from '@/app/lib/lioness';
import {checkLangParam, getLang, setLang} from '@/app/utils/i18n/langStore';
import {loadTranslations} from '@/app/utils/i18n';

export const I18nContext = createContext();

class I18nProvider extends PureComponent {
  logger = Logger.getInstance('I18nProvider');

  state = {
    messages: window.translations,
    language: getLang(),
  };

  componentDidMount() {
    const lang = checkLangParam();
    if (lang) {
      setLang(lang);
    }

    this.listenForTranslations();
  }

  listenForTranslations = () => {
    window.addEventListener('translations-loaded', ({detail}) => {
      this.setState(
        () => ({messages: detail.messages, language: detail.language}),
        () => {
          setLang(detail.language);
        }
      );
    });
  };

  changeLanguage = async (language) => {
    this.logger.debug('changeLanguage:', language);
    await loadTranslations(language, this.props.view);
  };

  render() {
    const {messages, language} = this.state;

    const value = {
      language: this.state.language,
      changeLanguage: this.changeLanguage,
    };

    return (
      <I18nContext.Provider value={value}>
        <LionessProvider messages={messages} locale={language}>
          {this.props.children}
        </LionessProvider>
      </I18nContext.Provider>
    );
  }
}

export default I18nProvider;
