import {gql} from '@apollo/client';

export const deactivateAccountMutation = gql`
  mutation deactivateAccount($input: DeactivateAccountInput!) {
    deactivateAccount(input: $input) {
      deactivationResult {
        status
        redirectUrl
      }
      success
      errors {
        code
        msg
      }
    }
  }
`;
