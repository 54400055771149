import styled, {css} from 'styled-components';
import IButtonTheme from './IButtonTheme';

const setPadding = (vertical: any, horizontal: any, border = 0) => `${vertical - border}px ${horizontal - border}px`;

const setSize = (size: any, padding?: any) => {
  switch (size) {
    case 'large':
      return setPadding(
        padding && padding.large ? padding.large.v : 16,
        padding && padding.large ? padding.large.h : 25
      );
    case 'medium':
      return setPadding(
        padding && padding.medium ? padding.medium.v : 14,
        padding && padding.medium ? padding.medium.h : 16
      );
    case 'small':
      return setPadding(
        padding && padding.small ? padding.small.v : 10,
        padding && padding.small ? padding.small.h : 14
      );
    default:
      return setPadding(
        padding && padding.large ? padding.large.v : 16,
        padding && padding.large ? padding.large.h : 25
      );
  }
};

const setColor = (componentTheme: any, color: any, hover = false) => {
  if (typeof hover === 'boolean') {
    return hover ? componentTheme && componentTheme[color + 'Hover'] : componentTheme && componentTheme[color];
  } else {
    return componentTheme && componentTheme[hover];
  }
};

interface ButtonStyledProps {
  size?: any;
  color?: any;
  colorHover?: any;
  hoverColor?: any;
  colorDisabled?: any;
  isLoading?: any;
  componentTheme: IButtonTheme;
}

export const ButtonStyled = styled.button<ButtonStyledProps>`
  display: inline-flex;
  position: relative;
  margin: 0;
  vertical-align: middle;
  line-height: 1em;
  ${({componentTheme}) =>
    componentTheme && componentTheme.borderRadius
      ? `border-radius: ${componentTheme.borderRadius};`
      : `border-radius: 4px;`}
  font-size: ${({componentTheme}) => (componentTheme && componentTheme.fontSize) || '16px'};
  ${({componentTheme}) =>
    componentTheme && componentTheme.border ? `border: ${componentTheme.border};` : `border: 0;`}
  font-weight: ${({componentTheme}) => (componentTheme && componentTheme.fontWeight) || '600'};
  cursor: pointer;
  background: ${({componentTheme, color}) => setColor(componentTheme, color)};
  color: ${({componentTheme}) => setColor(componentTheme, 'fontColor')};
  text-transform: ${({componentTheme}) => componentTheme.textTransform || 'none'};
  padding: ${({componentTheme, size}) =>
    componentTheme && componentTheme.padding ? setSize(size, componentTheme.padding) : setSize(size)};
  ${({componentTheme}) =>
    componentTheme && componentTheme.letterSpacing && `letter-spacing: ${componentTheme.letterSpacing};`}
  transition: all 0.3s ease-in-out;
  :focus {
    outline: none;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  :hover {
    ${({componentTheme}) => componentTheme.hoverColor && `color: ${componentTheme.hoverColor}`};
    background: ${({componentTheme, color, colorHover}) =>
      colorHover ? setColor(componentTheme, color, colorHover) : setColor(componentTheme, color, true)};
  }
  &:disabled {
    cursor: default;
    background: ${({componentTheme}) => componentTheme.disabled};
  }
  ${(props) =>
    props &&
    props.isLoading &&
    css<ButtonStyledProps>`
      color: transparent;
      background: ${({componentTheme, color, colorHover}) =>
        colorHover ? setColor(componentTheme, color, colorHover) : setColor(componentTheme, color, true)};
      span > svg {
        fill: transparent;
      }
  }
  `}
`;

export const LabelStyled = styled.div`
  margin-left: 0.8em;
`;

export const ButtonInnerStyled = styled.div`
  height: auto;
`;
