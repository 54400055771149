import styled from 'styled-components';
import NotificationsSource from '@dev7/altar-alt-ui/Notifications';

const NotificationsStyled = styled(NotificationsSource)`
  z-index: 999999;
`;

const Notifications = ({notifications, removeNotification, t}) => {
  const tNotifications = notifications.map((notification) => ({...notification, message: t(notification.message)}));

  return <NotificationsStyled notifications={tNotifications} removeNotification={removeNotification} />;
};

export default Notifications;
