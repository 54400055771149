import type {ProductSDK} from '../../../ProductSDK';
import {loginMutation} from '../../../graphql/mutation/login';
import {SessionTokens} from '../../../models/SessionTokens';
import {ApolloError, FetchResult} from '@apollo/client';
import {requireDefined} from '../../../utils/nullability-utils';
import {InvalidCredentialsError} from './exceptions/InvalidCredentialsError';
import {requireSuccessfulOperation} from '../../../utils/operations-utils';
import {Operation} from '../../../models/Operation';
import {Credentials} from '../../../models/Credentials';
import {LoginInput} from './models/LoginInput';

type Results = FetchResult<{login: Operation<{tokens: SessionTokens}>}>;

/**
 * @throws InvalidCredentialsError
 * @throws OperationFailError
 */
export async function login(this: ProductSDK, credentials: Credentials): Promise<SessionTokens> {
  const variables: {input: LoginInput} = {
    input: {
      session: credentials,
    },
  };

  try {
    const response: Results = await this.client.mutate({
      mutation: loginMutation,
      variables: variables,
    });

    const data = requireDefined(response.data?.login);
    requireSuccessfulOperation(data);

    return data.tokens;
  } catch (e: unknown) {
    if (e instanceof ApolloError) {
      if (e.graphQLErrors.length > 0) {
        switch (e.graphQLErrors[0].message) {
          case InvalidCredentialsError.ERR_MESSAGE:
            throw new InvalidCredentialsError(e.message);
        }
      }
    }

    throw e;
  }
}
