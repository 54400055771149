import {prepareActions} from '@/app/utils/redux';

export const actions = prepareActions(
  {},
  ['changeRegisterStep'],
  ['getSession', true, true],
  ['getPromoSession', true, true],
  ['sendRegisterPayment', true, true],
  ['sendRegisterEmail', true, true],
  ['sendRegisterPaymentSepa', true, true],
  ['sendRegisterPaymentSepaPin', true, true],
  ['setWaitSepa', true, true],
  ['sendChangePaymentMethod', true, true],
  ['fetchDsecure'],
  ['stopDsecurePooling'],
  ['dsecurePooling', true, true]
);

export default actions;
