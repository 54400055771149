import styled from 'styled-components';
import {media} from '../../../utils';
import {RowProps} from './Row';

export const RowWrapper = styled.div<RowProps>`
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px;
  ${(props) => props['justify-content'] && `justify-content: ${props['justify-content']}!important;`}

  /* change styles */
  ${(props) =>
    props &&
    props.theme &&
    media(props.theme.GRID_BREAKPOINTS).md(`
      margin-right: -15px;
      margin-left: -15px;
  `)}
`;
