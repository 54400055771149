/**
 * @throws Error
 */
export function assertDefined<T>(pointer: T): asserts pointer is Exclude<T, null | undefined> {
  if (pointer == null) throw new Error('Specified pointer was null or undefined.');
}

/**
 * @throws Error
 */
export function requireDefined<T>(pointer: T): Exclude<T, null | undefined> {
  if (pointer == null) throw new Error('Specified pointer was null or undefined.');

  return pointer!;
}
