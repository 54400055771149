import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter as Router } from 'connected-react-router/immutable';
import createStore from '@/app/store/createStore';
import ScrollToTop from '@/app/components/ScrollToTop';
import Sentry from '@/app/modules/sentry';
import config from './config.json';
import './styles/reset.scss';
import packageJson from './../package.json';
import App from './App';
import { loadTranslations } from '@/app/utils/i18n';
import { getLang } from '@/app/utils/i18n/langStore';

console.info('v:', packageJson.version);
Sentry.init(config.config.SENTRY.dsn);

export async function init() {
  await loadTranslations(getLang(), null);

  const store = createStore();
  const MOUNT_NODE = document.getElementById('root');

  function renderApp() {
    render(
      <Provider store={store}>
        <Router history={store.history}>
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </Router>
      </Provider>,
      MOUNT_NODE
    );
  }

  if (process.env.NODE_ENV === 'development') {
    const RedBox = require('redbox-react').default;
    try {
      renderApp();
    } catch (e) {
      render(<RedBox error={e} />, MOUNT_NODE);
    }
  } else {
    renderApp();
  }
}

init();
