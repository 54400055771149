import {ReactNode} from 'react';
import {ColWrapper} from './Col.styled';

export type cols = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';

interface ColProps {
  col?: cols;
  xs?: cols;
  sm?: cols;
  md?: cols;
  lg?: cols;
  xl?: cols;
  order?: string;
  offset?: cols;
  children?: ReactNode | string;
}

function Col(props: ColProps) {
  const {children, ...rest} = props;
  return <ColWrapper {...rest}>{children}</ColWrapper>

}

export default Col;
