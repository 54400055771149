import styled from 'styled-components';
import {media} from '../../utils';
import ITitleTheme from './ITitleTheme';

const headerRwdStyle = (prop: string, property: string, props: any) =>
  props[property] ? `${prop}: ${props[property]};` : '';

const headerRwd = (props: TitleProps) => {
  const {componentTheme, as: displayAs} = props;
  const size = displayAs || 'h1';

  return Object.keys(componentTheme.GRID_BREAKPOINTS)
    .filter((breakpoint) => (componentTheme as any)[size][breakpoint])
    .map((breakpoint) => {
      const properties = (componentTheme as any)[size][breakpoint];
      return media(componentTheme.GRID_BREAKPOINTS)[breakpoint](`
          ${headerRwdStyle('color', 'color', properties)}
          ${headerRwdStyle('font-family', 'fontFamily', properties)}
          ${headerRwdStyle('font-weight', 'fontWeight', properties)}
          ${headerRwdStyle('font-size', 'fontSize', properties)}
          ${headerRwdStyle('line-height', 'lineHeight', properties)}
          ${headerRwdStyle('letter-spacing', 'letterSpacing', properties)}
      `);
    });
};

const headerStyle = (props: ITitleStyled, property: any, prop: any) => {
  const {componentTheme, children, displayAs: component} = props;
  const size = component || children[0].trim();

  return componentTheme[size] && componentTheme[size][prop] && `${property}: ${componentTheme[size][prop]};`;
};

interface ITitleStyled {
  displayAs?: string;
  componentTheme: ITitleTheme;
  children?: any;
}

const TitleStyled = styled.h1<ITitleStyled>`
  ${(props) => headerStyle(props, 'color', 'color')}
  ${(props) => headerStyle(props, 'font-family', 'fontFamily')}
  ${(props) => headerStyle(props, 'font-size', 'fontSize')}
  ${(props) => headerStyle(props, 'font-weight', 'fontWeight')}
  ${(props) => headerStyle(props, 'line-height', 'lineHeight')}
  ${(props) => headerStyle(props, 'text-transform', 'textTransform')}
  ${(props) => headerStyle(props, 'letter-spacing', 'letterSpacing')}
  ${(props) => headerRwd(props)}
  > span {
    ${(props) => headerStyle(props, 'color', 'color')}
    ${(props) => headerStyle(props, 'font-family', 'fontFamily')}
    ${(props) => headerStyle(props, 'font-size', 'fontSize')}
    ${(props) => headerStyle(props, 'font-weight', 'fontWeight')}
    ${(props) => headerStyle(props, 'line-height', 'lineHeight')}
    ${(props) => headerStyle(props, 'text-transform', 'textTransform')}
    ${(props) => headerStyle(props, 'letter-spacing', 'letterSpacing')}
    ${(props) => headerRwd(props)}
  }
`;

export type headersType = 'h1' | 'h2' | 'h3' | 'h4';

interface TitleProps {
  as?: headersType;
  forwardedAs?: headersType;
  componentTheme: ITitleTheme;
}

function Title(props: TitleProps) {
  const {as, forwardedAs, componentTheme, ...rest} = props;
  return <TitleStyled as={forwardedAs || as} displayAs={forwardedAs || as} componentTheme={componentTheme} {...rest} />;

}

export default Title;
