import {gql} from '@apollo/client';

export const getContentQuery = gql`
  query getContent($id: ID!) {
    content(id: $id) {
      id
      contentType {
        id
        name
      }
      categories {
        id
        name
        categoryType
      }
      title
      description
      images {
        url
        imageType
        format
      }
      files {
        id
        url
        name
        format
        description
        durationMinutes
      }
      isUserContent
      language {
        isoCode
        name
      }
      properties {
        name
        value
      }
      tags {
        name
      }
    }
  }
`;
