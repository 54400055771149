import {gql} from '@apollo/client';

export const getUserQuery = gql`
  query me {
    me {
      country {
        continent {
          isoCode
          name
        }
        isoCode
        name
      }
      email
      language {
        isoCode
        name
      }
      signInCount
      subscription {
        deactivating
        deactivationViaSms
        expireDate
        nextBillingDate
        operatorName
        price {
          amount
          currency {
            isoCode
            smallestDenomination
            subunitToUnit
            symbol
          }
          displayString
          fractional
        }
        startDate
        status
        trial
        trialPeriod
      }
      updateCredentials
    }
  }
`;
