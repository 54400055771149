import styled, {keyframes} from 'styled-components';

export const StyledThrobber = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

export const throbber = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledThrobberContainer = styled.span`
  box-sizing: border-box;
  position: absolute;
  left: 0;
  margin-left: calc(50% - 10px);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  animation: ${throbber} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-style: solid;
  border-width: 3px;
  border-color: #cbcbcb;
  border-top-color: #807e7e;
  animation-delay: -0.45s;
`;
