import {StyledThrobber, StyledThrobberContainer} from './Throbber.styled';

/**
 * Form column.
 */
const Throbber = (props: any) => (
  <StyledThrobber {...props}>
    <StyledThrobberContainer />
  </StyledThrobber>
);

export default Throbber;
