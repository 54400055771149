import * as Sentry from '@sentry/browser';

export const addSentryScript = () => {
  window.setTimeout(() => {
    const lc = document.createElement('script');
    lc.src = 'https://browser.sentry-cdn.com/5.4.3/bundle.min.js';
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(lc, s);
  }, 500);
};

const ignoreErrors = [
  'ChunkLoadError',
  '/ChunkLoadError/gm',
  'https://customerheroes.ladesk.com' //liveagent
];

const blacklistUrls = [
  /127\.0\.0\.1:3000/i, // localhost
  /localhost:3000/i, // localhost
];

export default class SentryModule {
  static init = (dsn) => {
    addSentryScript();
    Sentry.init({
      dsn,
      ignoreErrors,
      blacklistUrls,
      beforeSend(event, hint) {
        const error = hint.originalException;
        if (error && error.message && ignoreErrors.includes(event.message)) {
          return null;
        }
        return event;
      },
    });
    SentryModule.addListeners();
  };

  static addListeners = () => {
    window.addEventListener('unhandledrejection', (err) => {
      Sentry.captureException(err['reason'], {
        extra: {unhandledPromise: true},
      });
    });
  };

  static captureException = (err, params = {}) => {
    Sentry.captureException(err, params);
  };
}
