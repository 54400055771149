import styled, {css} from 'styled-components';
import IDropDownTheme from './IDropDownTheme';

interface IDropDownListStyled {
  visible: boolean;
  componentTheme: IDropDownTheme;
}

export const DropDownListStyled = styled.ul<IDropDownListStyled>`
  ${({visible, componentTheme: {listBackground, listBorder, listElementText, listBorderRadius}}) => css`
    display: ${visible ? 'block' : 'none'};
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    list-style: none;
    background: ${listBackground};
    border: solid 1px ${listBorder};
    border-top: none;
    color: ${listElementText};
    border-radius: ${listBorderRadius || `0 0 4px 4px`};
    z-index: 6;
  `}
`;

interface IDropDownListLiStyled {
  componentTheme: IDropDownTheme;
}

export const DropDownListLiStyled = styled.li<IDropDownListLiStyled>`
  ${({
    componentTheme: {
      listBorder,
      carretColor,
      listElementBackgroundHover,
      listElementTextHover,
      listBorderHover,
      listBorderRadius,
    },
  }) => css`
    position: relative;
    transition: background 0.3s ease-in-out;
    &:not(:first-of-type) {
      border-top: solid 1px ${listBorder};
    }
    svg {
      fill: ${carretColor};
      transition: fill 0.3s ease-in-out;
    }
    &:hover {
      background: ${listElementBackgroundHover};
      color: ${listElementTextHover};
      ${listBorderHover
        ? `&::before {
        content: "";
        position: absolute;
        width: calc(100% + 2px);
        height: calc(100% + 4px);
        margin: -2px -1px;
        border: 1px solid ${listBorderHover};
        z-index: -1;
        }`
        : ''}
      span,
      div,
      a {
        color: ${listElementTextHover};
      }
      svg {
        fill: ${listElementTextHover};
      }
    }
    &:last-of-type {
      border-radius: ${listBorderRadius || `0 0 4px 4px`};
      ${listBorderHover
        ? `
          &:hover::before {
            border-radius: ${listBorderRadius || `0 0 4px 4px`}
          }
        `
        : ''}
    }
  `}
`;
