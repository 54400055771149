import {compose} from 'redux';
import {withTranslators} from '@/app/lib/lioness';
import ErrorBoundary from './ErrorBoundary';
import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {selectors as userSelectors} from '@/app/reducers/user';

const mapStateToProps = createStructuredSelector({
  user: userSelectors.getUser(),
});

const withConnect = connect(mapStateToProps);

export default compose(withRouter, withConnect, withTranslators)(ErrorBoundary);
