import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.scrollTop();
    }
  }

  scrollTop = () => {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

    if (top > 0) {
      window.scroll({top: 0, behavior: 'smooth'});
    }
  };

  render() {
    return <Fragment>{this.props.children}</Fragment>;
  }
}

ScrollToTop.propTypes = {
  icon: PropTypes.func,
  description: PropTypes.string,
};

export default ScrollToTop;
