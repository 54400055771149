import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {actions} from '@/app/reducers/support';
import {actions as notificationActions} from '@/app/reducers/notification';
import Logger from '@/app/modules/logger';
import {prepareError} from '@/app/utils/sagas';
import {sendEmailToSupport} from '@dev7/altar-product-sdk';
import {selectors as domainInfoSelectors} from '../reducers/info';
import {productSDK} from '../modules/productSDK';

const logger = Logger.getInstance('SupportSaga');

export function* supportSaga({payload}) {
  const {values, onSuccess} = payload;
  try {
    const domainInfo = yield select(domainInfoSelectors.getInfo());
    yield call(productSDK.call, sendEmailToSupport, {
      ...values,
      country: domainInfo.country,
    });
    yield call(onSuccess);
    yield put(actions.sendSupportSuccessAction());
  } catch (error) {
    logger.error(error);
    yield put(actions.sendSupportFailedAction());
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export function* cancelAccountSaga({payload}) {
  const {values, onSuccess} = payload;
  try {
    const domainInfo = yield select(domainInfoSelectors.getInfo());
    yield call(productSDK.call, sendEmailToSupport, {
      ...values,
      country: domainInfo.country,
    });
    yield call(onSuccess);
    yield put(actions.sendCancelAccountSuccessAction());
  } catch (error) {
    logger.error(error);
    yield put(actions.sendCancelAccountFailedAction());
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export default function* watchForSupport() {
  yield all([
    yield takeLatest(actions.sendSupportAction, supportSaga),
    yield takeLatest(actions.sendCancelAccountAction, cancelAccountSaga),
  ]);
}
