import type {ProductSDK} from '../../../ProductSDK';
import {ApolloError, FetchResult} from '@apollo/client';
import {Operation} from '../../../models/Operation';
import {DeactivateAccountInput} from './models/DeactivateAccountInput';
import {requireDefined} from '../../../utils/nullability-utils';
import {deactivateAccountMutation} from '../../../graphql/mutation/deactivateAccount';
import {DeactivationResult} from './models/DeactivationResult';
import {RedirectEndpoints} from './models/RedirectEndpoints';
import {requireSuccessfulOperation} from '../../../utils/operations-utils';
import {DeactivateAccountPayload} from './models/DeactivateAccountPayload';
import {UnauthorizedError} from '../../../exceptions/UnauthorizedError';
import { OperationFailError } from '../../../exceptions/OperationFailError';
import { CredentialsAlreadySetError } from '../updateCredentials';
import { SubscriptionAlreadyDeactivatedError } from './exceptions/SubscriptionAlreadyDeactivatedError';

type Results = FetchResult<{deactivateAccount: Operation<DeactivateAccountPayload>}>;

/**
 * @throws UnauthorizedError
 * @throws OperationFailError
 */
export async function deactivateAccount(
  this: ProductSDK,
  redirectEndpoints: RedirectEndpoints
): Promise<DeactivationResult> {
  try {
    const variables: {input: DeactivateAccountInput} = {
      input: {redirectEndpoints},
    };

    const response: Results = await this.client.mutate({
      mutation: deactivateAccountMutation,
      variables: variables,
    });

    const data = requireDefined(response.data?.deactivateAccount);
    requireSuccessfulOperation(data);

    return data.deactivationResult;
  } catch (e: unknown) {
    if (e instanceof ApolloError) {
      if (e.graphQLErrors.length > 0) {
        switch (e.graphQLErrors[0].message) {
          case UnauthorizedError.ERR_MESSAGE:
            throw new UnauthorizedError(e.message);
        }
      }
    }
    if (e instanceof OperationFailError && e.getErrors().length > 0) {
      switch (e.getErrors()[0].code) {
        case SubscriptionAlreadyDeactivatedError.ERR_CODE:
          throw new SubscriptionAlreadyDeactivatedError();
      }
    }

    throw e;
  }
}
