import {Component, createRef, FunctionComponent} from 'react';
import {ButtonStyled, ButtonText, CarretDownStyled, CarretUpStyled, DropDownStyled} from './DropDown.styled';
import {DropDownList} from './DropDownList';
import IDropDownTheme from './IDropDownTheme';
import {IListItem} from './DropDownListItem';

interface IDropDownProps {
  itemsList: IListItem[];
  buttonText: string;
  icon?: FunctionComponent;
  mainIconOptions?: any;
  mainIconListOptions?: any;
  closeAfterClick: boolean;
  buttonColor: string;
  buttonColorHover: string;
  componentTheme: IDropDownTheme;
}

interface IDropDownState {
  visible: boolean;
}

class DropDown extends Component<IDropDownProps, IDropDownState> {
  static defaultProps = {
    buttonColor: 'light',
    buttonColorHover: 'background',
  };

  wrapperRef = createRef<HTMLDivElement>();

  state = {
    visible: false,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handlClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handlClickOutside);
  }
  handlClickOutside = (event: any) => {
    if (this.wrapperRef && !this.wrapperRef.current!.contains(event.target) && this.state.visible) {
      this.setState(() => ({
        visible: false,
      }));
    }
  };

  handleClick = () => {
    this.setState((prevState) => ({
      visible: !prevState.visible,
    }));
  };

  handleClickItem = () => this.props.closeAfterClick && this.handleClick();

  render() {
    const {
      itemsList,
      buttonText,
      icon: Icon,
      mainIconOptions,
      mainIconListOptions,
      componentTheme,
      buttonColor,
      buttonColorHover,
      ...rest
    } = this.props;
    const {visible} = this.state;

    return (
      <DropDownStyled ref={this.wrapperRef} {...rest}>
        <ButtonStyled
          componentTheme={componentTheme}
          size='small'
          onClick={this.handleClick}
          isOpen={visible}
          color={buttonColor}
          colorHover={buttonColorHover}
        >
          {Icon && <Icon {...mainIconOptions} style={{fill: componentTheme.iconColor, marginRight: '8px'}} />}
          <ButtonText isOpen={visible} componentTheme={componentTheme}>
            {buttonText}
          </ButtonText>
          {!visible ? (
            <CarretDownStyled componenttheme={componentTheme} />
          ) : (
            <CarretUpStyled componenttheme={componentTheme} />
          )}
        </ButtonStyled>
        <DropDownList
          itemsList={itemsList}
          visible={visible}
          handleClose={this.handleClickItem}
          mainIconListOptions={mainIconListOptions}
          componentTheme={componentTheme}
        />
      </DropDownStyled>
    );
  }
}


export default DropDown;
