import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {withTranslators} from '@/app/lib/lioness';
import {createStructuredSelector} from 'reselect';
import {selectors} from '@/app/reducers/info';
import {actions as cookiesActions, selectors as cookiesSelectors} from '@/app/reducers/cookies';
import {withInfo} from '@/app/ui/HOC/withInfo';
import {toJS} from '@/app/utils/immutable/toJS';
import AdvanceCookies from './AdvanceCookies';

const mapStateToProps = createStructuredSelector({
  info: selectors.getInfo(),
  isCookiesVisible: cookiesSelectors.getCookiesState(),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeCookie: cookiesActions.closeCookiesAction,
      openCookie: cookiesActions.openCookiesAction,
    },
    dispatch
  );

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withTranslators, withInfo)(toJS(AdvanceCookies));
