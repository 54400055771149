export class SubscriptionAlreadyDeactivatedError extends Error {
  public static ERR_MESSAGE = 'Subscription already deactivated';
  public static ERR_CODE = 702;

  constructor() {
    super(SubscriptionAlreadyDeactivatedError.ERR_MESSAGE);
    this.name = SubscriptionAlreadyDeactivatedError.name;
    if (Object.hasOwnProperty.call(Error, 'captureStackTrace')) Error.captureStackTrace(this);
  }
}
