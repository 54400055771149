import {FetchResult} from '@apollo/client';
import type {ProductSDK} from '../../../ProductSDK';
import {requireDefined} from '../../../utils/nullability-utils';
import {Operation} from '../../../models/Operation';
import {SendMessageInput} from './models/SendMessageInput';
import {sendEmailToSupportMutation} from '../../../graphql/mutation/sendEmailToSupport';
import {EmailMessage} from './models/EmailMessage';
import {requireSuccessfulOperation} from '../../../utils/operations-utils';

type Results = FetchResult<{sendMessage: Operation<unknown>}>;

/**
 * @throws OperationFailError
 */
export async function sendEmailToSupport(this: ProductSDK, emailMessage: EmailMessage): Promise<void> {
  const variables: {input: SendMessageInput} = {
    input: {message: emailMessage},
  };

  const response: Results = await this.client.mutate({
    mutation: sendEmailToSupportMutation,
    variables: variables,
  });

  const data = requireDefined(response.data?.sendMessage);
  requireSuccessfulOperation(data);
}
