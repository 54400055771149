import {ComponentType} from 'react';
import {FooterWrapper} from './Footer.styled';
import IFooterTheme from './IFooterTheme';

interface FooterProps {
  children: ComponentType | HTMLElement;
  componentTheme: IFooterTheme;
}

function Footer(props: FooterProps) {
  const {children, componentTheme, ...rest} = props;
  return (
    <FooterWrapper componentTheme={componentTheme} {...rest}>
      {children}
    </FooterWrapper>
  );
}

export default Footer;
