import styled from 'styled-components';
import Image from '@dev7/altar-alt-ui/Image';
import Title from '@dev7/altar-alt-ui/Title';
import Paragraph from '@dev7/altar-alt-ui/Paragraph';
import Button from '@dev7/altar-alt-ui/Button';

export const ErrorWrapper = styled.div``;

export const ImageErrorStyled = styled(Image)`
  width: 65px;
  height: 65px;
`;

export const TitleStyled = styled(Title)`
  margin: 24px 0 0;
`;

export const ParagraphStyled = styled(Paragraph)`
  margin: 8px 0 0;
  line-height: 24px;
`;

export const ButtonStyled = styled(Button)`
  margin-top: 24px;
`;
