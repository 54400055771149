import {combineReducers} from 'redux-immutable';
import {reducer as startupReducer} from '@/app/reducers/startup';
import {reducer as infoReducer} from '@/app/reducers/info';
import {reducer as userReducer} from '@/app/reducers/user';
import {reducer as notificationReducer} from '@/app/reducers/notification';
import {reducer as cookiesReducer} from '@/app/reducers/cookies';
import {reducer as supportReducer} from '@/app/reducers/support';
import {reducer as genresReducer} from '@/app/reducers/genres';
import {reducer as movieReducer} from '@/app/reducers/movie';
import {reducer as moviesReducer} from '@/app/reducers/movies';
import {reducer as moviesToWatchReducer} from '@/app/reducers/moviesToWatch';
import {reducer as moviesStatisticReducer} from '@/app/reducers/moviesStatistics';
import {reducer as premiumMoviesInfoReducer} from '@/app/reducers/premiumMoviesInfo';
import {reducer as promoMoviesReducer} from '@/app/reducers/promoMovies';
import {reducer as recommendedMoviesReducer} from '@/app/reducers/recommendedMovies';
import {reducer as forgotFlowReducer} from '@/app/reducers/forgotFlow';
import {reducer as registerReducer} from '@/app/reducers/register';
import {reducer as sessionInfoReducer} from '@/app/reducers/sessionInfo';

import {connectRouter} from 'connected-react-router';

export default function createRootReducer(history) {
  return (injectedReducers = {}) => {
    return combineReducers({
      startup: startupReducer,
      info: infoReducer,
      user: userReducer,
      notification: notificationReducer,
      cookies: cookiesReducer,
      support: supportReducer,
      genres: genresReducer,
      movie: movieReducer,
      movies: moviesReducer,
      moviesToWatch: moviesToWatchReducer,
      moviesStatistic: moviesStatisticReducer,
      premiumMoviesInfo: premiumMoviesInfoReducer,
      promoMovies: promoMoviesReducer,
      recommendedMovies: recommendedMoviesReducer,
      forgotFlow: forgotFlowReducer,
      register: registerReducer,
      sessionInfo: sessionInfoReducer,
      router: connectRouter(history),
      ...injectedReducers,
    });
  };
}
