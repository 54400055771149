import {gql} from '@apollo/client';

export const getDomainInfoQuery = gql`
  query getDomainInfo {
    domainInfo {
      domain
      additionalData {
        key
        value
      }
      country {
        continent {
          name
          isoCode
        }
        isoCode
        name
      }
      entities {
        additionalData {
          key
          value
        }
        address
        city
        country
        name
      }
      impressum {
        additionalData {
          key
          value
        }
        address
        city
        country
        name
      }
      serviceName
      subscriptionPlans {
        active
        duration
        durationUnit
        name
        price {
          amount
          currency {
            isoCode
            smallestDenomination
            subunitToUnit
            symbol
          }
          displayString
          fractional
        }
        trialDays
      }
      supportEmail
      supportPhones {
        country {
          continent {
            isoCode
            name
          }
          isoCode
          name
        }
        number
        workingHours
      }
    }
  }
`;
