import styled from 'styled-components';
import LabelSource from './../Label';

export const InputFieldStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const Label = styled(LabelSource)`
  order: -1;
`;
