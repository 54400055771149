import WebFont from 'webfontloader';

const loadFonts = (fonts) => {
  WebFont.load({
    google: {
      families: fonts,
    },
  });
};

export default loadFonts;
