export const checkIfUSCountry = (country) => {
  const usShortcuts = ['United States', 'US', 'us', 'US-en', 'us-en'];
  var re = new RegExp(usShortcuts.join('|'), 'i');
  return re.test(country);
};

export const checkIfBrasil = (country) => {
  const brShortcuts = ['Brasil', 'br', 'BR'];
  var re = new RegExp(brShortcuts.join('|'), 'i');
  return re.test(country);
};
