import {Link, NavLink} from 'react-router-dom';
import styled, {css} from 'styled-components';

const LinkMix = () => css<{componenttheme: any; isButton: boolean; isLinkActive: boolean}>`
  display: inline-block;
  text-decoration: none;
  ${({componenttheme}) =>
    componenttheme && componenttheme.fontWeight ? `font-weight: ${componenttheme.fontWeight};` : ``}
  ${({isButton}) =>
    isButton
      ? css<{componenttheme: any}>`
          display: inline-flex;
          position: relative;
          vertical-align: middle;
          line-height: ${({componenttheme}) =>
            componenttheme && componenttheme.buttonLineHeight ? componenttheme.buttonLineHeight : '1em'};
          border-radius: ${({componenttheme}) => componenttheme && (componenttheme.borderRadius || `3px`)};
          ${({componenttheme}) => (componenttheme && componenttheme.border ? `border: ${componenttheme.border};` : ``)}
          font-size: ${({componenttheme}) => (componenttheme.fontSizeBtn ? componenttheme.fontSizeBtn : '16px')};
          font-weight: ${({componenttheme}) =>
            (componenttheme && componenttheme.buttonFontWeight) || componenttheme.fontWeight || '600'};
          padding: 16px 24px;
          background: ${({componenttheme}) => componenttheme && componenttheme.background};
          color: ${({componenttheme}) => componenttheme && componenttheme.buttonColor};
          ${({componenttheme}) =>
            componenttheme && componenttheme.border ? `border: ${componenttheme.border};` : `border: 0;`}
          text-transform: ${({componenttheme}) => componenttheme.textTransform || 'none'};
          ${({componenttheme}) => componenttheme.letterSpacing && `letter-spacing: ${componenttheme.letterSpacing};`}
          transition: all 0.3s ease-in-out;
          &:hover {
            background: ${({componenttheme}) => componenttheme && componenttheme.hoverBackground};
            ${({componenttheme}) =>
              componenttheme && componenttheme.hoverBorder
                ? `border: ${componenttheme.hoverBorder};`
                : componenttheme && componenttheme.border
                ? `border: ${componenttheme.border};`
                : ``}
            ${({componenttheme}) =>
              componenttheme && componenttheme.hoverBtnColor ? `color: ${componenttheme.hoverBtnColor};` : ``}
          }
        `
      : css<{componenttheme: any; isLinkActive: boolean}>`
          display: inline-block;
          color: ${({componenttheme, isLinkActive}) =>
            componenttheme && isLinkActive ? componenttheme.activeColor : componenttheme.color};
          font-size: ${({componenttheme}) => componenttheme && componenttheme.fontSize};
          line-height: ${({componenttheme}) => componenttheme && componenttheme.lineHeight};
          ${({componenttheme}) =>
            componenttheme && componenttheme.fontFamily && `font-family: ${componenttheme.fontFamily};`}
          &:hover {
            color: ${({componenttheme}) => componenttheme && (componenttheme.hoverColor || componenttheme.activeColor)};
            ${({componenttheme}) =>
              componenttheme &&
              componenttheme.hoverTextShadow &&
              `text-shadow: 0 0 .01px ${componenttheme.hoverTextShadow};`}
          }
        `};
`;

export const LinkStyled = styled.a`
  ${LinkMix};
`;

export const LinkStyledRedirect = styled(({isButton, isLinkActive, ...rest}) => <Link {...rest} />)<{
  componenttheme: any;
  isButton: boolean;
}>`
  ${LinkMix};
`;

export const NavLinkStyled = styled(({isButton, isLinkActive, ...rest}) => <NavLink {...rest} />)<{
  componenttheme: any;
  isButton: boolean;
}>`
  ${LinkMix};
  &.active {
    color: ${({componenttheme}) => componenttheme && componenttheme.activeColor};
  }
`;

export const FakeLinkStyled = styled.span`
  ${LinkMix};
`;
