import {Component} from 'react';
import {ImageStyled, ImageWrapper} from './Image.styled';

interface IImageProps {
  timeout?: number;
  src: string;
  webp?: string;
  alt?: string;
  height?: string;
}

interface IImageState {
  loading: boolean;
}

class Image extends Component<IImageProps, IImageState> {
  state = {
    loading: true,
  };

  static defaultProps = {
    timeout: 0,
  };

  handleLoad = () => {
    const {timeout} = this.props;
    const delay = timeout! > 0 ? timeout : 0;
    setTimeout(() => this.setState({loading: false}), delay);
  };

  render() {
    const {src, webp, alt, ...rest} = this.props;
    const {loading} = this.state;
    return (
      <ImageWrapper isLoading={loading} {...rest}>
        <ImageStyled src={src} webp={webp} alt={alt || 'img-component'} onLoad={this.handleLoad} />
      </ImageWrapper>
    );
  }
}

export default Image;
