import {gql} from '@apollo/client';

export const logoutMutation = gql`
  mutation logout($input: LogoutInput!) {
    logout(input: $input) {
      success
      errors {
        code
        msg
      }
    }
  }
`;
