import styled, {css, keyframes} from 'styled-components';

const LdsRingKeyfrems = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

interface IStyleProps {
  color: string;
}

const style = (props: IStyleProps) => css`
  animation: ${LdsRingKeyfrems} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border: 6px solid ${props.color};
  border-color: ${props.color} transparent transparent transparent;
`;

interface ILdsRingProps {
  centered?: boolean;
}

export const LdsRing = styled.span<ILdsRingProps>`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  ${(props) =>
    props.centered
      ? `
    display: block;
    margin: 0 auto;
  `
      : ``}
`;

export const LdsRingInner = styled.span`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border-radius: 50%;
  ${style};
`;
