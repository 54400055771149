import {prepareActions} from '@/app/utils/redux';

export const actions = prepareActions(
  {},
  ['getRecentMovies', true, true],
  ['getStaffPickMovies', true, true],
  ['fetchBrowseMovies', true, true],
  ['fetchMoreBrowseMovies', true, true],
  ['getSearchMovies', true, true],
);

export default actions;
