import {FetchResult} from '@apollo/client';
import type {ProductSDK} from '../../../ProductSDK';
import {requireDefined} from '../../../utils/nullability-utils';
import {Operation} from '../../../models/Operation';
import {AddUserContentInput} from './models/AddUserContentInput';
import {requireSuccessfulOperation} from '../../../utils/operations-utils';
import {addUserContentMutation} from '../../../graphql/mutation/addUserContent';
import {updateCache} from './misc/updateCache';

type AddUserContentResults = FetchResult<{addUserContent: Operation<unknown>}>;

/**
 * @throws OperationFailError
 */
export async function addUserContent(this: ProductSDK, contentId: string): Promise<void> {
  const variables: {input: AddUserContentInput} = {
    input: {contentId},
  };

  const response: AddUserContentResults = await this.client.mutate({
    mutation: addUserContentMutation,
    variables: variables,
  });

  const data = requireDefined(response.data?.addUserContent);
  requireSuccessfulOperation(data);

  updateCache.call(this, contentId);
}
