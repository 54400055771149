import { Fragment } from 'react';
import {Container} from '@dev7/altar-alt-ui/Rwd';
import Paragraph from '@dev7/altar-alt-ui/Paragraph';
import Image from '@dev7/altar-alt-ui/Image';
import imagesrc from '@/product/assets/png/image_3.png';
import imageWebpSrc from '@/product/assets/png/image_3.webp';
import UpdateCredentialsForm from './UpdateCredentialsForm';
import {ContentCol, ImageCol, ItemBox, Row, Wrapper} from './UpdateCredentialsSection.styled';

const UpdateCredentialsSection = ({t, showUpdateCredentials}) => {
  return (
    <Fragment>
      {showUpdateCredentials ? (
        <Wrapper>
          <Container>
            <Row>
              <ImageCol xl='6' md='6' sm='12' offset-xl='1'>
                <Image src={imagesrc} webp={imageWebpSrc} alt='new-pass-img' />
              </ImageCol>
              <ContentCol xl='4' lg='4' md='6' sm='12'>
                <ItemBox title={t('Create new password')}>
                  <Paragraph pType='p2'>{t('Create your new password by typing it below')}</Paragraph>
                </ItemBox>
                <UpdateCredentialsForm />
              </ContentCol>
            </Row>
          </Container>
        </Wrapper>
      ) : null}
    </Fragment>
  );
};

export default UpdateCredentialsSection;
