import styled, {css} from 'styled-components';
import {media} from '../../utils';
import IFooterTheme from './IFooterTheme';

export const FooterWrapper = styled.footer<{componentTheme: IFooterTheme}>`
  ${({componentTheme}) => css`
    position: relative;
    padding: 48px 0 32px;
    border-top: 1px solid ${componentTheme.border};
    ${componentTheme.background &&
    css`
      background: ${componentTheme.background};
    `}
    ${media(componentTheme.GRID_BREAKPOINTS)['md'](`
    padding: 32px 0 24px;
  `)}
    ${media(componentTheme.GRID_BREAKPOINTS)['lg'](`
    padding: 40px 0 32px;
  `)}
  `}
`;
