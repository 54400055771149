import styled from 'styled-components';
import ICheckmarkTheme from './ICheckmarkTheme';

interface ICheckmark {
  componentTheme: ICheckmarkTheme;
}

export const Checkmark = styled.span`
  position: relative;
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  display: inline-block;
  border-radius: 50%;
  margin: 0 8px;
  box-sizing: border-box;
`;

export const CheckmarkPrimary = styled(Checkmark)<ICheckmark>`
  border: 1px solid ${({componentTheme}) => componentTheme && componentTheme.border};
  background: ${({componentTheme}) => componentTheme && componentTheme.background};
`;

export const CheckmarkChecked = styled(Checkmark)<ICheckmark>`
  background: ${({componentTheme}) => componentTheme && componentTheme.backgroundChecked};
  &:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 9px;
    border: solid ${({componentTheme}) => componentTheme && componentTheme.borderChecked};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    right: 6px;
    top: 3px;
    box-sizing: border-box;
  }
`;

export const CheckmarkActive = styled(Checkmark)<ICheckmark>`
  border: 1px solid ${({componentTheme}) => componentTheme && componentTheme.borderActive};
  ${({componentTheme}) =>
    componentTheme && componentTheme.backgroundActiveFull && `background: ${componentTheme.backgroundActiveFull}`};
  &:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${({componentTheme}) => componentTheme && componentTheme.backgroundActive};
    top: 4px;
    left: 4px;
    box-sizing: border-box;
  }
`;
