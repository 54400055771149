import {all, call, put, takeEvery, takeLatest} from 'redux-saga/effects';
import {actions as moviesToWatchActions} from '@/app/reducers/moviesToWatch';
import {actions as notificationActions} from '@/app/reducers/notification';
import Logger from '@/app/modules/logger';
import {bookmarkMovieSaga} from '@/app/sagas/movie';
import {prepareError} from '@/app/utils/sagas';
import {addUserContent, deleteUserContent} from '@dev7/altar-product-sdk';
import {getUserContentsList} from '@dev7/altar-product-sdk';
import {productSDK} from '../modules/productSDK';

const logger = Logger.getInstance('MoviesToWatchSaga');

export function* getMoviesToWatchSaga() {
  try {
    const connection = yield call(productSDK.call, getUserContentsList);

    yield put(
      moviesToWatchActions.getMoviesToWatchSuccessAction({
        movies: connection.nodes,
        total: connection.totalCount,
      })
    );
  } catch (error) {
    yield put(moviesToWatchActions.getMoviesFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export function* watchLaterSaga({payload}) {
  try {
    yield call(productSDK.call, addUserContent, payload.id);
    yield put(moviesToWatchActions.watchLaterSuccessAction());
    yield call(bookmarkMovieSaga);
  } catch (error) {
    yield put(moviesToWatchActions.watchLaterFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export function* unwatchSaga({payload}) {
  try {
    yield call(productSDK.call, deleteUserContent, payload.id);
    yield put(moviesToWatchActions.unwatchSuccessAction({id: payload.id}));
    yield call(bookmarkMovieSaga);
  } catch (error) {
    yield put(moviesToWatchActions.unwatchFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export default function* watchForPromoMovies() {
  yield all([
    takeLatest(moviesToWatchActions.getMoviesToWatchAction, getMoviesToWatchSaga),
    takeEvery(moviesToWatchActions.watchLaterAction, watchLaterSaga),
    takeEvery(moviesToWatchActions.unwatchAction, unwatchSaga),
  ]);
}
