import { useEffect } from 'react';

window.LiveAgent = null;
window.agentButton = null;

export default function LiveAgent(props) {
  const { id, autoOpenChat, onAfterLoadLiveAgent } = props;
  useEffect(() => {
    if (!window.LiveAgent) {
      let scriptUrl = 'https://customerheroes.ladesk.com/scripts/track.js';
      let node = document.createElement('script');
      node.src = scriptUrl;
      node.id = 'la_x2s6df8d';
      node.type = 'text/javascript';
      node.async = true;
      node.charset = 'utf-8';
      node.onload = function() {
        window.agentButton = window.LiveAgent.createButton(id, document.getElementById('chatButton'));
        window.agentButton.onOnline = () => {
          onAfterLoadLiveAgent();
          if (autoOpenChat) {
            window.agentButton.onClick();
          }
        };
      };
      document.getElementsByTagName('body')[0].appendChild(node);
    }
    return () => {
      window.LiveAgent = null;
      document.getElementById('la_x2s6df8d').remove();
    };
  }, [id, autoOpenChat, onAfterLoadLiveAgent]);
  return null;
}
