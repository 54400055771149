import {ComponentType} from 'react';
import styled from 'styled-components';
import IFieldErrorTheme from './IFieldErrorTheme';

export const FieldErrorStyled = styled.span<{componentTheme: IFieldErrorTheme}>`
  color: ${({componentTheme}) => componentTheme && componentTheme.error};
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
`;

interface FieldErrorProps {
  children: ComponentType | HTMLElement;
  componentTheme: IFieldErrorTheme;
}

function FieldError(props: FieldErrorProps) {
  const {children, ...rest} = props;
  return <FieldErrorStyled {...rest}>{children}</FieldErrorStyled>
}

export default FieldError;
