import styled from 'styled-components';
import Button from './../Button';
import Close from '../Close';
import ICookiesTheme from './ICookiesTheme';

interface CookiesBox {
  componentTheme: ICookiesTheme;
}

export const CookiesBox = styled.div<CookiesBox>`
  position: fixed;
  bottom: 60px;
  right: 30px;
  width: 280px;
  border: 1px solid ${({componentTheme}) => componentTheme.borderColor || '#D1CCC0'};
  background: ${({componentTheme}) => componentTheme && componentTheme.background};
  border-radius: ${({componentTheme}) => componentTheme && componentTheme.borderRadius};
  ${({componentTheme}) => componentTheme.boxShadow && `box-shadow: ${componentTheme.boxShadow}`}
  padding: 24px 40px 24px 23px;
  z-index: 99999;
`;

export const CookiesClose = styled(Close)`
  top: 16px;
  right: 16px;
  width: 16px;
  height: 16px;
  &:before,
  &:after {
    left: auto;
    right: 8px
`;

export const CookiesButton = styled(Button)`
  font-size: ${({componentTheme}) =>
    componentTheme && componentTheme.buttonOverrides && componentTheme.buttonOverrides.fontSize
      ? componentTheme.buttonOverrides.fontSize
      : '14px'};
  font-weight: ${({componentTheme}) =>
    componentTheme && componentTheme.buttonOverrides && componentTheme.buttonOverrides.fontWeight};
  font-family: ${({componentTheme}) =>
    componentTheme && componentTheme.buttonOverrides && componentTheme.buttonOverrides.fontFamily};
  line-height: 16px;
  padding: ${({componentTheme}) =>
    componentTheme && componentTheme.buttonOverrides && componentTheme.buttonOverrides.padding
      ? componentTheme.buttonOverrides.padding
      : '8px 12px'};
  margin: 16px 0 0;
`;
