import axios from 'axios';
import CONFIG from '@/app/config';
import {getUrlWithParams} from '@/app/utils/query';

export const getSession = async () => {
  const url = CONFIG.api.sessionUrl + window.location.search;
  const result = await fetch(url).then((response) => response.json());
  return result;
};

export const getPromoSession = async () => {
  const url = CONFIG.api.sessionUrl + '?__mpp=promo';
  const result = await fetch(url).then(response => response.json());
  return result;
};

export const registerTrialUser = async (value) => {
  const url = `${CONFIG.api.sessionUrl}register_api/v1/trial_register`;
  const response = await axios.post(
    getUrlWithParams(url, value),
    {},
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
  return response.data;
};

export const createAccount = async (data) => {
  const url = `${CONFIG.api.sessionUrl}register_user/`;
  const result = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
  }).then((response) => response.json());
  return result;
};

export const verifyAccount = async (data) => {
  const url = `${CONFIG.api.sessionUrl}register_payment/`;
  const result = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
  }).then((response) => response.json());
  return result;
};

export const updatePaymentMethod = async (data) => {
  const url = `${CONFIG.api.sessionUrl}update_payment/`;
  const result = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
  }).then((response) => response.json());
  return result;
};
