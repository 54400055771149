export const LANGUAGES = {
  EN: 'en',
  ES: 'es',
  FR: 'fr',
  BR: 'br',
};

export const I18N_LANGUAGES = {
  EN: 'en',
  ES: 'es',
  FR: 'fr',
  PT: 'pt',
};
