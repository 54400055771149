import {ReactNode} from 'react';
import styled from 'styled-components';
import Wrapper from './../Wrapper';
import {ReactComponent as SearchIconSrc} from './assets/icon_search.svg';
import {ReactComponent as CloseIconSrc} from './assets/icon_close.svg';
import {media} from '../../utils';
import ISearcherTheme from './ISearcherTheme';

const iconStyles = (componentTheme: ISearcherTheme) => ({
  width: '16px',
  height: '16px',
  fill: componentTheme && componentTheme.iconFill,
});

export const SearcherStyled = styled.div``;

interface ISearchButton {
  isExpanded: boolean;
  componentTheme: ISearcherTheme;
}

export const SearchBtn = styled.button<ISearchButton>`
  position: absolute;
  top: 1px;
  right: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  padding: 0;
  outline: none;
  ${({componentTheme, isExpanded}) => `
    background: ${
      isExpanded
        ? `${componentTheme.btnBackgroundExpanded || componentTheme.btnBackground}`
        : `${componentTheme.btnBackground}`
    };
  `}
  border: none;
  border-radius: ${({componentTheme}) =>
    componentTheme && componentTheme.borderRadius ? `${componentTheme.borderRadius}` : `4px`};
  &:hover {
    cursor: pointer;
  }
  transition: background ${({isExpanded}) => (isExpanded ? '0s' : '.3s')};
`;

export const SearchIcon = styled(SearchIconSrc)<{componenttheme: ISearcherTheme}>`
  ${({componenttheme}) => iconStyles(componenttheme)};
`;

export const CloseIcon = styled(CloseIconSrc)<{componenttheme: ISearcherTheme}>`
  ${({componenttheme}) => iconStyles(componenttheme)};
`;

interface ISearchInput {
  error?: boolean;
  isExpanded: boolean;
  ref: any;
  componentTheme: ISearcherTheme;
}

export const SearchInput = styled.input<ISearchInput>`
  font-family: inherit;
  color: ${({error, componentTheme}) => (error ? componentTheme.colorError : componentTheme.color)};
  background: ${({error, componentTheme, isExpanded}) =>
    error
      ? componentTheme.backgroundError
      : isExpanded && componentTheme.backgroundExpanded
      ? componentTheme.backgroundExpanded
      : componentTheme.background};
  border: 1px solid
    ${({error, componentTheme, isExpanded}) =>
      error
        ? componentTheme.borderError
        : isExpanded && componentTheme.borderExpanded
        ? componentTheme.borderExpanded
        : componentTheme.border};
  border-radius: ${({componentTheme}) =>
    componentTheme && componentTheme.borderRadius ? `${componentTheme.borderRadius}` : `4px`};
  font-size: ${({componentTheme}) =>
    componentTheme && componentTheme.fontSize ? `${componentTheme.fontSize}` : `14px`};
  line-height: 20px;
  outline: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  ${({componentTheme}) => componentTheme && componentTheme.fontWeight && `font-weight: ${componentTheme.fontWeight};`}

  height: 40px;
  width: 100%;
  ${({componentTheme, isExpanded}) =>
    media(componentTheme.GRID_BREAKPOINTS)['md'](`
    min-width: ${isExpanded ? '376px' : '0'};
  `)}
  padding: ${({isExpanded}) => (isExpanded ? '8px 40px 8px 16px' : '0')};
  transition: min-width 0.3s, padding 0.3s, background ${({isExpanded}) => (isExpanded ? '0s' : '.3s')};
  -webkit-appearance: none;
  &:active,
  &:focus {
    background: ${({componentTheme}) => componentTheme && componentTheme.backgroundActive};
    border: 1px solid ${({componentTheme}) => componentTheme.borderActive};
  }

  &::placeholder {
    opacity: ${({isExpanded}) => (isExpanded ? '1' : '0')};
    ${({componentTheme}) => componentTheme.placeholderColor && `color: ${componentTheme.placeholderColor};`}
    transition: .3s ease;
  }
`;

interface ISearchWrapper {
  isExpanded: boolean;
  expandedReversed?: boolean;
  ref: any;
  children: ReactNode | string;
}

export const SearchWrapper = styled(Wrapper)<ISearchWrapper>`
  position: relative;
  display: flex;
  ${({expandedReversed}) => (expandedReversed ? `justify-content: flex-end` : `justify-content: flex-start`)};
  width: ${({isExpanded}) => (isExpanded ? '100%' : '40px')};
  z-index: 3;
  transition: width 0.3s;
`;
