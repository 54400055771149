import {fromJS} from 'immutable';
import {prepareReducers} from '@/app/utils/redux';
import actions from './actions';

export const initialState = fromJS({
  isLoading: false,
  movie: null,
});

const reducer = prepareReducers(
  {
    [actions.getMovieAction]: (state) => state.set('isLoading', true),
    [actions.getMovieSuccessAction]: (state, {payload}) => state.set('isLoading', false).set('movie', payload),
    [actions.getMovieFailedAction]: (state) => state.set('isLoading', false),
    [actions.clearMovieAction]: (state) => state.set('isLoading', false).set('movie', null),
    [actions.bookmarkMovieAction]: (state) =>
      state.update('movie', (movie) => (movie ? {...movie, isUserContent: !movie.isUserContent} : null)),
  },
  initialState
);

export default reducer;
