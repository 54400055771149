import {ReactComponent as CheckIcon} from './assets/icon_check.svg';
import {
  ActionStyled,
  IconWrapper,
  LinkContent,
  LinkStyled,
  SelectContent,
  SelectStyled,
} from './DropDownListItem.styled';
import IDropDownTheme from './IDropDownTheme';

type ItemType = 'Link' | 'Action' | 'Select';

export interface IListItem {
  type: ItemType;
  name: string;
  to?: string;
  icon?: any;
  callback?: Function;
  isSelected?: boolean;
}

export interface DropDownListItemProps {
  item: IListItem;
  handleClose: Function;
  mainIconListOptions?: any;
  componentTheme: IDropDownTheme;
}

export function DropDownListItem(props: DropDownListItemProps) {
  const {item, handleClose, componentTheme, mainIconListOptions} = props;
  const {type, name, to, icon: Icon, callback, isSelected} = item;
  switch (type) {
    case 'Link':
      return (
        <LinkStyled to={to as string} key={name} onClick={() => handleClose()} componenttheme={componentTheme}>
          {Icon && (
            <IconWrapper>
              <Icon {...mainIconListOptions} />
            </IconWrapper>
          )}
          <LinkContent componentTheme={componentTheme}>{name}</LinkContent>
        </LinkStyled>
      );
    case 'Action':
      const handleClickAction = () => {
        handleClose();
        callback && callback();
      };
      return (
        <ActionStyled onClick={handleClickAction} key={name} componentTheme={componentTheme}>
          {Icon && (
            <IconWrapper>
              <Icon {...mainIconListOptions} />
            </IconWrapper>
          )}
          <LinkContent className='DropDownListItemName' componentTheme={componentTheme}>
            {name}
          </LinkContent>
        </ActionStyled>
      );
    case 'Select':
      const handleClickSelectAction = () => {
        handleClose();
        callback && callback();
      };
      return (
        <SelectStyled onClick={handleClickSelectAction} key={name} componentTheme={componentTheme}>
          {Icon && <Icon />}
          <SelectContent componentTheme={componentTheme}>{name}</SelectContent>
          {isSelected && <CheckIcon width={10} height={10} />}
        </SelectStyled>
      );
    default:
      return <span>{name}</span>;
  }
}
