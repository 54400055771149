import {FetchResult, StoreObject} from '@apollo/client';
import type {ProductSDK} from '../../../ProductSDK';
import {requireDefined} from '../../../utils/nullability-utils';
import {Operation} from '../../../models/Operation';
import {DeleteUserContentInput} from './models/DeleteUserContentInput';
import {requireSuccessfulOperation} from '../../../utils/operations-utils';
import {deleteUserContentMutation} from '../../../graphql/mutation/deleteUserContent';
import {Content} from '../../../models/Content';
import {getContentQuery} from '../../../graphql/query/getContent';
import {updateCache} from './misc/updateCache';

type Results = FetchResult<{deleteUserContent: Operation<unknown>}>;

/**
 * @throws OperationFailError
 */
export async function deleteUserContent(this: ProductSDK, contentId: string): Promise<void> {
  const variables: {input: DeleteUserContentInput} = {
    input: {contentId},
  };

  const response: Results = await this.client.mutate({
    mutation: deleteUserContentMutation,
    variables: variables,
  });

  const getContentResults: {content: Content & StoreObject} | null = this.client.readQuery({
    query: getContentQuery,
    variables: {id: contentId},
  });

  if (getContentResults) {
    this.client.cache.modify({
      id: this.client.cache.identify(getContentResults.content),
      fields: {
        isUserContent() {
          return false;
        },
      },
    });
  }

  const data = requireDefined(response.data?.deleteUserContent);
  requireSuccessfulOperation(data);

  updateCache.call(this, contentId);
}
