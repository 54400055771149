import { Component } from 'react';
import {LANGUAGES} from '@/app/constants/languages';
import {ROUTES} from '@/app//Routes';
import CONFIG from '@/app/config';
import {
  BackdropMenu,
  Hamburger,
  LanguagePicker,
  LogoLinkStyled,
  LogoStyled,
  MainMenu,
  MenuWrapper,
  NotLoggedWrapper,
} from './NotLogged.styled';

class HeaderNotLogged extends Component {
  state = {
    isOpen: false,
  };

  componentDidUpdate(prevProps) {
    const {isOpen} = this.state;
    if (!isOpen) return;

    if (prevProps.location.pathname !== this.props.location.pathname && isOpen) {
      this.setState({isOpen: false});
    }
  }

  handleLangChange = (lang) => {
    const {changeLanguage} = this.props;
    const mappedLang = lang === 'br' ? 'pt' : lang;
    changeLanguage && changeLanguage(mappedLang);
  };

  handleHamburgerClick = () => {
    this.setState((prev) => ({isOpen: !prev.isOpen}));
  };

  handleLinkClick = (path) => {
    const {isOpen} = this.state;
    if (isOpen) {
      this.setState({isOpen: false});
    }

    if (path !== this.props.location.pathname) {
      this.props.history.push(path);
    }
  };

  render() {
    const {t, language} = this.props;
    const {isOpen} = this.state;
    const mappedLang = language === 'pt' ? 'br' : language;

    const backdropLinks = [
      {
        id: 2,
        type: 'link',
        path: ROUTES.LOGIN.path,
        text: t('Log In'),
        callback: () => this.handleLinkClick(ROUTES.LOGIN.path),
      },
      {
        id: 3,
        type: 'navlink',
        path: ROUTES.REGISTER.path,
        text: t('Register'),
        callback: () => this.handleLinkClick(ROUTES.REGISTER.path),
      },
      {
        id: 1,
        type: CONFIG.support_redirect ? 'anchor' : 'navlink',
        path: ROUTES.SUPPORT.path,
        text: t('Support'),
        callback: () => this.handleLinkClick(ROUTES.SUPPORT.path),
      },
    ];

    const links = [
      {
        id: 4,
        type: CONFIG.support_redirect ? 'anchor' : 'link',
        path: ROUTES.SUPPORT.path,
        text: t('Support'),
      },
      {id: 5, type: 'navlink', path: ROUTES.REGISTER.path, text: t('Register')},
      {id: 6, type: 'link', path: ROUTES.LOGIN.path, text: t('Log In'), isButton: true,},
    ];

    return (
      <NotLoggedWrapper>
        <Hamburger onClick={this.handleHamburgerClick} isActive={isOpen} />
        <LogoLinkStyled url={ROUTES.MAINPAGE.path} ariaLabel={t('Go to homepage')}>
          <LogoStyled />
        </LogoLinkStyled>
        <MenuWrapper>
          <MainMenu links={links} />
          <LanguagePicker onSelect={this.handleLangChange} language={mappedLang} languages={LANGUAGES} showFlag withUSFlag={CONFIG.with_us_flag} />
        </MenuWrapper>
        <BackdropMenu isOpen={isOpen} links={backdropLinks} />
      </NotLoggedWrapper>
    );
  }
}

export default HeaderNotLogged;
