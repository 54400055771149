import styled from 'styled-components';
import {media} from '../../../utils';
import {cols} from './Col';

interface IColWrapper {
  col?: cols;
  xs?: cols;
  sm?: cols;
  md?: cols;
  lg?: cols;
  xl?: cols;
  offset?: cols;
  order?: string;
  theme: any;
}

const col = (size: cols) => `flex: 0 0 ${(100 / 12) * parseInt(size)}%; max-width: ${(100 / 12) * parseInt(size)}%;`;

const offsetCss = (offset: cols) => `margin-left: ${(100 / 12) * parseInt(offset)}%`;

const offsetMedia = (props: IColWrapper) =>
  Object.keys(props)
    .filter((key) => /^offset-/.test(key))
    .map((offset) => offset.split('-')[1])
    .map((size) => media(props.theme.GRID_BREAKPOINTS)[size](`${offsetCss((props as any)['offset-' + size])}`));

const orderCss = (order: string) => `order: ${order}`;

const orderMedia = (props: IColWrapper) =>
  Object.keys(props)
    .filter((key) => /^order-/.test(key))
    .map((order) => order.split('-')[1])
    .map((size) => media(props.theme.GRID_BREAKPOINTS)[size](`${orderCss((props as any)['order-' + size])}`));

export const ColWrapper = styled.div<IColWrapper>`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 20px;
  padding-left: 20px;
  flex: 0 0 ${(props) => (100 / 12) * (parseInt(props.col as any) || 12)}%;
  max-width: ${(props) => (100 / 12) * (parseInt(props.col as any) || 12)}%;
  /* col-xs */
  ${(props) =>
    props.xs &&
    media(props.theme.GRID_BREAKPOINTS).xs(`
      ${col(props.xs)}
  `)}
  /* col-sm */
  ${(props) =>
    props.sm &&
    media(props.theme.GRID_BREAKPOINTS).sm(`
      ${col(props.sm)}
  `)}
  /* col-md */
  ${(props) =>
    props.md &&
    media(props.theme.GRID_BREAKPOINTS).md(`
      ${col(props.md)}
  `)}
  /* col-lg */
  ${(props) =>
    props.lg &&
    media(props.theme.GRID_BREAKPOINTS).lg(`
      ${col(props.lg)}
  `)}
  /* col-xl */
  ${(props) =>
    props.xl &&
    media(props.theme.GRID_BREAKPOINTS).xl(`
     ${col(props.xl)}
  `)}
  ${(props) => props.offset && offsetCss(props.offset)}
  ${(props) => offsetMedia(props)}
  ${(props) => props.order && orderCss(props.order)}
  ${(props) => orderMedia(props)}

  /* change styles */
  ${(props) =>
    props.theme &&
    media(props.theme.GRID_BREAKPOINTS).md(`
    padding-right: 15px;
    padding-left: 15px;
  `)}
`;
