import {Component, ReactNode} from 'react';
import styled from 'styled-components';

const ViewStyled = styled.main``;

interface IViewProps {
  title: string;
  children: ReactNode | string;
}

class View extends Component<IViewProps> {
  componentDidMount() {
    document.title = this.props.title;
  }
  componentDidUpdate() {
    document.title = this.props.title;
  }
  render() {
    const {children, ...rest} = this.props;
    return <ViewStyled {...rest}>{children}</ViewStyled>;
  }
}

export default View;
