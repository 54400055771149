import {gql} from '@apollo/client';

export const getUserContentsListQuery = gql`
  query getUserContentsList(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $sort: SortInput
    $categories: [ID!]
    $contentTypeId: ID
    $tags: [String!]
    $search: String
  ) {
    userContents(
      before: $before
      after: $after
      first: $first
      last: $last
      sort: $sort
      categories: $categories
      contentTypeId: $contentTypeId
      tags: $tags
      search: $search
    ) {
      totalCount
      nodes {
        id
        contentType {
          id
          name
        }
        categories {
          id
          name
          categoryType
        }
        title
        description
        images {
          url
          imageType
          format
        }
        files {
          id
          url
          name
          format
          description
          durationMinutes
        }
        isUserContent
        language {
          isoCode
          name
        }
        properties {
          name
          value
        }
        tags {
          name
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
