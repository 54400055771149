import {withRouter} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import GdprProvider from '@/app/providers/gdpr';
import {Startup} from '@/app/ui';
import loadFonts from '@/app/utils/loadFonts';
import {getConfig} from '@/app/utils/general';
import theme from '@/product/theme';
import {ROUTES} from '@/app/Routes';
import Wrapper from '@/app/components/Wrapper';
import CONFIG from '@/app/config';
import I18nProvider from '@/app/providers/i18n';
import {getLang} from '@/app/utils/i18n/langStore';
import AdvanceCookies from '@/app/components/AdvanceCookies';
import Cookies from '@/app/components/Cookies';
import GlobalStyle from '@/app/styles/global.styled';
import Routing from '@/app/Routing';
import UpdateCredentials from '@/app/components/UpdateCredentials';
import Notifications from '@/app/components/Notifications';
import Header from '@/app/components/Header';
import Footer from '@/app/components/Footer';

const config = getConfig();
loadFonts(config.STYLEGUIDE.fonts);
const themeReady = {...config.STYLEGUIDE, ...theme};

function App({location}) {
  const actualPath = Object.values(ROUTES).find(({path}) => path && path.includes(location.pathname));
  const actualView = actualPath ? actualPath.view : 'MainPage';

  return (
    <Startup>
      <ThemeProvider theme={themeReady}>
        <I18nProvider language={getLang()} view={actualView}>
          <GdprProvider>
            <Wrapper>
              <GlobalStyle />
              <Header />
              <Routing />
              <Footer />
              {CONFIG.advance_cookie ? <AdvanceCookies /> : <Cookies />}
              <Notifications />
              <UpdateCredentials />
            </Wrapper>
          </GdprProvider>
        </I18nProvider>
      </ThemeProvider>
    </Startup>
  );
}

export default withRouter(App);
