export class CredentialsAlreadySetError extends Error {
  public static ERR_CODE = 212;
  public static ERR_MESSAGE = 'Credentials already set';

  constructor() {
    super('Credentials already set. Make sure that `user.updateCredentials` is true.');
    this.name = CredentialsAlreadySetError.name;
    if (Object.hasOwnProperty.call(Error, 'captureStackTrace')) Error.captureStackTrace(this);
  }
}
