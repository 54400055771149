import styled, {css, keyframes} from 'styled-components';

const LdsRingKeyfrems = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

interface IglobalStyle {
  pixelSize: string;
  borderWidth: string;
}

const globalStyle = (props: IglobalStyle) => css`
  width: ${props.pixelSize};
  height: ${props.pixelSize};
  border-width: ${props.borderWidth};
`;

interface IinnerStyle {
  color: string;
  color2: string;
}

const innerStyle = (props: IinnerStyle) => css`
  ${globalStyle};
  border-color: ${props.color};
  border-top-color: ${props.color2};
`;

interface ILdsRing {
  centered?: boolean;
  pixelSize: string;
  borderWidth: string;
}

export const LdsRing = styled.span<ILdsRing>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 6px solid transparent;
  border-radius: 50%;
  ${globalStyle};
  ${(props: ILdsRing) =>
    props.centered
      ? `
    margin: 0 auto;
  `
      : ``}
`;

interface ILdsRingInner {
  color: string;
  color2: string;
  pixelSize: string;
  borderWidth: string;
}

export const LdsRingInner = styled.span<ILdsRingInner>`
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  animation: ${LdsRingKeyfrems} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-style: solid;
  border-width: 6px;
  border-color: black;
  border-top-color: red;
  animation-delay: -0.45s;
  ${innerStyle};
`;
