import {all, call, put, takeLatest} from 'redux-saga/effects';
import {actions as recommendedMoviesActions} from '@/app/reducers/recommendedMovies';
import {actions as notificationActions} from '@/app/reducers/notification';
import Logger from '@/app/modules/logger';
import {prepareError} from '@/app/utils/sagas';
import {getContentsList} from '@dev7/altar-product-sdk';
import {productSDK} from '../modules/productSDK';

const logger = Logger.getInstance('MoviesSaga');

export function* getRecommendedMoviesActionsSaga() {
  try {
    const result = yield call(productSDK.call, getContentsList, {
      first: 10,
      sort: {by: 'shuffle', direction: 'ASC'},
    });
    yield put(
      recommendedMoviesActions.getRecommendedMoviesSuccessAction({
        movies: result.nodes,
        total: result.totalCount,
      })
    );
  } catch (error) {
    yield put(recommendedMoviesActions.getRecommendedMoviesFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export default function* recommendedMovies() {
  yield all([takeLatest(recommendedMoviesActions.getRecommendedMoviesAction, getRecommendedMoviesActionsSaga)]);
}
