import _merge from 'lodash/merge';

export function prepareTranslations(translations, language = 'en') {
  return {
    [language]: {
      translations: {
        '': translations,
      },
    },
  };
}

export async function loadTranslations(language, view) {
  const viewTranslations = await loadViewTranslations(language, view);
  const commonTranslations = await loadCommonTranslations(language);
  const merged = prepareTranslations(_merge(viewTranslations, commonTranslations), language);
  window.translations = merged;
  const event = new CustomEvent('translations-loaded', {detail: {messages: merged, language}});
  window.dispatchEvent(event);
  return merged;
}

export async function loadViewTranslations(language, view) {
  if (!view) return {};
  const lang = language || document.documentElement.lang;
  const translations = await import(
    /* webpackChunkName: "view-translations." */ `@/app/view/${view}/translations/${lang}.json`
  );
  return translations.default;
}

export async function loadCommonTranslations(language) {
  const lang = language || document.documentElement.lang;
  if (!lang) return {};
  const translations = await import(
    /* webpackChunkName: "common-translations." */ `@/app/translations/${lang}.json`
  );
  return translations.default;
}
