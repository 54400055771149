import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {createStructuredSelector} from 'reselect';
import {actions, selectors} from '@/app/reducers/startup';
import {toJS} from '@/app/utils/immutable/toJS';
import Startup from './Startup.js';

const mapStateToProps = createStructuredSelector({
  isLoading: selectors.isLoading(),
});

const mapDispatchToProps = (dispatch) => ({
  startupAction: bindActionCreators(actions.startupAction, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(toJS(Startup));
