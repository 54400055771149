import queryString from 'query-string';

export const setQueryParam = (query) => {
  const queryParams = {...getQueryParams(), ...query};
  window.location.search = queryString.stringify(queryParams, {sort: false});
};

export const getQueryParams = () => queryString.parse(window.location.search);

export const getQueryParamsString = (extraParams) => {
  const params = extraParams ? {...getQueryParams(), ...extraParams} : getQueryParams();
  return queryString.stringify(params, {sort: false});
};

export const hasQueryParam = (keys) => {
  const params = getQueryParams();
  if (Array.isArray(keys)) {
    return keys.some((key) => Object.hasOwnProperty.call(params, key));
  } else {
    return !!params[keys];
  }
};

export const getQueryParam = (keys) => {
  const params = getQueryParams();
  if (Array.isArray(keys)) {
    const result = {};
    keys.forEach((key) => {
      if (Object.hasOwnProperty.call(params, key)) result[key] = params[key];
    });
    return result;
  } else {
    return params[keys];
  }
};

export function getUrlWithParams(url, params) {
  if (!url) throw new Error('url is not specified!');
  return (
    url + (url.indexOf('?') === -1 ? '?' : '&') + queryString.stringify(params, {sort: false, arrayFormat: 'bracket'})
  );
}

export function getCurrentUrlWithParams(url, params) {
  return getUrlWithParams(url, params);
}

export function getCurrentUrlWithAllParams(extraParams) {
  const currentUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
  return getUrlWithAllParams(currentUrl, extraParams);
}

export function getUrlWithAllParams(url, extraParams) {
  if (!url) throw new Error('url is not specified!');

  const params = extraParams ? {...getQueryParams(), ...extraParams} : getQueryParams();
  const queryStringified = queryString.stringify(params, {sort: false});
  if (!queryStringified) {
    return url;
  } else {
    return url + (url.indexOf('?') === -1 ? '?' : '&') + queryStringified;
  }
}

// return params string by some regex
// ex. http://<my-app>.com?some_param=test=3&valid=true
// cutParamsString('?some_param=') // test=3&valid=true
export const cutParamsString = (regexp = '') => {
  const [paramsSlug] = window.location.search.split(regexp).slice(-1);
  if (!paramsSlug || typeof paramsSlug !== 'string') {
    return null;
  }
  return paramsSlug;
};
