import { Component } from 'react';

class Auth extends Component {
  componentDidMount() {
    const {startupAction} = this.props;
    startupAction && startupAction();
  }

  render() {
    return !this.props.isLoading ? this.props.children : null;
  }
}

export default Auth;
