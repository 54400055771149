import {ReactNode} from 'react';
import {ContainerWrapper} from './Container.styled';

export interface ContainerProps {
  children: ReactNode | string;
}

function Container(props: ContainerProps) {
  const {children, ...rest} = props;
  return <ContainerWrapper {...rest}>{children}</ContainerWrapper>;
}

export default Container;
