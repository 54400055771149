import {SessionTokens} from './models/SessionTokens';
import {FinalOptions, Options} from './models/Options';
import {ApolloClient, InMemoryCache, NormalizedCacheObject} from '@apollo/client';
import {ApolloClientOptions} from '@apollo/client/core/ApolloClient';
import {refreshToken} from './api/mutation/refreshToken';

const defaultOptions: Partial<Options> = {
  refreshThreshold: 5 * 60 * 1000, // 5 minutes
};

export class ProductSDK {
  protected readonly options: FinalOptions;
  public client!: ApolloClient<NormalizedCacheObject>;
  private refreshTokenTimeoutRef?: NodeJS.Timeout;

  public constructor(options: Options) {
    if (!options.url) throw new Error('URL was not provided.');

    this.options = {...defaultOptions, ...options} as FinalOptions;
    this.client = this.setupClient();

    this.call = this.call.bind(this);
  }

  private setupClient(accessToken?: string): ApolloClient<NormalizedCacheObject> {
    const options: ApolloClientOptions<NormalizedCacheObject> = {
      uri: this.options.url,
      cache: new InMemoryCache(),
      headers: {},
    };

    if (accessToken) options.headers!['Authorization'] = `Bearer ${accessToken}`;

    return new ApolloClient(options);
  }

  private refreshToken = refreshToken.bind(this);

  private setupRefreshTimeout(sessionTokens: SessionTokens, onRefreshCallback: (sessionTokens: SessionTokens) => void) {
    const expiryTimeout = this.getAccessTokenExpireTimeout(sessionTokens);
    this.refreshTokenTimeoutRef = setTimeout(async () => {
      try {
        const newSessionTokens = await this.refreshToken(sessionTokens.refreshToken);
        this.client = this.setupClient(newSessionTokens.accessToken);
        onRefreshCallback(newSessionTokens);
        this.setupRefreshTimeout(newSessionTokens, onRefreshCallback);
      } catch (e: unknown) {
        console.error(
          'Unable to refresh authentication token.',
          'Cancelling further attempts.',
          'Error log should appear below:'
        );
        throw e;
      }
    }, expiryTimeout);
  }

  private clearRefreshTimeout() {
    if (this.refreshTokenTimeoutRef) {
      clearTimeout(this.refreshTokenTimeoutRef);
      this.refreshTokenTimeoutRef = undefined;
    }
  }

  private getAccessTokenExpireTimeout(sessionTokens: SessionTokens) {
    let expiry = parseInt(sessionTokens.expiry);
    if (isNaN(expiry))
      throw new Error('Invalid sessions tokens `expiry` provided. Expected valid unix epoch timestamp.');

    expiry = expiry * 1000; // to milliseconds
    const timeNow = new Date().getTime();
    return expiry - timeNow - this.options.refreshThreshold;
  }

  public setupAuthentication(sessionTokens: SessionTokens, onRefreshCallback: (sessionTokens: SessionTokens) => void) {
    this.client = this.setupClient(sessionTokens.accessToken);
    this.setupRefreshTimeout(sessionTokens, onRefreshCallback);
  }

  public dropAuthentication() {
    this.clearRefreshTimeout();
    this.client = this.setupClient();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public call<R, T extends (this: ProductSDK, ...args: any[]) => R>(callableFunc: T, ...args: Parameters<T>): R {
    return callableFunc.call(this, ...args);
  }
}
