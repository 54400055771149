import Link from "@dev7/altar-alt-ui/Link";

export const Checkboxes = (t, isUS, checkbox1, checkbox2, checkbox3, checkbox4) => {
  return [
    {
      id: 'checkbox1',
      state: checkbox1,
      title: t('Performance and analytics cookies'),
      description: t(
        'If you allow these cookies, you help us make the website better. They collect information such as the most popular pages and any errors displayed. These are fully anonymous aggregates and in no way identify you personally.'
      ),
    },
    {
      id: 'checkbox2',
      state: checkbox2,
      title: t('Functionality and personalization cookies'),
      description: t(
        'If you allow these cookies, you won’t have to enter the same info every time you visit the website. They remember the choices you make (language and location, cookie acceptance, LiveAgent settings) and any customization you enter (text size, fonts etc.).'
      ),
    },
    {
      id: 'checkbox3',
      state: checkbox3,
      title: t('Targeting and advertising cookies'),
      description: t(
        'If you allow these cookies, we will be able to give you better, more relevant suggestions. They collect information about your viewing habits and use them to match adverts (our own and third party) to your tastes.'
      ),
    },
    {
      id: 'checkbox4',
      state: checkbox4,
      title: t('LiveAgent cookies'),
      description: isUS ? t(
        'You need to enable these cookies for the LiveAgent function to work. These are third-party cookies, provided by LiveAgent (Quality Unit, LLC) Full privacy policy is available on the supplier’s '
      ) : t(
        'You need to enable these cookies for the LiveAgent function to work. These are third-party cookies, provided by LiveAgent (Quality Unit, s. r. o). Full privacy policy is available on the supplier’s '
      ),
      link: (
        <Link href='https://www.liveagent.com/privacy-policy/' type='anchor' target='_blank'>
          {t('website.')}
        </Link>
      ),
    },
  ];
};
