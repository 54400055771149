import {Component} from 'react';
import {Message, NotificationItemStyled} from './NotificationItem.styled';
import INotificationsTheme from './../INotificationsTheme';

const TIMEOUT = 5000;
const CLOSE_BEFORE = 1000;

interface INotificationItemProps {
  timeout: number;
  type: 'danger' | 'info' | 'warning' | 'success';
  removeNotification: Function;
  message: any;
  id: any;
  componentTheme: INotificationsTheme;
}

interface INotificationItemState {
  close: boolean;
}

class NotificationItem extends Component<INotificationItemProps, INotificationItemState> {
  static defaultProps = {
    timeout: TIMEOUT,
  };

  private timeoutId: number | undefined = undefined;

  state = {
    close: false,
  };

  componentDidMount() {
    this.removeMe();
    this.closeMe();
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
    this.timeoutId = undefined;
  }

  removeMe = () => {
    const {id, removeNotification, timeout} = this.props;
    this.timeoutId = setTimeout(() => removeNotification(id), timeout);
  };

  closeMe = () => {
    this.timeoutId = setTimeout(() => this.setState({close: true}), this.props.timeout - CLOSE_BEFORE);
  };

  render() {
    const {type, message, componentTheme, ...rest} = this.props;

    return (
      <NotificationItemStyled close={this.state.close} type={type} {...rest} componentTheme={componentTheme}>
        <Message componentTheme={componentTheme} pType='p1'>
          {message}
        </Message>
      </NotificationItemStyled>
    );
  }
}

export default NotificationItem;
