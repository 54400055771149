import {ComponentType} from 'react';
import {CloseWrapper} from './Close.styled';

import ICloseTheme from './ICloseTheme';

interface CloseProps {
  withAnimation?: boolean;
  ariaLabelClose?: string;
  componentTheme: ICloseTheme;
  children?: ComponentType | HTMLElement;
}

function Close(props: CloseProps) {
  const {ariaLabelClose, ...rest} = props;
  return <CloseWrapper tabIndex={0} role='button' aria-label={ariaLabelClose} {...rest} />

}

export default Close;
