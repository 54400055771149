import {media} from './utils';
import {IStyles} from './styles.interface';

export interface IAppyStyles {
  section: string;
  component: string;
  theme: any;
}

export function applyStyles(props: IAppyStyles) {
  const {section, component, theme} = props;
  const {GRID_BREAKPOINTS} = theme;
  const applySection: IStyles = theme[section].styles;
  return (
    applySection &&
    applySection[component] &&
    Object.keys(applySection[component]).map((size) =>
      media(GRID_BREAKPOINTS)[size](
        Object.keys((applySection[component] as any)[size]).reduce(
          (prev, item) => `${prev} ${item}: ${(applySection[component] as any)[size][item]}; `,
          ''
        )
      )
    )
  );
}
