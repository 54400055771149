import {AnyObject} from '../utils/typescript-utils';

export class OperationFailError extends Error {
  private readonly errors: AnyObject[];

  constructor(errors: AnyObject[]) {
    super('\n' + JSON.stringify(errors, null, 2));
    this.errors = errors;
    this.name = OperationFailError.name;
    if (Object.hasOwnProperty.call(Error, 'captureStackTrace')) Error.captureStackTrace(this);
  }

  public getErrors(): AnyObject[] {
    return this.errors;
  }
}
