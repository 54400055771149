/* eslint-disable max-lines */

import {all, call, delay as effectDelay, put, race, take, takeLatest} from 'redux-saga/effects';
import {
  getSessionStorageItem,
  removeRegisterInputs,
  removeSessionStorageItem,
  setSessionStorageItem,
  storeRegisterInputs,
} from '@/app/modules/sessionStorage';
import {actions} from '@/app/reducers/register';
import {actions as notificationActions} from '@/app/reducers/notification';
import {checkIfGlobalError, prepareRegisterError} from '@/app/utils/sagas';
import {getQueryParams} from '@/app/utils/query';
import {ERROR_CODES, ERROR_MESSAGES} from '@/app/constants/errorCodes';
import {HOBJ_PARAMS_INTERCEPT, REGISTER_PAYMENT_ACTION} from '@/app/constants/register';
import {init3DSScript, remove3DSIframe, redirect3DS} from '@/app/lib/3dsecure';
import * as registerApi from '@/app/api/register';
import Logger from '@/app/modules/logger';

const logger = Logger.getInstance('RegisterSaga');
const SESSION_ID = 's_id';
const PAYMENT_METHOD = 'p_a';

export function* getSessionSaga() {
  try {
    const sessionId = yield call(getSessionStorageItem, SESSION_ID);
    const {hobj} = yield call(getQueryParams, window.location.search);
    if (hobj) {
      const {hsid, action} = JSON.parse(atob(hobj));
      if(action === REGISTER_PAYMENT_ACTION.WAIT) {
        yield call(setSessionStorageItem, SESSION_ID, hsid);
        let mappedValues = {'mappedValues': { 'fields': {'ccn': null}}}
        yield put(actions.fetchDsecureAction({onError: {}, mappedValues}));
      }
    }
    if (!sessionId) {
      const {hobj} = yield call(getQueryParams, window.location.search);
      if (hobj) {
        const {hsid} = JSON.parse(atob(hobj));
        yield call(setSessionStorageItem, SESSION_ID, hsid);
      } else {
        const result = yield call(registerApi.getSession, HOBJ_PARAMS_INTERCEPT);
        yield call(setSessionStorageItem, SESSION_ID, result.session_id);
      }

    }
    
    yield put(actions.getSessionSuccessAction());
  } catch (error) {
    logger.error(error);
    yield put(actions.getSessionFailedAction('errorDefault'));
  }
}

export function* getPromoSessionSaga() {
  try {
    const sessionId = yield call(getSessionStorageItem, SESSION_ID);
    if (!sessionId) {
      const { hobj } = yield call(getQueryParams, window.location.search);
      if (hobj) {
        const { hsid } = JSON.parse(atob(hobj));
        yield call(setSessionStorageItem, SESSION_ID, hsid);
      } else {
        const result = yield call(registerApi.getPromoSession, HOBJ_PARAMS_INTERCEPT);
        yield call(setSessionStorageItem, SESSION_ID, result.session_id);
      }
    }
    yield put(actions.getPromoSessionSuccessAction());
  } catch (error) {
    logger.error(error);
    yield put(actions.getPromoSessionFailedAction('errorDefault'));
  }
}

export function* sendRegisterEmailSaga({payload}) {
  const {onError, mappedValues} = payload;
  try {
    const session_id = yield call(getSessionStorageItem, SESSION_ID);
    const values = {...mappedValues, session_id};
    const result = yield call(registerApi.createAccount, values);
    if (result.status === 'success') {
      const {email, agreement} = values.fields;
      const sessionFields = JSON.stringify({email, agreement});
      yield call(storeRegisterInputs, sessionFields);
      yield put(actions.sendRegisterEmailSuccessAction({step: 'verify', action: result.action}));
      yield call(setSessionStorageItem, PAYMENT_METHOD, result.action);
    } else if (result.status === 'error') {
      const isGlobalError = yield call(checkIfGlobalError, result.errors);
      if (!isGlobalError) {
        yield call(onError, result.errors);
      } else {
        yield put(notificationActions.addNotificationAction(prepareRegisterError(result.errors)));
      }
      yield put(actions.sendRegisterEmailFailedAction('create'));
    }
  } catch (error) {
    logger.error(error);
    yield put(actions.sendRegisterEmailFailedAction('create'));
    yield put(notificationActions.addNotificationAction(ERROR_MESSAGES[ERROR_CODES.UNEXPECTED_ERROR]));
  }
}

export function* watchDsecurePolling() {
  while (true) {
    const {payload} = yield take(actions.fetchDsecureAction);
    yield race([
      call(fetch3DSSaga, payload),
      take(actions.stopDsecurePoolingAction),
      take(actions.dsecurePoolingSuccessAction),
      take(actions.dsecurePoolingFailedAction),
    ]);
  }
}

function* registerPaymentCallback({step, redirect}) {
  yield put(actions.sendRegisterPaymentSuccessAction({step, redirect}));
  yield put(actions.dsecurePoolingSuccessAction());
}

function* chooseRegisterAction(result, mappedValues) {
  switch (result.action) {
    case REGISTER_PAYMENT_ACTION.REDIRECT: {
      yield call(removeSessionStorageItem, SESSION_ID);
      yield call(removeSessionStorageItem, PAYMENT_METHOD);
      yield call(removeRegisterInputs);
      yield call(registerPaymentCallback, {step: 'done', redirect: result.url});
      return;
    }
    case REGISTER_PAYMENT_ACTION.DSECURE:
    case REGISTER_PAYMENT_ACTION.DSECURE_BRW:
    case REGISTER_PAYMENT_ACTION.DSECURE_CHALLANGE:
    case REGISTER_PAYMENT_ACTION.DSECURE_DECOUPLED: {
      yield call(init3DSScript, result);
      yield put(actions.fetchDsecureAction({'payload': {onError: {}, mappedValues}}));
      return;
    }
    case REGISTER_PAYMENT_ACTION.DSECURE_RESEND: {
      const session_id = yield call(getSessionStorageItem, SESSION_ID);
      const values = {...mappedValues, session_id};

      if(result.additional_data?.close_old_iframes) {
        yield call(remove3DSIframe);
      }

      yield call(registerApi.verifyAccount, values);
      return;
    }

    case REGISTER_PAYMENT_ACTION.OFFER: {
      yield call(registerPaymentCallback, {step: 'error', redirect: null});
      return;
    }
    case REGISTER_PAYMENT_ACTION.ERROR: {
      yield call(registerPaymentCallback, {step: 'error', redirect: null});
      return;
    }
    default: {
      yield call(registerPaymentCallback, {step: 'errorDefault', redirect: null});
      return;
    }
  }
}

// TODO(K.S) Refactor pooling sagas - remove duplicates etc.
function* fetch3DSSaga(payload) {
  const {onError, mappedValues} = payload;
  const session_id = yield call(getSessionStorageItem, SESSION_ID);
  const data = {
    session_id,
    fields: {
      '3dscheck': true,
      'ccn': mappedValues.fields?.ccn
    }
  };

  while (true) {
    try {
      const result = yield call(registerApi.verifyAccount, data);
      if (result.status === 'error') {
        const isGlobalError = yield call(checkIfGlobalError, result.errors);
        if (!isGlobalError) {
          yield call(onError, result.errors);
        } else {
          yield put(notificationActions.addNotificationAction(prepareRegisterError(result.errors)));
        }
        yield put(actions.dsecurePoolingFailedAction());
        yield put(actions.sendRegisterEmailFailedAction('verify'));
      }

      if (result.action !== REGISTER_PAYMENT_ACTION.WAIT) {
        yield call(remove3DSIframe);
        yield call(chooseRegisterAction, result, mappedValues);
      }
      yield effectDelay(result.additional_data.wait_time * 1000);
    } catch (e) {
      yield put(actions.stopDsecurePoolingAction());
      yield put(actions.dsecurePoolingFailedAction());
    }
  }
}

export function* sendRegisterPaymentSaga({payload}) {
  const {onError, mappedValues} = payload;
  try {
    const session_id = yield call(getSessionStorageItem, SESSION_ID);
    const values = {...mappedValues, session_id};
    const result = yield call(registerApi.verifyAccount, values);
    if (!result.status) {
      if (
        result.action === REGISTER_PAYMENT_ACTION.DSECURE ||
        result.action === REGISTER_PAYMENT_ACTION.DSECURE_BRW ||
        result.action === REGISTER_PAYMENT_ACTION.DSECURE_CHALLANGE ||
        result.action === REGISTER_PAYMENT_ACTION.DSECURE_DECOUPLED
        ) {
          yield call(init3DSScript, result);
          yield effectDelay(result.additional_data.wait_time * 1000);
          yield put(actions.fetchDsecureAction(payload));
        } else if (result.action === REGISTER_PAYMENT_ACTION.DSECURE_RESEND) {
          yield call(registerApi.verifyAccount, values);
        } else if (result.action === REGISTER_PAYMENT_ACTION.DSECURE_QUICKREDIRECT) {
          yield call(redirect3DS, result)
        } else {
        yield call(remove3DSIframe);
        yield call(chooseRegisterAction, result);
      }
    } else if (result.status === 'error') {
      const isGlobalError = yield call(checkIfGlobalError, result.errors);
      if (!isGlobalError) {
        yield call(onError, result.errors);
      } else {
        yield put(notificationActions.addNotificationAction(prepareRegisterError(result.errors)));
      }
      yield put(actions.sendRegisterEmailFailedAction('error'));
    }
  } catch (error) {
    logger.error(error);
    yield put(actions.sendRegisterPaymentFailedAction('error'));
    yield put(notificationActions.addNotificationAction(ERROR_MESSAGES[ERROR_CODES.UNEXPECTED_ERROR]));
  }
}

export function* sendRegisterPaymentSepaSaga({payload}) {
  const {onError, mappedValues} = payload;
  try {
    const session_id = yield call(getSessionStorageItem, SESSION_ID);
    const values = {...mappedValues, session_id};
    const result = yield call(registerApi.verifyAccount, values);
    if (result.action === 'pin') {
      yield put(actions.sendRegisterPaymentSepaSuccessAction({step: 'pin', redirect: null}));
    } else if (result.action === 'wait') {
      const emptyValuesPayload = {...payload, mappedValues: {}};
      yield put(actions.setWaitSepaAction());
      yield effectDelay(result.additional_data.wait_time * 1000);
      yield call(sendRegisterPaymentSepaSaga, {payload: emptyValuesPayload});
    } else if (result.action === 'redirect') {
      if (
        result.action === REGISTER_PAYMENT_ACTION.DSECURE ||
        result.action === REGISTER_PAYMENT_ACTION.DSECURE_BRW ||
        result.action === REGISTER_PAYMENT_ACTION.DSECURE_CHALLANGE
      ) {
        yield call(init3DSScript, result);
        yield put(actions.fetchDsecureAction(payload));
        yield put(actions.setWaitSepaSuccessAction());
      } else {
        yield call(remove3DSIframe);
        yield call(chooseRegisterAction, result);
        yield put(actions.setWaitSepaSuccessAction());
      }
    } else if (result.status === 'error') {
      const isGlobalError = yield call(checkIfGlobalError, result.errors);
      if (result.errors.pin) {
        yield put(actions.sendRegisterPaymentSepaFailedAction());
        yield put(notificationActions.addNotificationAction(ERROR_MESSAGES[ERROR_CODES.GIVEN_TOKEN_IS_INVALID]));
        yield put(notificationActions.addNotificationAction(prepareRegisterError(result.errors)));
        if (result.extra_data.resend) {
          yield put(actions.sendRegisterPaymentSepaPinFailedAction());
        }
      } else if (!isGlobalError) {
        yield call(onError, result.errors);
      } else {
        yield put(notificationActions.addNotificationAction(prepareRegisterError(result.errors)));
      }
      yield put(actions.sendRegisterPaymentSepaFailedAction());
      yield put(actions.setWaitSepaFailedAction());
    }
  } catch (error) {
    logger.error(error);
    yield put(actions.sendRegisterPaymentSepaFailedAction());
    yield put(notificationActions.addNotificationAction(ERROR_MESSAGES[ERROR_CODES.UNEXPECTED_ERROR]));
  }
}

export function* sendChangePaymentMethodSaga({payload}) {
  const {onError, mappedValues} = payload;
  try {
    const session_id = yield call(getSessionStorageItem, SESSION_ID);
    const values = {...mappedValues, session_id};
    const result = yield call(registerApi.updatePaymentMethod, values);
    if (result.status === 'success') {
      yield put(actions.sendChangePaymentMethodSuccessAction(result.action));
      yield call(setSessionStorageItem, PAYMENT_METHOD, result.action);
    } else if (result.status === 'error') {
      const isGlobalError = yield call(checkIfGlobalError, result.errors);
      yield put(actions.sendChangePaymentMethodFailedAction(result.action));
      yield call(setSessionStorageItem, PAYMENT_METHOD, result.action);
      yield put(notificationActions.addNotificationAction(prepareRegisterError(result.errors.pm)));
      if (!isGlobalError) {
        yield call(onError, result.errors);
      } else {
        yield put(notificationActions.addNotificationAction(prepareRegisterError(result.errors)));
      }
    }
  } catch (error) {
    logger.error(error);
  }
}

export default function* watchForRegister() {
  yield all([
    yield takeLatest(actions.getSessionAction, getSessionSaga),
    yield takeLatest(actions.getPromoSessionAction, getPromoSessionSaga),
    yield takeLatest(actions.sendRegisterEmailAction, sendRegisterEmailSaga),
    yield takeLatest(actions.sendRegisterPaymentAction, sendRegisterPaymentSaga),
    yield takeLatest(actions.sendRegisterPaymentSepaAction, sendRegisterPaymentSepaSaga),
    yield takeLatest(actions.sendChangePaymentMethodAction, sendChangePaymentMethodSaga),
  ]);
}