import Default from './types/Default';
import * as types from './types';

interface LoadingProps {
  color?: string;
  color2?: string;
  type?: 'default' | 'dots' | 'ring';
  size?: 'small' | 'medium' | 'large';
  centered?: boolean;
  pixelSize?: string;
  borderWidth?: string;
}

interface ILoadingTypes {
  type: string;
  component: any;
}

function Loading(props: LoadingProps) {
  const {type = 'default', size = 'medium', color = 'grey', centered = false, ...rest} = props;
  const loadingTypes: Array<ILoadingTypes> = [
    {type: 'default', component: types.Default},
    {type: 'dots', component: types.Dots},
    {type: 'ring', component: types.Ring},
  ];

  const Component = (loadingTypes && loadingTypes.find((item) => item.type === type)!.component) || Default;
  return <Component color={color} size={size} centered={centered} {...rest} />;
}

export default Loading;
