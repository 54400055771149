import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    background: ${(props) => props.theme.colors.background};
  }
  body{
    font-family: ${(props) => props.theme.fontFamily.main};
  }
  p, span {
    color: ${(props) => props.theme.others.textColor};
  }
  button, a, ul, li, p, span, label, text {
    color: ${(props) => props.theme.others.textColor};
  }
  div, span, applet, object, iframe, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video  {
    font-family: ${(props) => props.theme.fontFamily.main};
  }
`;
export default GlobalStyle;
