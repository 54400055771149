import {applyMiddleware, compose, createStore as createReduxStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {fromJS} from 'immutable';
import {__DEV__} from '@/app/config';
import createRootReducer from './reducers';
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';
import rootSaga from '@/app/sagas';
import {watchDsecurePolling} from '@/app/sagas/register';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const createStore = (initialState = {}) => {
  // ======================================================
  // Middleware Configuration
  const middlewares = [routerMiddleware(history), sagaMiddleware];

  // ======================================================
  // Store Enhancers
  const enhancers = [];
  let composeEnhancers = compose;

  if (__DEV__) {
    if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    }
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  const store = createReduxStore(
    createRootReducer(history)(),
    fromJS(initialState),
    composeEnhancers(applyMiddleware(...middlewares), ...enhancers)
  );

  sagaMiddleware.run(watchDsecurePolling);

  // Extensions
  // To unsubscribe, invoke `store.unsubscribeHistory()` anytime
  // store.unsubscribeHistory = browserHistory.listen(updateLocation(store));
  store.history = history;
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  store.runSaga(rootSaga);

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createRootReducer(history)(store.injectedReducers));
    });
  }

  return store;
};

export default createStore;
