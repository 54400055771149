import {gql} from '@apollo/client';

export const updateCredentialsMutation = gql`
  mutation updateCredentials($input: UserSetCredentialsInput!) {
    userSetCredentials(input: $input) {
      success
      errors {
        code
        msg
      }
    }
  }
`;
