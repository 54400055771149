import CONFIG from '@/app/config';
import txt from '@/product/translations/MainPage/title';
import {ROUTES} from '@/app/Routes';

export const getTitle = (title) => {
  return title === ROUTES.MAINPAGE.title ? txt.title : title;
};

export const getPageTitle = ({t, title, separator = '-'}) => {
  return title === ROUTES.PLAYER.title ? `${CONFIG.title}` : `${CONFIG.title} ${separator} ${t(getTitle(title))}`;
};
