export const setCookie = ({name = null, value = null, expires = 'Session'}) => {
  if (!name.trim() || !value) {
    // eslint-disable-next-line no-console
    console.warn('Provided value or name for cookies cannot be null!');
    return;
  }
  document.cookie = `${name}=${value};expires=${expires}`;
};

export const deleteCookie = (name, domain) => {
  if (!name.trim()) {
    // eslint-disable-next-line no-console
    console.warn('You have to provide name for cookie to delete!');
    return;
  }
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;${domain ? `domain=${domain}` : ''}`;
};

export const getCookie = (cookieName) => {
  if (!cookieName.trim()) {
    // eslint-disable-next-line no-console
    console.warn('You have to provide name for cookie to delete!');
    return;
  }
  const name = cookieName + '=';
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const checkIfCookieExists = (name) => {
  if (!name.trim()) {
    // eslint-disable-next-line no-console
    console.warn('You have to provide name for cookie to delete!');
    return;
  }
  const cookieExists = getCookie(name) !== '';
  return cookieExists;
};
