/**
 * ~ IMPORTANT !!! ~
 * This file should always be mirror of /info response object
 * Check api documentation if something is different
 */
const info = {
  domain: '',
  service_name: '',
  support_email: '',
  support_phones: [
    {
      country: '',
      support_phone: '',
      working_hours: '',
    },
  ],
  support_skype: null,
  subscription_plans: [
    {
      name: '',
      price: '',
      active: true,
      trial_days: 7,
      duration: 1,
      duration_unit: '',
      currency: '',
      currency_symbol: '',
    },
  ],
  entities: [
    {
      name: '',
      address: '',
      city: '',
      country: '',
      additional_data: {
        reg_address: '',
        reg_nr: '',
        headquarters_email: '',
        state: '',
        cep: '',
        cnpj: '',
      },
    },
  ],
  impressum: [],
  country: '',
  europe: true,
  additional_data: {
    desc_name: '',
    desc_phone: '',
  },
};

export default info;
