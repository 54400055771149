import styled from 'styled-components';
import {media, toCss} from '../../utils';
import IParagraphTheme, {ParagraphType} from './IParagraphTheme';

const paragraphStyles = (props: IParagraphStyled) => {
  const {componentTheme, pType} = props;
  const {GRID_BREAKPOINTS, types} = componentTheme;

  return Object.keys((types as any)[pType])
    .filter((item: string) => !Object.keys(GRID_BREAKPOINTS).includes(item))
    .map((item) => mapRules((types as any)[pType][item], item))
    .join(' ');
};

const mapRules = (props: ParagraphType, property: string) => {
  return props ? `${toCss(property)}: ${props};` : '';
};

const mapProperties = (properties: any) =>
  Object.keys(properties)
    .map((property: any) => mapRules(properties[property], property))
    .join(' ');

const paragraphStylesRwd = (props: IParagraph) => {
  const {componentTheme, pType} = props;
  const {GRID_BREAKPOINTS, types} = componentTheme;

  return Object.keys(GRID_BREAKPOINTS)
    .filter((breakpoint) => (types as any)[pType][breakpoint])
    .map((breakpoint) => {
      const properties = (types as any)[pType][breakpoint];
      return media(GRID_BREAKPOINTS)[breakpoint](`
        ${mapProperties(properties)}
      `);
    });
};

interface IParagraphStyled {
  pType: string;
  componentTheme: IParagraphTheme;
}

const ParagraphStyled = styled.p<IParagraphStyled>`
  ${(props) => paragraphStyles(props)}
  ${(props) => paragraphStylesRwd(props)}
`;

interface ParagraphProps {
  pType: string;
  componentTheme: IParagraphTheme;
}
function Paragraph(props: ParagraphProps) {
  const {componentTheme} = props;
  return <ParagraphStyled {...props} componentTheme={componentTheme} />;
}

Paragraph.defaultProps = {
  pType: 'p1',
};

export default Paragraph;
