import {Component} from 'react';
import {HeaderStyled, Wrapper} from './Header.styled';
import IHeaderTheme from './IHeaderTheme';

interface IHeaderProps {
  hasBackground: boolean;
  componentTheme: IHeaderTheme;
}
interface IHeaderState {
  sticky: boolean;
}

class Header extends Component<IHeaderProps, IHeaderState> {
  state = {
    sticky: false,
  };

  static defaultProps = {
    hasBackground: false,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, {passive: true});
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const sticky = window.pageYOffset > 0;
    this.setState({sticky});
  };

  render() {
    const {hasBackground, children, componentTheme, ...rest} = this.props;

    return (
      <HeaderStyled sticky={this.state.sticky} hasBackground={hasBackground} componentTheme={componentTheme} {...rest}>
        <Wrapper>{children}</Wrapper>
      </HeaderStyled>
    );
  }
}

export default Header;
