import {ReactComponent as SettingsIcon} from '@/app/assets/svg/icon_setting.svg';
import {ReactComponent as LogOutIcon} from '@/app/assets/svg/icon_log_out.svg';
import {ROUTES} from '@/app//Routes';
import {DropDown} from './UserMenu.styled';
import {ReactComponent as ProfileIcon} from '@/app/assets/svg/icon_profile.svg';

const UserMenu = ({t, onLogout, className}) => {
  const userList = [
    {
      type: 'Link',
      name: t('Settings'),
      icon: SettingsIcon,
      to: ROUTES.ACCOUNT.path,
    },
    {type: 'Action', name: t('Logout'), icon: LogOutIcon, callback: onLogout},
  ];

  return (
    <DropDown
      className={className}
      buttonText={t('My account')}
      itemsList={userList}
      icon={ProfileIcon}
      closeAfterClick
      buttonColor='light'
      buttonColorHover='whathere?'
      mainIconOptions={{
        width: 16,
        height: 16,
      }}
      mainIconListOptions={{
        width: 16,
        height: 16,
      }}
    />
  );
};

export default UserMenu;
