export class InvalidPasswordError extends Error {
  public static ERR_CODE = 202;
  public static ERR_MESSAGE = 'Current password is invalid';

  constructor() {
    super('Current password is invalid. Maybe password is the same as current one?');
    this.name = InvalidPasswordError.name;
    if (Object.hasOwnProperty.call(Error, 'captureStackTrace')) Error.captureStackTrace(this);
  }
}
