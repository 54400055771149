import {createSelector} from 'reselect';

const selectors = {
  selectState: (state) => state.get('genres'),
  isLoading: () => createSelector(selectors.selectState, (genres) => genres.get('isLoading')),
  isError: () => createSelector(selectors.selectState, (genres) => genres.get('isError')),
  getGenres: () => createSelector(selectors.selectState, (genres) => genres.get('genres')),
};

export default selectors;
