import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {createStructuredSelector} from 'reselect';
import {actions, selectors} from '@/app/reducers/notification';
import {toJS} from '@/app/utils/immutable/toJS';
import Notifications from './Notifications';
import {withTranslators} from '@/app/lib/lioness';

const mapStateToProps = createStructuredSelector({
  notifications: selectors.getNotifications(),
});

const mapDispatchToProps = (dispatch) => ({
  removeNotification: bindActionCreators(actions.removeNotificationAction, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withTranslators)(toJS(Notifications));
