import {DropDownListLiStyled, DropDownListStyled} from './DropDownList.styled';
import {DropDownListItem, IListItem} from './DropDownListItem';
import IDropDownTheme from './IDropDownTheme';

export interface DropDownListProps {
  itemsList: IListItem[];
  visible: boolean;
  handleClose: Function;
  mainIconListOptions?: any;
  componentTheme: IDropDownTheme;
}

export function DropDownList(props: DropDownListProps) {
  const {itemsList,
    visible,
    handleClose,
    mainIconListOptions,
    componentTheme} = props;
  return (
    <DropDownListStyled visible={visible} componentTheme={componentTheme}>
      {itemsList &&
        itemsList.map((item) => {
          return (
            <DropDownListLiStyled key={item.name} componentTheme={componentTheme}>
              <DropDownListItem
                item={item}
                handleClose={handleClose}
                mainIconListOptions={mainIconListOptions}
                componentTheme={componentTheme}
              />
            </DropDownListLiStyled>
          );
        })}
    </DropDownListStyled>
  );
}