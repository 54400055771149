import {InputStyled} from './Input.styled';
import IInputTheme from './IInputTheme';

interface InputProps {
  name: string;
  type: string;
  error?: string | boolean;
  touched?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  componentTheme: IInputTheme;
}

function Input(props: InputProps) {
  const {placeholder, name, type, error, touched, disabled, readOnly, componentTheme, ...rest} = props;
  return (
    <InputStyled
      type={type}
      id={name}
      name={name}
      placeholder={placeholder}
      error={!!error && touched}
      touched={touched}
      componentTheme={componentTheme}
      {...rest}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
}

export default Input;
