import {ApolloError, FetchResult} from '@apollo/client';
import type {ProductSDK} from '../../../ProductSDK';
import {requireDefined} from '../../../utils/nullability-utils';
import {Operation} from '../../../models/Operation';
import {requireSuccessfulOperation} from '../../../utils/operations-utils';
import {UnauthorizedError} from '../../../exceptions/UnauthorizedError';
import {logoutMutation} from '../../../graphql/mutation/logout';

type Results = FetchResult<{logout: Operation<unknown>}>;

/**
 * @throws UnauthorizedError
 * @throws OperationFailError
 */
export async function logout(this: ProductSDK): Promise<void> {
  try {
    const variables: {input: Record<never, unknown>} = {input: {}};

    const response: Results = await this.client.mutate({
      mutation: logoutMutation,
      variables: variables,
    });

    const data = requireDefined(response.data?.logout);
    requireSuccessfulOperation(data);
  } catch (e: unknown) {
    if (e instanceof ApolloError) {
      if (e.graphQLErrors.length > 0) {
        switch (e.graphQLErrors[0].message) {
          case UnauthorizedError.ERR_MESSAGE:
            throw new UnauthorizedError(e.message);
        }
      }
    }

    throw e;
  }
}
