/* eslint-disable no-console */
/*
 * Logger Util
 *
 * init:
 * import Logger from './utils/logger'
 * Logger.init(env)
 *
 * usage:
 * var Logger = require('js-logger');
 * var logger = jsLogger.get('named-logger');
 * logger.info("123")
 *
 * time metrcis:
 * logger.time();
 * ...
 * logger.timeEnd();
 *
 */
import CONFIG from '@/app/config';

const jsLogger = require('js-logger');

export class Logger {
  config;

  constructor() {
    this.config = CONFIG.logs;
    this.initDefaultLogs();
  }

  getInstance = (name) => {
    const instance = jsLogger.get(name);
    // instance.apiLog = this.apiLog;
    return instance;
  };

  initDefaultLogs = () => {
    const logLevel = this.config.browser ? jsLogger.DEBUG : jsLogger.INFO;
    jsLogger.useDefaults({
      formatter: function (messages, context) {
        messages.unshift(`${context.name} -`);
        messages.unshift(new Date().toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, `[$1]`));
      },
    });
    jsLogger.setLevel(logLevel);
  };
}

export default new Logger();
