export const REGISTER_PAYMENT_ACTION = {
  CHECK: 'check', //check effort status screen
  CONFIRMATION: 'confirmation', //only confirmation registration (like checkbox or just button to confirm agreement)
  CONTENT_SELECTION: 'content_selection', //only confirmation registration (like checkbox or just button to confirm agreement)
  DSECURE: '3dsecure', //same like check but with 3dsecure iframe
  DSECURE_BRW: '3ds_brw',
  DSECURE_CHALLANGE: '3ds_challange',
  DSECURE_DECOUPLED: '3ds_decoupled',
  DSECURE_RESEND: 'resend_paycard',
  DSECURE_QUICKREDIRECT: 'quick_redirect',
  ERROR: 'error', //show error page
  IFRAME: 'iframe', //show content of redirect_url param in an iframe
  MSISDN: 'msisdn', //msisdn input step
  OFFER: 'offer', //sales verification drop
  OPERATORS: 'operators', //show operators selection screen (see example dvg_br)
  OPSDN: 'opsdn', //msisdn and operators steps combined (not continued anymore for DVG_BR)
  PAID: 'paid', //paid
  PAYCARD: 'paycard', //credit card input details step (this step varias in fields needed to input see daftpay )
  PAYMENT_SWITCH: 'payment_switch', //payment_switch screen (see example echovox_be)
  PIN: 'pin',
  REDIRECT: 'redirect', //redirect to ULR under redirect_url param
  REGISTRATION: 'registration', //email+pass / email only -> depends on the flow
  RETRY: 'retry', //Not used at the moment (old pre EVOX virgo_fr flow)
  RETRY_WARNING: 'retry_warning', //Not used at the moment (old pre EVOX virgo_fr flow)
  SMS_CODE: 'sms_code', //sms send page
  SESSION_CHECK: 'session_check',
  START: 'start',
  SUCCESS: 'success',
  TOKEN: 'token', //show token input screen (see example dvg_br)
  WAIT: 'wait', //more of a directive to wait on check_effort_status screen for specified nr of second before next check call
};

export const HOBJ_PARAMS_INTERCEPT = '?force_api_params=';

export const registerSteps = {
  create: 'create',
  verify: 'verify',
  pin: 'pin',
  done: 'done',
  error: 'error',
  errorDefault: 'errorDefault',
};
