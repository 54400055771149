import styled from 'styled-components';
import {media} from '../../../utils';

export const ContainerWrapper = styled.div<{theme: any}>`
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
  /* col-md */
  ${(props) =>
    props &&
    props.theme &&
    media(props.theme.GRID_BREAKPOINTS).xs(`
      max-width: none;
  `)}

  /* col-md */
  ${(props) =>
    props &&
    props.theme &&
    media(props.theme.GRID_BREAKPOINTS).sm(`
      max-width: ${props.theme.CONTAINER_MAX_WIDTHS.sm}px
  `)}

  /* col-md */
  ${(props) =>
    props &&
    props.theme &&
    media(props.theme.GRID_BREAKPOINTS).md(`
      padding-right: 15px;
      padding-left: 15px;
      max-width: ${props.theme.CONTAINER_MAX_WIDTHS.md}px;
  `)}

  /* col-md */
  ${(props) =>
    props &&
    props.theme &&
    media(props.theme.GRID_BREAKPOINTS).lg(`
      max-width: ${props.theme.CONTAINER_MAX_WIDTHS.lg}px;
  `)}

  /* col-md */
  ${(props) =>
    props &&
    props.theme &&
    media(props.theme.GRID_BREAKPOINTS).xl(`
      max-width: ${props.theme.CONTAINER_MAX_WIDTHS.xl}px;
  `)}
`;
