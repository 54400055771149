import { lazy, memo, Suspense } from "react";
import {Route, Switch} from 'react-router-dom';
import {ErrorBoundary, PrivateRoute, UnAuthRoute} from '@/app/ui';
import {getPageTitle} from '@/app/utils/pageTitle';
import withGdprContext from '@/app/ui/HOC/withGdprContext';
import {withTranslators} from '@/app/lib/lioness';
import {ACCESS_TYPE, ROUTES, ROUTES_GDPR} from './Routes';
import ViewWrapper from './components/View';
import LoadingView from './components/LoadingView';

const renderRouting = (route) => {
  const {path, access, view, title, exact, fallback} = route;
  const ViewComponent = lazy(() =>
    import(/* webpackChunkName: "view-[request]", webpackExclude: /\/__tests__\// */ `./view/${view}`)
  );

  const View = withTranslators((props) => {
    const {t} = props;

    return (
      <ErrorBoundary>
        <ViewWrapper title={getPageTitle({t, title})}>
          <ViewComponent {...props} />
        </ViewWrapper>
      </ErrorBoundary>
    );
  });

  switch (access) {
    case ACCESS_TYPE.AUTH:
      return <PrivateRoute key={view} path={path} component={View} />;
    case ACCESS_TYPE.UN_AUTH:
      return (
        <UnAuthRoute
          key={view}
          path={path}
          fallback={fallback || ROUTES.DISCOVER.path}
          component={View}
          exact={exact}
        />
      );
    default: {
      return <Route key={view} path={path} exact={exact} render={() => <View />} />;
    }
  }
};

const Routing = (props) => {
  const {europe} = props;
  const routes = europe ? ROUTES_GDPR : ROUTES;

  return (
    <Suspense fallback={<LoadingView />}>
      <Switch>{Object.keys(routes).map((route) => renderRouting(routes[route]))}</Switch>
    </Suspense>
  );
};

export default withGdprContext(memo(Routing));
