import {checkIfCookieExists, getCookie, setCookie} from '@/app/modules/cookies';
import {readLocalesLang, saveLocalesLang} from '../../modules/localStorage';
import {I18N_LANGUAGES, LANGUAGES} from '@/app/constants/languages';
import {getQueryParams} from '@/app/utils/query';

export const checkLangParam = () => {
  const {lang} = getQueryParams();
  const isAvailable = lang && Object.values(LANGUAGES).indexOf(lang.toLowerCase()) !== -1;
  // display br lang as pt
  const mappedLang = lang === 'br' ? 'pt' : lang;
  return isAvailable ? mappedLang : false;
};

export const getLang = () => {
  // get lang from params ex. ?lang=es
  const DEFAULT_LANG = 'en';
  const langParam = checkLangParam();
  if (langParam) return langParam;

  if (!checkIfCookieExists('lang')) {
    //get lang from browser
    const browserLang = navigator.language || navigator.userLanguage;
    const lang = readLocalesLang() ?? browserLang.split('-')[0];
    const acceptedLang = Object.values(I18N_LANGUAGES).find((value) => value === lang);
    return acceptedLang ? acceptedLang : DEFAULT_LANG;
  }
  //get lang from cookies
  return getCookie('lang') || DEFAULT_LANG;
};

export const setLang = (lang) => {
  const date = new Date(Date.now() + 12096e5);
  getCookie('cookies_agreement') && setCookie({name: 'lang', value: lang, expires: date});
  saveLocalesLang(lang);
};
