import { Component } from 'react';
import {checkIfCookieExists, setCookie} from '@/app/modules/cookies';
import {ROUTES_GDPR} from '@/app/Routes';
import {CookiesStyled, Link, Paragraph} from './Cookies.styled';
import FakeChat from '../FakeChat';
import {getConfig} from '@/app/utils/general';

const date = new Date(Date.now() + 3600000 * 24 * 14);
const cookie = { name: 'cookies_agreement', value: true, expiry: date };
const cookieLiveAgent2Week = { name: 'la_cookie-accept', value: true, expiry: date };
const { hasFakeChat } = getConfig();

class Cookies extends Component {
  state = {
    isOpen: !checkIfCookieExists(cookie.name) && this.props.europe,
    isChatOpen: false,
    chatStarted: !hasFakeChat,
    isFakeChatOpen: false,
  };

  componentDidMount() {
    this.checkForCookie();
  }

  checkForCookie = () => {
    const cookieExists = !checkIfCookieExists(cookie.name);
    if (this.state.isOpen !== cookieExists) {
      this.setState({isOpen: cookieExists});
    }
  };

  handleAgreement = () => {
    setCookie(cookie);
    setCookie(cookieLiveAgent2Week);
    this.handleClose();
  };

  handleClose = () => {
    if (this.state.isOpen) {
      this.setState({isOpen: false});
    }
  };

  onToggleChat = (isOpen) => {
    this.setState({isChatOpen: isOpen});
  };
  onToggleFakeChat = isOpen => {
    this.setState({ isFakeChatOpen: isOpen });
  };
  onStartChat = started => {
    this.setState({ chatStarted: started, isChatOpen: true });
  };

  render() {
    const {t, europe} = this.props;

    return [
      europe && this.state.isOpen && (<CookiesStyled
        buttonText={t('I accept cookies')}
        onClick={this.handleAgreement}
        onClose={this.handleClose}
        ariaLabelClose={t('Close')}
        hasFakeChat={hasFakeChat}
        isChatOpen={this.state.chatStarted ? this.state.isChatOpen : this.state.isFakeChatOpen}
        key='cookies'
      >
        <Paragraph pType='p3'>
          {t(
            'We use cookies to ensure that you have the best experience on our website. We also use cookies for marketing purposes and to get feedback from you about our services. By clicking "I accept cookies" you agree to receive all the cookies. To learn about the use of cookies and the protection of cookies, '
          )}
          <Link url={ROUTES_GDPR.COOKIES.path} type='anchor' target='_blank'>
            {t('click here')}
          </Link>
        </Paragraph>
      </CookiesStyled>),
      <FakeChat
        hasFakeChat={!this.state.isOpen || !europe ? false : hasFakeChat}
        cookiesAccepted={checkIfCookieExists(cookie.name)}
        onToggleChat={this.onToggleChat}
        onStartChat={this.onStartChat}
        onToggleFakeChat={this.onToggleFakeChat}
        key='fake-chat'
        test={this.props.test}
      />,
    ];
  }
}

export default Cookies;
