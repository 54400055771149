import {prepareActions} from '@/app/utils/redux';

export const actions = prepareActions(
  {},
  ['getMoviesToWatch', true, true],
  ['watchLater', true, true],
  ['unwatch', true, true]
);

export default actions;
