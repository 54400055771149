import {FetchResult} from '@apollo/client';
import type {ProductSDK} from '../../../ProductSDK';
import {requireDefined} from '../../../utils/nullability-utils';
import {CategoriesListConnection} from './models/CategoriesListConnection';
import {CategoriesListOptions} from './models/CategoriesListOptions';
import {getCategoriesListQuery} from '../../../graphql/query/getCategoriesList';

export async function getCategoriesList(
  this: ProductSDK,
  options: CategoriesListOptions = {}
): Promise<CategoriesListConnection> {
  const response: FetchResult<{categories: CategoriesListConnection}> = await this.client.query({
    query: getCategoriesListQuery,
    variables: options,
  });

  return requireDefined(response.data?.categories);
}
