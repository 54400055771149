import {fromJS} from 'immutable';
import {prepareReducers} from '@/app/utils/redux';
import actions from './actions';

export const initialState = fromJS({
  isLoading: false,
  showUpdateCredentials: false,
  data: null, //TODO: Inspect usage - Now it's `User`
});

const reducer = prepareReducers(
  {
    [actions.loginAction]: (state) => state.set('isLoading', true),
    [actions.loginSuccessAction]: (state, {payload}) => state.set('isLoading', false).set('data', payload),
    [actions.loginFailedAction]: (state) => state.set('isLoading', false),
    [actions.logoutSuccessAction]: (state) => state.set('data', null),
    [actions.cancelAccountAction]: (state) => state.set('isLoading', true),
    [actions.cancelAccountSuccessAction]: (state) => state.set('isLoading', false),
    [actions.cancelAccountFailedAction]: (state) => state.set('isLoading', false),

    [actions.updatePasswordAction]: (state) => state.set('isLoading', true),
    [actions.updatePasswordSuccessAction]: (state) => state.set('isLoading', false),
    [actions.updatePasswordFailedAction]: (state) => state.set('isLoading', false),

    [actions.newPasswordAction]: (state) => state.set('isLoading', true),
    [actions.newPasswordSuccessAction]: (state) => state.set('isLoading', false),
    [actions.newPasswordFailedAction]: (state) => state.set('isLoading', false),

    [actions.newPasswordEmailAction]: (state) => state.set('isLoading', true),
    [actions.newPasswordEmailSuccessAction]: (state) => state.set('isLoading', false),
    [actions.newPasswordEmailFailedAction]: (state) => state.set('isLoading', false),

    [actions.updateCredentialsAction]: (state) => state.set('isLoading', true),
    [actions.updateCredentialsSuccessAction]: (state) => state.set('isLoading', false),
    [actions.updateCredentialsFailedAction]: (state) => state.set('isLoading', false),

    [actions.showUpdateCredentialsAction]: (state) => state.set('showUpdateCredentials', true),
    [actions.hideUpdateCredentialsAction]: (state) => state.set('showUpdateCredentials', false),
  },
  initialState
);

export default reducer;
