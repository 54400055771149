import {FetchResult} from '@apollo/client';
import type {ProductSDK} from '../../../ProductSDK';
import {requireDefined} from '../../../utils/nullability-utils';
import {getDomainInfoQuery} from '../../../graphql/query/getDomainInfo';
import {DomainInfo} from './models/DomainInfo';

export async function getDomainInfo(this: ProductSDK): Promise<DomainInfo> {
  const response: FetchResult<{domainInfo: DomainInfo}> = await this.client.query({
    query: getDomainInfoQuery,
  });

  return requireDefined(response.data?.domainInfo);
}
