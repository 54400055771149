import {gql} from '@apollo/client';

export const updatePasswordMutation = gql`
  mutation updatePassword($input: UpdateUserInput!) {
    updateUser(input: $input) {
      success
      errors {
        code
        msg
      }
    }
  }
`;
