import {SessionTokens} from '@dev7/altar-product-sdk';

export function doesSessionTokensExpired(sessionTokens: SessionTokens): boolean {
  const expiry = parseInt(sessionTokens.expiry);

  if (isNaN(expiry))
    throw new Error('Invalid sessions tokens `expiry` provided. Expected valid unix epoch timestamp.');

  return (expiry * 1000) < (new Date().getTime() * 1000);
}
