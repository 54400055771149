import styled, {css, keyframes} from 'styled-components';
import Paragraph from '../../Paragraph';
import INotificationsTheme from './../INotificationsTheme';

type type = 'danger' | 'info' | 'warning' | 'success';

const notificationLabel = (type: type, componentTheme: INotificationsTheme) => ({
  background: (componentTheme as any)[`${type}Background`],
  'border-top-left-radius': '5px',
  'border-bottom-left-radius': '5px',
});

const slideInLeft = keyframes`
  from { transform: translateX(100%); }
  to { transform: translateX(0); }
`;

const slideOutRight = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(100%); }
`;

interface INotificationItemStyled {
  componentTheme: INotificationsTheme;
  close: boolean;
  type: type;
}

export const NotificationItemStyled = styled.div<INotificationItemStyled>`
  min-width: 215px;
  min-height: 64px;
  position: relative;
  margin: 8px 0;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  ${({type, componentTheme}) => notificationLabel(type, componentTheme)};
  ${({close}) =>
    !close &&
    css`
      animation: ${slideInLeft} 0.5s ease;
    `};

  ${({close}) =>
    close &&
    css`
      animation: ${slideOutRight} 0.5s ease forwards;
    `};
`;

export const Message = styled(Paragraph)``;
