import {fromJS} from 'immutable';
import {prepareReducers} from '@/app/utils/redux';
import actions from './actions';

export const initialState = fromJS({
  isLoading: false,
  data: null,
});

const reducer = prepareReducers(
  {
    [actions.getSessionInfoAction]: (state) => state.set('isLoading', true),
    [actions.getSessionInfoSuccessAction]: (state, {payload}) => state.set('isLoading', false).set('data', payload),
    [actions.getSessionInfoFailedAction]: (state) => state.set('isLoading', false),
  },
  initialState
);

export default reducer;
