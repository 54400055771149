import {ApolloError, FetchResult} from '@apollo/client';
import type {ProductSDK} from '../../../ProductSDK';
import {requireDefined} from '../../../utils/nullability-utils';
import {Operation} from '../../../models/Operation';
import {requireSuccessfulOperation} from '../../../utils/operations-utils';
import {UpdateUserInput} from './models/UpdateUserInput';
import {PasswordDataset} from './models/PasswordDataset';
import {updatePasswordMutation} from '../../../graphql/mutation/updatePassword';
import {UnauthorizedError} from '../../../exceptions/UnauthorizedError';
import {OperationFailError} from '../../../exceptions/OperationFailError';
import {InvalidPasswordError} from './exceptions/InvalidPasswordError';

type Results = FetchResult<{updateUser: Operation<unknown>}>;

/**
 * Update user attributes with current password.
 * @throws UnauthorizedError
 * @throws OperationFailError
 */
export async function updatePassword(this: ProductSDK, dataset: PasswordDataset): Promise<void> {
  try {
    const variables: {input: UpdateUserInput} = {
      input: {user: dataset},
    };

    const response: Results = await this.client.mutate({
      mutation: updatePasswordMutation,
      variables: variables,
    });

    const data = requireDefined(response.data?.updateUser);
    requireSuccessfulOperation(data);
  } catch (e: unknown) {
    if (e instanceof ApolloError) {
      if (e.graphQLErrors.length > 0) {
        switch (e.graphQLErrors[0].message) {
          case UnauthorizedError.ERR_MESSAGE:
            throw new UnauthorizedError(e.message);
        }
      }
    }
    if (e instanceof OperationFailError && e.getErrors().length > 0) {
      switch (e.getErrors()[0].code) {
        case InvalidPasswordError.ERR_CODE:
          throw new InvalidPasswordError();
      }
    }

    throw e;
  }
}
