import styled from 'styled-components';
import IScrollTopTheme from './IScrollTopTheme';

export const ScrollTopStyled = styled.button<{componentTheme: IScrollTopTheme}>`
  cursor: pointer;
  background: ${({componentTheme}) => componentTheme && componentTheme.background};
  ${({componentTheme}) =>
    componentTheme && componentTheme.border ? `border: ${componentTheme.border};` : `border: none;`}
  ${({componentTheme}) =>
    componentTheme && componentTheme.borderRadius ? `border-radius: ${componentTheme.borderRadius};` : ``}
`;
