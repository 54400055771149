import {CookiesBox, CookiesButton, CookiesClose} from './Cookies.styled';
import ICookiesTheme from './ICookiesTheme';
import {ButtonColors} from '../Button/Button';

interface CookiesProps {
  buttonText: string;
  onClick: Function;
  onClose: Function;
  ariaLabelClose?: string;
  componentTheme: ICookiesTheme;
  btnColor?: ButtonColors;
}

function Cookies(props: CookiesProps) {
  const {children, onClose, ariaLabelClose, onClick, buttonText, btnColor = 'brand', componentTheme, ...rest} = props;
  return (
    <CookiesBox componentTheme={componentTheme} {...rest}>
      <CookiesClose ariaLabelClose={ariaLabelClose} onClick={onClose} componentTheme={componentTheme} />
      {children}
      <CookiesButton size='small' onClick={onClick} color={btnColor} componentTheme={componentTheme}>
        {buttonText}
      </CookiesButton>
    </CookiesBox>
  );
}

export default Cookies;
