import {createSelector} from 'reselect';

const selectors = {
  selectState: (state) => state.get('movies'),
  isLoading: () => createSelector(selectors.selectState, (movies) => movies.get('isLoading')),
  getBrowseMovies: () => createSelector(selectors.selectState, (movies) => movies.get('browseMovies')),
  getBrowseTotal: () => createSelector(selectors.selectState, (movies) => movies.get('browseTotal')),
  getBrowsePagination: () => createSelector(selectors.selectState, (movies) => movies.get('browsePagination')),
  getSearchMovies: () => createSelector(selectors.selectState, (movies) => movies.get('searchMovies')),
  getSearchTotal: () => createSelector(selectors.selectState, (movies) => movies.get('searchTotal')),
  getRecentMovies: () => createSelector(selectors.selectState, (movies) => movies.get('recentMovies')),
  getRecentTotal: () => createSelector(selectors.selectState, (movies) => movies.get('recentTotal')),
  getStaffPickMovies: () => createSelector(selectors.selectState, (movies) => movies.get('staffPickMovies')),
  getStaffPickTotal: () => createSelector(selectors.selectState, (movies) => movies.get('staffPickTotal')),
  //TODO: add to 'watchLater' list
  //TODO: remove from 'unwatch' list
  //TODO: buyMovies
};

export default selectors;
