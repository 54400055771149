import {gql} from '@apollo/client';

export const loginMutation = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      tokens {
        accessToken
        refreshToken
        expiry
      }
      success
      errors {
        code
        msg
      }
    }
  }
`;
