import {all, call, put, takeLatest} from 'redux-saga/effects';
import {actions as promoMoviesActions} from '@/app/reducers/promoMovies';
import {actions as notificationActions} from '@/app/reducers/notification';
import Logger from '@/app/modules/logger';
import {prepareError} from '@/app/utils/sagas';
import {getCategoriesList, getContentsList} from '@dev7/altar-product-sdk';
import {productSDK} from '../modules/productSDK';

const logger = Logger.getInstance('PromoMoviesSaga');
const PROMO_CONTENT_CATEGORY_NAME = 'Promo content';

export function* getPromoMoviesSaga() {
  try {
    const categoriesResults = yield call(productSDK.call, getCategoriesList);
    const promoContentCategory = categoriesResults.nodes.find(
      (category) => category.name === PROMO_CONTENT_CATEGORY_NAME
    );

    if (!promoContentCategory) throw new Error(`Category named \`${PROMO_CONTENT_CATEGORY_NAME}\` was not found.`);

    const result = yield call(productSDK.call, getContentsList, {first: 5, categories: [promoContentCategory.id]});
    yield put(
      promoMoviesActions.getPromoMoviesSuccessAction({
        movies: result.nodes,
        total: result.totalCount,
      })
    );
  } catch (error) {
    yield put(promoMoviesActions.getPromoMoviesFailedAction());
    logger.error(error);
    yield put(notificationActions.addNotificationAction(prepareError(error)));
  }
}

export default function* watchForPromoMovies() {
  yield all([takeLatest(promoMoviesActions.getPromoMoviesAction, getPromoMoviesSaga)]);
}
