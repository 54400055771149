const AuthToken = 'authToken';
const Inputs = 'inputs';

export function storeAuthToken(authToken) {
  sessionStorage.setItem(AuthToken, authToken);
}

export function getAuthToken() {
  return sessionStorage.getItem(AuthToken);
}

export function removeAuthToken() {
  sessionStorage.removeItem(AuthToken);
}

export function storeRegisterInputs(fields) {
  sessionStorage.setItem(Inputs, fields);
}

export function getRegisterInputs() {
  return sessionStorage.getItem(Inputs);
}

export function removeRegisterInputs() {
  return sessionStorage.removeItem(Inputs);
}

export const setSessionStorageItem = (key, value) => {
  if (!key || !value) {
    /* eslint-disable no-console */
    console.error('Cannot add item to sessionStorage. Missing params!');
    return;
  }
  return sessionStorage.setItem(key, value);
};

export const getSessionStorageItem = (key) => {
  if (!key) {
    /* eslint-disable no-console */
    console.error('Missing sessionStorage key!');
    return;
  }
  return sessionStorage.getItem(key);
};

export const removeSessionStorageItem = (key) => {
  if (!key) {
    /* eslint-disable no-console */
    console.error('Missing sessionStorage key to remove!');
    return;
  }
  sessionStorage.removeItem(key);
};

export const clearSessionStorage = () => {
  sessionStorage.clear();
};
