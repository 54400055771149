import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';
import IDropDownTheme from './IDropDownTheme';

interface IDropdownItem {
  componentTheme: IDropDownTheme;
}

const itemtMix = () => css`
  display: flex;
  align-items: center;
  padding: 12px 16px;
`;

export const ActionStyled = styled.div<IDropdownItem>`
  ${itemtMix}
  cursor: pointer;
  color: ${({componentTheme}) => componentTheme.listElementText};
`;

export const LinkStyled = styled(Link)<{componenttheme: IDropDownTheme}>`
  ${itemtMix}
  color: ${({componenttheme}) => componenttheme.listElementText};
`;

export const SelectStyled = styled.div<IDropdownItem>`
  ${itemtMix}
  justify-content: space-between;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;
    ${({componentTheme}) => componentTheme.listCarretColor && `fill: ${componentTheme.listCarretColor};`};
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const LinkContentMix = () => css<IDropdownItem>`
  ${({componentTheme: {listElementText, listElementFontSize, listElementLineHeight}}) => css`
    color: ${listElementText};
    font-size: ${listElementFontSize || '14px'};
    line-height: ${listElementLineHeight || '16px'};
    transition: color 0.3s ease-in-out;
  `}
`;

export const LinkContent = styled.span<IDropdownItem>`
  ${LinkContentMix}
`;

export const SelectContent = styled.span<IDropdownItem>`
  ${LinkContentMix}
  ${({componentTheme}) => componentTheme.fontWeight && `font-weight: ${componentTheme.fontWeight};`}
`;
