/**
 * @param decimalMinutes - 2.5
 * @return {Date} - Date('1970-01-01T00:02:30.000Z')
 */
export function formatDecimalMinutesToDate(decimalMinutes: number): Date {
  return new Date(decimalMinutes * 60 * 1000);
}

/**
 * @param date - Date('1970-01-01T00:02:30.000Z')
 * @param format - 'minutes'
 * @return string - '02:30'
 */
export function formatDateToDigitalTime(date: Date, format: 'seconds' | 'minutes' | 'hours' = 'minutes'): string {
  const formatted = date.toISOString();

  switch (format) {
    case 'seconds': {
      return formatted.substr(17, 2);
    }
    case 'hours': {
      return formatted.substr(11, 8);
    }
    case 'minutes':
    default: {
      return formatted.substr(14, 5);
    }
  }
}

/**
 * @param date - Date('1970-01-01T02:00:00.000Z')
 * @param format - 'hours'
 * @return string - '2h 0m'
 */
export function formatDateToSimpleTime(date: Date, format: 'seconds' | 'minutes' | 'hours' = 'minutes'): string {
  const formatted = date.toISOString().substr(11, 8);
  const [hours, minutes, seconds] = [formatted.substr(0, 2), formatted.substr(3, 2), formatted.substr(6, 2)].map(
    (stringNum: string) => (stringNum.startsWith('0') ? stringNum.substr(1) : stringNum)
  );

  switch (format) {
    case 'seconds':
      return `${seconds}s`;
    case 'minutes':
      return (parseInt(minutes) > 0 ? `${minutes}m ` : '') + `${seconds}s`;
    case 'hours':
    default:
      return (parseInt(hours) > 0 ? `${hours}h ` : '') + `${minutes}m`;
  }
}
