import {LdsRing, LdsRingInner} from './Ring.styled';

interface RingProps {
  color: string;
  color2: string;
  borderWidth: string;
  pixelSize: string;
}

function Ring(props: RingProps) {
  const {borderWidth, color, color2, pixelSize, ...rest} = props;
  const globalStyles = {
    pixelSize,
    borderWidth,
  };
  const outerStyle = {
    ...globalStyles,
  };
  const innerStyle = {
    ...globalStyles,
    color,
    color2,
  };
  return (
    <LdsRing {...outerStyle} {...rest}>
      <LdsRingInner {...innerStyle} />
    </LdsRing>
  );
}

export default Ring;
