import { Component } from 'react';
import _mergeWith from 'lodash/mergeWith';
import {checkIfBrasil, checkIfUSCountry} from '@/app/utils/register';
import staticInfo from '@/app/constants/info';

export const withInfo = (WrappedComponent) => {
  const customizer = (objValue, srcValue) => {
    if (srcValue === null) {
      return objValue;
    }
  };

  return class extends Component {
    state = {
      info: this.props.info,
    };

    render() {
      /**
       * ~ IMPORTANT !!! ~
       * infoMerged - is merged of two sources: static info file and info from api
       * Change static info if api /info changes somehow
       */
      const infoMerged = _mergeWith(staticInfo, this.props.info, customizer);
      const isUS = checkIfUSCountry(infoMerged.country);
      const isBR = checkIfBrasil(infoMerged.country);
      const entity = infoMerged.entities[0];
      const subscription_plan = infoMerged.subscription_plans[0];
      const {
        name,
        address,
        city,
        country,
        additional_data: {reg_address, reg_nr, cep, cnpj},
      } = entity;
      //adding spaces to change!
      const spacesRegNr = reg_nr && `, Reg nr: ${reg_nr}`;
      const spacesCep = cep && `\u00A0${cep}`;
      const spacesCnpj = cnpj && `\u00A0${cnpj}`;
      const spacesRegAddress = reg_address && `, Reg address: ${reg_address}`;
      const entityFull = `${name}\u00A0${address}${spacesCep}\u00A0${city}\u00A0${country}${spacesCnpj}${spacesRegAddress}${spacesRegNr}`;
      return (
        <WrappedComponent
          {...this.props}
          {...infoMerged}
          entityFull={entityFull}
          entity={entity}
          info={infoMerged}
          isUS={isUS}
          isBR={isBR}
          subscription_plan={subscription_plan}
        />
      );
    }
  };
};

export default withInfo;
