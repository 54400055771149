import { Component } from 'react';
import {I18nContext} from './../../providers/i18n';

const withI18Context = (WrappedComponent, other) => {
  return class extends Component {
    render() {
      return (
        <I18nContext.Consumer>
          {(value) => <WrappedComponent {...other} {...this.props} {...value} />}
        </I18nContext.Consumer>
      );
    }
  };
};

export default withI18Context;
