import {checkWebPSupport} from 'supports-webp-sync';

export const getImagePath = (path) => {
  const pathArr = path.split('.');

  const pathWithoutExtension = pathArr[0];
  return `${pathWithoutExtension}.webp`;
};

export function isWebpFormatSupported() {
  return checkWebPSupport();
}

export const isSvg = (path) => {
  const pathArr = path.split('.');
  return pathArr[1] === 'svg';
};
