import {createSelector} from 'reselect';

const selectors = {
  selectState: (state) => state.get('premiumMoviesInfo'),
  isLoading: () => createSelector(selectors.selectState, (premiumMoviesInfo) => premiumMoviesInfo.get('isLoading')),
  getPremiumMoviesInfo: () =>
    createSelector(selectors.selectState, (premiumMoviesInfo) => premiumMoviesInfo.get('premiumMoviesInfo')),
};

export default selectors;
