import {fromJS} from 'immutable';
import {prepareReducers} from '@/app/utils/redux';
import actions from './actions';

export const initialState = fromJS({
  isLoading: false,
  isError: false,
  movies: [],
  total: null,
});

const reducer = prepareReducers(
  {
    [actions.getPromoMoviesAction]: (state) => state.set('isLoading', true).set('isError', false),
    [actions.getPromoMoviesSuccessAction]: (state, {payload}) =>
      state.set('isLoading', false).set('isError', false).set('movies', payload.movies).set('total', payload.total),
    [actions.getPromoMoviesFailedAction]: (state) => state.set('isLoading', false).set('isError', true),
  },
  initialState
);

export default reducer;
