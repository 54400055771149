import {FetchResult} from '@apollo/client';
import type {ProductSDK} from '../../../ProductSDK';
import {requireDefined} from '../../../utils/nullability-utils';
import {getContentQuery} from '../../../graphql/query/getContent';
import {Content} from '../../../models/Content';

export async function getContent(this: ProductSDK, id: string): Promise<Content> {
  const response: FetchResult<{content: Content}> = await this.client.query({
    query: getContentQuery,
    variables: {id},
  });

  return requireDefined(response.data?.content);
}
