import {fromJS} from 'immutable';
import {prepareReducers} from '@/app/utils/redux';
import actions from './actions';

export const initialState = fromJS({
  isLoading: false,
  isError: false,
  genres: [],
});

const reducer = prepareReducers(
  {
    [actions.getGenresAction]: (state) => state.set('isLoading', true).set('isError', false),
    [actions.getGenresSuccessAction]: (state, {payload}) =>
      state.set('isLoading', false).set('isError', false).set('genres', payload),
    [actions.getGenresFailedAction]: (state) => state.set('isLoading', false).set('isError', true),
  },
  initialState
);

export default reducer;
